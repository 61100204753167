import SearchIcon from '@mui/icons-material/Search'

export default function Quotes(prop) {
  const { list, onClickEvent } = prop

  return (
    <>
      <h5 className="text-white">Preventivi ricevuti</h5>
      <table>
        <tr>
          <th>Freelancer</th>
          <th className="text-center">Offerta</th>
          <th className="text-center">Durata</th>
          <th className="text-center">Scheda</th>
          <th>Conferma</th>
        </tr>
        {list.map(obj => (
          <tr>
            <td className="freelancer ps-2">
              <div>
                <div className="avatar-xs">
                  <img src={obj.freelancer.img} alt="" />
                </div>
                @{obj.freelancer.name}
              </div>
            </td>
            <td className="cost-project">€&nbsp;{obj.budget}</td>
            <td className="date-project">{obj.days} giorni</td>
            <td className="scheda-project fs-7 text-primary">
              <div className="d-flex align-items-center justify-content-around cursor-pointer">
                <button
                  type="button"
                  onClick={() =>
                    onClickEvent({ page: 'offerte-details', freelance: obj })
                  }
                >
                  <SearchIcon fontSize="small" /> Vedi
                </button>
              </div>
            </td>
            <td className="status-project">
              <button type="button">Accetta offerta</button>
            </td>
          </tr>
        ))}
      </table>
    </>
  )
}
