import { useParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import listProjects from 'pagesitemeup/Progetti/listProjects.json'
import { SMULoading } from 'components/SMU'

import DettagliHeader from './sections/DettagliHeader'
import DettagliBody from './sections/DettagliBody'
import DettagliFooter from './sections/DettagliFooter'

import './DettagliProgetto.css'

import useHttp from '../../hooks/use-http'
import ChatProgetto from './sections/ChatProgetto'

export default function DettagliProgetto() {
  const { id } = useParams()
  const authContext = useContext(AuthContext)
  const freelanceID = authContext.authState?.freelancedata?.id
  const freelanceIDTipo = authContext.authState?.freelancedata?.id_tipologia;

  const {
    sendRequest: sendProjectDetailsRequest,
    isLoading: isProjectDetailsLoading
  } = useHttp()
  const {
    sendRequest: sendProjectTypeRequest,
    isLoading: isProjectTypeLoading
  } = useHttp()

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceTypesLoading
  } = useHttp()
  const {
    sendRequest: sendClientDetailsRequest,
    isLoading: isClientDetailsLoading
  } = useHttp()

  const {
    sendRequest: sendChatProgetto,
    isLoading: isChatProgettoLoading
  } = useHttp()

  const { sendRequest: sendMakeOfferRequest, isLoading: isOfferLoading } =
    useHttp()
  const [project, setProject] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [freelanceTypes, setFreelanceTypes] = useState([])
  const [projectType, setProjectType] = useState({})
  const [client, setClient] = useState({})
  const [chatProgetto, setChatProgetto] = useState([])

  const manageFreelanceTypes = data => {
    setFreelanceTypes(data?.data || [])
  }

  const loadFreelanceTypes = () => {
    sendFreelanceTypesRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: manageFreelanceTypes
    })
  }

  const manageClientData = data => {
    setClient(data?.data || [])
  }

  const manageProjectTypeData = data => {
    setProjectType(data.data)
  }
  const manageProjectDetails = data => {
    setProject(data?.data || {})
    console.log(data.data, 'PROGETTO')
    setIsLoading(false)
    sendClientDetailsRequest({
      url: `/sitemeup/cliente?id=${data.data.id_cliente}`,
      manageData: manageClientData
    })

    sendProjectTypeRequest({
      url: `/sitemeup/tipologiaprogetto?id=${data?.data?.tipoprogetto?.id}`,
      manageData: manageProjectTypeData
    })
  }

  const loadProjectDetails = () => {
    sendProjectDetailsRequest({
      url: `/sitemeup/order?id=${id}`,
      manageData: manageProjectDetails
    })
  }

  const manageOfferRequest = data => {
    loadProjectDetails()
  }

  const makeOffer = e => {
    const dt = { ...e, id_order: project.id, id_freelance: freelanceID, stato:'0' }

    sendMakeOfferRequest({
      url: `/sitemeup/orderoffersave`,
      bodyH: dt,
      methodH: 'PUT',
      manageData: manageOfferRequest 
    })
  }

  const manageChatProgettoRequest = (dt) => {
    setChatProgetto(dt.data)
  }

  const loadChatProgetto = () => {
    sendChatProgetto({
      url: `/sitemeup/chatsprogetto?id_order=${id}`,
      manageData: manageChatProgettoRequest
    })
  }

  const sendChatProgettoSave = (text, idx) => {
    if (idx) {
      sendChatProgetto({
        url: `/sitemeup/chatprogettoupdate`,
        bodyH: {
          id:idx,
          risposta:text
        },
        methodH: 'POST',
        manageData: loadChatProgetto
      })
    } else {
      sendChatProgetto({
        url: `/sitemeup/chatprogettosave`,
        bodyH: {
          id_freelance:freelanceID,
          id_order:id,
          testo:text,
          risposta:''
        },
        methodH: 'PUT',
        manageData: loadChatProgetto
      })
    }
    
  }

  useEffect(() => {
    loadProjectDetails()
    loadFreelanceTypes()
    loadChatProgetto()
  }, [])

  return (
    <>
      <SMUNavbar />
      <SMULoading
        show={
          isLoading ||
          isProjectDetailsLoading ||
          isClientDetailsLoading ||
          isOfferLoading ||
          isFreelanceTypesLoading || 
          isProjectTypeLoading ||
          isChatProgettoLoading 
        }
      />
      {!isLoading ? (
        <>
          <div className="makeOfferProject">
            <DettagliHeader project={project} freelances={freelanceTypes} />
            <DettagliBody
              project={project}
              freelances={freelanceTypes}
              client={client}
              
            />
            <ChatProgetto
              project={project}
              client={client}
              chatProgetto={chatProgetto}
              sendChatProgetto={(e, idx)=>sendChatProgettoSave(e, idx)}
            />
            <DettagliFooter
              project={project}
              freelances={freelanceTypes}
              makeOffer={makeOffer}
              canChooseTemplate={projectType?.tipifreelance?.find((el)=>el.sceglietemplate === 1 && el.id_tipofreelance === freelanceIDTipo) || null}
            />
          </div>
        </>
      ) : null}
    </>
  )
}
