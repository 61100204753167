/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'

function RotatingCard({ children }) {
  const [rotate, setRotate] = useState(false)

  const rotate0 = () => setRotate(false)
  const rotate180 = () => setRotate(true)

  return (
    <MKBox
      sx={{ perspective: '50rem' }}
      onMouseEnter={rotate180}
      onMouseLeave={rotate0}
    >
      <Card
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'relative',
          transform: rotate ? 'rotateY(180deg)' : 'rotateY(0)',
          transformStyle: 'preserve-3d',
          transition: 'all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1)'
        }}
      >
        {children}
      </Card>
    </MKBox>
  )
}

// Typechecking props for the RotatingCard
RotatingCard.propTypes = {
  children: PropTypes.node.isRequired
}

export default RotatingCard
