/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { useSelector, useDispatch } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

// Global components using redux
import KOStatusAlert from 'components/SMU/Globals/KOStatusAlert'
import { SMULoading } from 'components/SMU'

// Material Kit 2 PRO React themes
import theme from 'assets/theme'
import Presentation from 'layouts/pages/presentation'
import routes from 'routes'

// import useHttp from './hooks/use-http'

// Material Kit 2 PRO React routes
import 'assets/css/GeneralStyle.css'
import 'assets/css/CustomStyle.css'

import genId from 'idGenerator'

const stripePromise = loadStripe('pk_test_MMcjHIkOhxK3CyvgLTgBXXva')

export default function App() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pi_3KvdfvKh1AFIeCPT1u242v9r_secret_5E0oCsbBF0j4ig2DPIEVLRmBd'
  }

  const { pathname } = useLocation()

  const isLoading = useSelector(state => state.loading)
  // const isLoading = useSelector(state => {
  //   console.log('STATE FOR LOADING', Object.values(state).some(value => value.status === 'loading'))
  //   return Object.values(state).some(value => value.status === 'loading')
  //   // return state.loading
  // })

  // const { sendRequest: getFullData, isLoading: isDataLoading } = useHttp()

  // const dispatch = useDispatch();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      // TODO find a method to create authMiddleware based on token expiration; https://stackoverflow.com/questions/51426737/triggering-a-function-when-entering-a-route-react-router-dom

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key || genId(5)}
          />
        )
      }

      return null
    })

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise} options={options}>
        <CssBaseline />
        <SMULoading show={isLoading} />
        <KOStatusAlert />
        <Routes>
          {getRoutes(routes)}
          <Route path="/presentation" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/landing-web-designer" />} />
        </Routes>
      </Elements>
    </ThemeProvider>
  )
}
