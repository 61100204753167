import React, { useState } from 'react'
import Slider from "react-slick";
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import Input from '@mui/material/Input'
import { Select, MenuItem } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ProgettoCaricato from './images/progetto_caricato.png'
import PublishedProject from './images/published-project.jpg'


const ProjectPublished = props => {
  const { project, projectTypes, handleNext, products, sendUpdate, addProducts } = props
  const { titolo, descrizione, tiposito, projectType, localita, prodotti, addedProducts } = project


  const navigate = useNavigate();

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.25,
    slidesToScroll: 3,
    centerMode:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
        }
      }
    ]
  };


  const manageProducts = (e) => {
    
    if (e.target.checked) {
      if (!prodotti.find(el => el?.id_prodotto === e.target.value)) {
        sendUpdate('prodotti', [
          ...prodotti,
          {
            id_prodotto: parseInt(e.target.value, 10)
          }
        ])
      }
    } else {
      sendUpdate(
        'prodotti',
        prodotti.filter(
          el => el?.id_prodotto !== parseInt(e.target.value, 10)
        )
      )
    }


  }


  const handleAddProducts = () => {
    sendUpdate(
      'addedProducts',
      [...addedProducts, ...prodotti.map((el)=>el.id_prodotto)]
    )
    addProducts();
  }
  return (
    <div className="col-11 mx-auto">
      { false ? (<>
      <h2 className="text-center">Dai una carica extra al tuo progetto</h2>

      
      <div className="row mt-3">
        
        <div className="col-12 text-center">

        <Slider {...slickSettings}>
          {products &&
            products.filter((el)=>!addedProducts.includes(el.id)).map(el => (
              <>
                <div className="item mt-5 px-2">
                    <input type="checkbox"
                      className="team-selection"
                      id={el.nome.replaceAll(" ", "")}
                      name={el.nome.replaceAll(" ", "")}
                      value={el.id}
                      checked={
                        prodotti.find(
                          ej =>
                            parseInt(ej?.id_prodotto, 10) ===
                            parseInt(el.id, 10)
                        ) || false
                      }
                      onChange={manageProducts}
                    />
                    <label htmlFor={el.nome.replaceAll(" ", "")}>
                      {el.immaginepath ? (
                        <>
                          <img alt={el.nome} src={el.immaginepath} />
                        </>
                      ) : null}
                      <h4>{el.nome}</h4>
                      <p>€ {el.costo.toFixed(2)}</p>
                    </label>
                  </div>
              </>
            ))}

          </Slider>


{prodotti.length > 0 ? (<>

          <button
            type="button"
            className="btn btn-lg btn-primary letter-spacing-1 swpier-next"
            alt=""
            onClick={handleAddProducts}
            
          >
            Aggiungi prodotti selezionati
          </button>
          </>) : null}


          

          <p><Link to="/profilo">Torna al profilo</Link></p>

        </div>
      </div>
      </>) : null}
      <div className="row mt-3">
        <div className="col-12 text-center">
          <h2 className="fs-5">Gestisci il tuo nuovo progetto</h2>
          <p>Controlla i preventivi dei freelancer, crea il tuo team, prendi contatto con il project manager assegnato.</p>

          
          
        </div>
        <div className="col-10 offset-1 text-center"><p><img src={PublishedProject} alt="Progetto pubblicato" className="img-fluid" /></p></div>
      </div>

      <div className="row mt-3">
            <div className="col-12 text-center">
              
              <p><Link to="/profilo" className="btn btn-primary">Vai al profilo</Link></p>
            </div>
      </div>

    </div>
  )
}

export default ProjectPublished
