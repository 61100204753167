import React from 'react'

// @mui material components
// import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
// import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
// import MKButton from 'components/MKButton'

import imgColl1 from 'assets/images/sitemeup/semplice.svg'
import imgColl2 from 'assets/images/sitemeup/budget.svg'
import imgColl3 from 'assets/images/sitemeup/professionalita.svg'
import geometricA from 'assets/images/sitemeup/geometricA.svg'
import geometricB from 'assets/images/sitemeup/geometricB.svg'

// import { SMULoading, Registration } from "components/SMU/index";

// import { FormattedMessage } from 'react-intl'

function Lead() {
  // const [show, setShow] = useState(false)
  const arrayInfo = [
    {
      img: imgColl1,
      title: 'Seleziona il progetto web',
      desc: 'Che meglio si sposa alle tue capacità'
    },
    {
      img: imgColl2,
      title: 'Invia un’offerta dettagliata',
      desc: 'Il nostro portale verifica il budget del cliente con il costo di mercato. Niente più lavori sottocosto!'
    },
    {
      img: imgColl3,
      title: 'Sei stato selezionato? GRANDE!',
      desc: 'Ricordati che il cliente ha già fissato il budget. Appena terminato il lavoro, ti verrà accreditato l’intero importo pattuito!'
    }
  ]

  return (
    <>
      <section className="py-5 bg-white">
        <div className="container-fluid position-relative overflow-hidden">
          <img
            src={geometricA}
            className="geometricA_rlogo_landing z-index-0"
            alt="Site Me Up - theme"
          />
          <img
            src={geometricB}
            className="geometricB_llogo_landing z-index-0"
            alt="Site Me Up - theme"
          />
          <div className="row position-relative z-index-3">
            <div className="col-12 col-lg-10 m-auto">
              <div className="container px-0">
                <div className="row px-0">
                  <div className="col-12 text-center zilla px-0 px-md-1">
                    <h2>stiamo cercando nuovi collaboratori!</h2>
                    <p className="subtitle text-center">
                      Entra a far parte della nostra community
                    </p>
                  </div>

                  {arrayInfo.map(e => (
                    <div className="col-12 col-lg-4 zilla px-lg-5 my-lg-4">
                      <div className="pb-4 pb-lg-0 mb-3 mb-lg-2 position-relative text-center cover">
                        <img
                          src={e.img}
                          alt="Professionalità"
                          className="img-fluid"
                        />
                      </div>

                      <div className="text-center text-lg-start">
                        <h3 className="text-secondary">{e.title}</h3>
                        <p>{e.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Lead
