import React from 'react'
import template1 from 'assets/images/sitemeup/creaprogetto/template_example1.png'
import './TemplateBox.css'

const TemplateBox = ({ template, setTemplate }) => (
  <div className="template-box">
    <div className="d-block text-center">
      <img
        src={template.immaginepath || template1}
        className="img-fluid mb-2"
        alt="template"
      />
    </div>

    <div className="d-flex col-12 z-index-2 px-2 px-md-3 pb-3 pt-2 align-items-center ">
      <div className="d-flex col-7 fs-small text-start">
        <b>
          {template.nome}
        </b>
      </div>
      <div className="d-flex col-5 align-items-end justify-content-end">
        <a
          href="#checkTemplate"
          type="button"
          className="btn btn-sm btn-primary m-0 py-2 px-3 fs-small"
          onClick={() => setTemplate(template.id)}
        >
          Dettagli
        </a>
      </div>
    </div>
  </div>
)

export default TemplateBox
