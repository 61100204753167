// @mui material components
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
import MKButton from 'components/MKButton'

import headerimmage from 'assets/images/sitemeup/header_homepage.png'

import { FormattedMessage } from 'react-intl'

function HeaderHp() {
  return (
    <MKBox display="flex" alignItems="center" className="bg-primary">
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={5}
          lg={6}
          className="d-flex justify-content-center text-md-start text-center flex-column mt-sm-0 mt-2"
        >
          <MKBox
            component="img"
            src={headerimmage}
            alt="SiteMeup"
            className="mw-100"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          lg={6}
          className="d-flex justify-content-center text-md-start text-center flex-column mt-sm-0 mt-2 pt-lg-7 px-md-5"
        >
          <h1 className="text-white">
            <FormattedMessage id="Costruiamo" />
            <br />
            <FormattedMessage id="insieme" />
            <br />
            <FormattedMessage id="il sito web" />
            <br />
            <FormattedMessage id="dei tuoi sogni" />
          </h1>
          <Stack
            direction="row"
            className="d-lg-flex d-block gap-2 mx-auto mx-md-0"
          >
            <MKButton color="white" className="btn btn-white btn-lg my-2">
              CREA IL TUO SITO
            </MKButton>
            <MKButton color="white" className="btn btn-white btn-lg my-2">
              GUADAGNA COME WEB DESIGNER
            </MKButton>
          </Stack>
        </Grid>
      </Grid>
    </MKBox>
  )
}
export default HeaderHp
