import React from 'react'

// @mui material components
import Card from '@mui/material/Card'

// Coworking page sections
import AboutUs from 'pagesitemeup/HomePage/sections/AboutUs'
import Warranty from 'pagesitemeup/HomePage/sections/Warranty'

// Images
import cfWeb4 from 'assets/images/sitemeup/cfWeb4.png'
import cfWeb7 from 'assets/images/sitemeup/cfWeb7.png'
import contratto from 'assets/images/sitemeup/contratto.jpg'
import logo from 'assets/images/sitemeup/sitemeup_marchio.svg'
import geometricC from 'assets/images/sitemeup/geometricC.svg'
import geometricD from 'assets/images/sitemeup/geometricD.svg'

export default function CFClient() {
  return (
    <>
      <Card className="bg-gray">
        <AboutUs />
      </Card>
      <Warranty />

      <section className="bg-white">
        <div className="container">
          <div className="row pt-4">
            <div className="col-md-8 mx-auto text-center">
              <h2 className="">CONTRATTO SICURO</h2>
              <div className="subtitle">
                Firmiamo un vero contratto legale tra te e il web designer.
                <br />
                Per noi la serietà è al primo posto. Niente più preventivi senza
                valore!
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="img-fluid"
                  src={contratto}
                  loading="lazy"
                  alt="contratto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6 py-md-7 bg-secondary">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-11 col-sm-10 col-md-6 col-lg-4 mx-lg-auto mb-6 mb-md-0 d-flex">
              <img src={cfWeb4} alt="sitemeup" className="w-100" />
            </div>
            <div className="col-md-6 col-lg-7 col-12 mx-lg-0 mb-4 d-flex justify-content-center align-items-center">
              <div className="text-center text-lg-start zilla">
                <img src={logo} className="w-25 mb-3" alt="Site Me Up" />
                <h2 className="text-white">
                  Ovviamente verrai affiancato
                  <br />
                  ad un tutor personale.
                  <br />
                  <span className="fw-normal">
                    Non ti lasceremo mai navigare
                    <br />
                    da solo in questo percorso!
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-tertiary position-relative overflow-hidden">
        <div className="container">
          <img
            src={geometricC}
            className="geometricC_llogo_comefunziona z-index-1"
            alt="Site Me Up - theme"
          />
          <img
            src={geometricD}
            className="geometricD_rlogo_comefunziona z-index-1"
            alt="Site Me Up - theme"
          />

          <div className="pt-6 mx-1 mx-lg-5 p-3 pb-5 position-relative z-index-3">
            <div className="row align-items-center">
              <div className="mb-5 mb-md-0 col-md-7 mx-auto text-center text-lg-start">
                <h2 className="text-white">
                  Trova il web designer
                  <br />
                  <span className="fw-normal">più adatto al tuo progetto!</span>
                </h2>
                <p className="text-white">
                  Sulla nostra piattaforma potrai usufruire della competenza dei{' '}
                  <br />
                  professionisti che ti aiuteranno a far decollare il tuo
                  business!
                </p>
                <button type="button" className="btn-primary btn-lg my-4">
                  Inizia subito
                </button>
              </div>
              <div className="col-md-5 mx-auto">
                <img
                  className="img-fluid"
                  src={cfWeb7}
                  loading="lazy"
                  alt="trova webdesigner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
