import React, { useEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import './ProjectSummary.css'
import Programmatore from '../images/web_developer_2x.png';

const ProjectSummary = props => {
  const { project, freelances, handleNext, handleBack, publishProject } = props
  const { titolo, tiposito, projectType, localita, profiles, descrizione } = project

  const [totalBudget, setTotalBudget] = useState(0)

  useEffect(() => {
    let totalBud =
      (tiposito === 1
        ? projectType?.prezzonuovo
        : projectType?.prezzorifacimento) || 0
    
    totalBud = 0; // Eliminazione costo iniziale del progetto

    profiles.map(el => {
      const baseCost =
        tiposito === 1
          ? projectType.tipifreelance.find(
              et => et.id_tipofreelance === el.id_tipofreelance
            )?.costonuovo
          : projectType.tipifreelance.find(
              et => et.id_tipofreelance === el.id_tipofreelance
            )?.costorifacimento

      const budget =
        (baseCost || 0) +
          el?.answers.reduce((acc, x) => {
            if (x.answer) {
              if (x.moltiplicatorio === 1) {
                return acc + x.budget * (parseInt(x.answer, 10) || 0)
              }
              return acc + x.budget
            }

            return acc
          }, 0) || 0

      totalBud += budget
      return el
    })
    setTotalBudget(totalBud)
  }, [])

  return (
    <>
      <div className="summary-project lato mt-0 pt-0">

<h2 className="mb-4">Riepilogo del progetto</h2>

  <div className="col-md-11 mx-auto">

    <div className="first-panel px-5">
      <div className="info flex-grow-1">
        <desc className="text-truncate"><small className="text-xs text-uppercase">{tiposito === 1 ? 'Nuovo progetto' : 'Aggiorna progetto'}</small>
        <br />
        <span className="gold text-uppercase"><strong>{titolo}</strong></span></desc>
        <p className="d-none d-md-flex">
          <div className="d-flex flex-fill">
            <LocationOnIcon fontSize="large" color="white" />
            <div className="pl-2">
              <h6 className="text-uppercase">Località</h6>
              <desc className="text-truncate">{localita}</desc>
            </div>
          </div>
          <div className="d-flex flex-fill">
            <WindowRoundedIcon fontSize="large" color="white" />
            <div className="pl-2">
              <h6 className="text-uppercase">Tipologia</h6>
              <desc className="text-truncate">{projectType?.nome || 'Sconosciuto'}</desc>
            </div>
          </div>
          <div className="d-flex flex-fill">
            <GroupRoundedIcon fontSize="large" color="white" />
            <div className="pl-2">
              <h6 className="text-uppercase">Professionisti</h6>
              <desc className="text-truncate">{profiles.length} Freelance</desc>
            </div>
          </div>
        </p>
      </div>
      <div className="template">
        <section className="budget my-0 h-100">
          <span className="py-5">€ {totalBudget.toFixed(2)}</span>
          <span> Stima Costo</span>
        </section>
   
      </div>
    </div>

  <div className="px-md-5 py-3">
      <h4>Descrizione del progetto</h4>
      {descrizione}

    </div>
  </div>
    



      <div className="container mt-4">
        
        {profiles.map(el => {
          const f = freelances.find(ff => ff.id === el.id_tipofreelance)

          console.log(el, 'PROFILO')
          const baseCost =
            tiposito === 1
              ? projectType.tipifreelance.find(
                  et => et.id_tipofreelance === el.id_tipofreelance
                )?.costonuovo
              : projectType.tipifreelance.find(
                  et => et.id_tipofreelance === el.id_tipofreelance
                )?.costorifacimento

          const budget =
            (baseCost || 0) +
              el?.answers.reduce((acc, x) => {
                if (x.answer) {
                  if (x.moltiplicatorio === 1) {
                    return acc + x.budget * (parseInt(x.answer, 10) || 0)
                  }
                  return acc + x.budget
                }

                return acc
              }, 0) || 0

          const q = Array.isArray(f?.questions) ? {} : f?.questions
          const qf = projectType.tipifreelance.find(
            et => et.id_tipofreelance === el.id_tipofreelance
          )?.questions
          const questions = qf || q

          return (
            <>
            <div className="row">
              <div className="col-md-11 mx-auto">


              

                  
                  <div className="row">
                      <div className="col-3 col-md-2">
                          <img src={f.pathfoto || Programmatore} alt={f.name} className="img-fluid" />
                      </div>
                      <div className="col-9 col-md-10">

                        <div className="d-flex">
                          <h5 className="text-uppercase">{f.name}</h5>
                          <span className="underline_box flex-grow-1" />
                          <h5>€ {budget.toFixed(2)}</h5>
                      </div>

                          <div className="list">
                   
                    <li>
                      <desc>Descrizione Attività</desc>
                      <h6>{el.attivita}</h6>
                    </li>

                    {questions &&
                      Object.keys(questions).map(ql => (
                        <>
                          <li key={ql}>
                            <desc>
                              <strong className="text-uppercase">{ql}</strong>
                            </desc>
                          </li>

                          {questions[ql].map(eq => {
                            const quest = eq?.tipofreelancequestion || eq
                            let risposta =
                              el?.answers?.find(
                                pp =>
                                  pp.id_question === (quest?.id_domanda || quest.id)
                              )?.answer || 'Nessuna risposta'
                            if (quest.answers?.length > 0) {
                              risposta =
                                quest.answers.find(pp => pp.key === risposta)
                                  ?.key || 'Nessuna risposta'
                            }
                            return (
                              <li>
                                <desc>{quest.text}</desc>
                                <h6>{risposta}</h6>
                              </li>
                            )
                          })}
                        </>
                      ))}
                  </div>
                  
                      </div>

                  </div>


                  <hr />
              </div>
            </div>
            
            </>
          )
        })}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-4">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                alt=""
                onClick={() => handleBack()}
              >
                <small>Indietro</small>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                alt=""
                onClick={() => handleNext(totalBudget)}
              >
                <small>Avanti</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ProjectSummary
