import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setProfileStars, selectProfile } from 'slices/profileSlice'
import genId from '../../../idGenerator'

function Stars({ setSaved, plugin }) {
  // plugin.id for skill_id
  const dispatch = useDispatch()
  const data = useSelector(selectProfile)
  const { stars } = data.skills?.find(el => el.id_skill === plugin.id) || 0

  const changeNumberOfStars = (type, i) => {
    const { tipo, skills } = data
    const { id } = plugin
    const currentSkill = skills.find(el => el.id_skill === id)
    const totalStars =
      type === 'empty' && currentSkill ? i + 1 + currentSkill.stars : i + 1

    if (currentSkill !== undefined) {
      dispatch(setProfileStars({ tipo, id: currentSkill.id_skill, totalStars }))
    } else {
      dispatch(setProfileStars({ tipo, id, totalStars }))
    }
    setSaved(false)
  }

  return (
    <>
      {Array.from({ length: stars }).map((_, i) => (
        <button
          key={genId(3)}
          type="button"
          aria-label={i + 1}
          onClick={() => changeNumberOfStars('full', i)}
        >
          <span className="fa fs-4 fa-star text-warning me-1" />
        </button>
      ))}

      {Array.from({ length: 5 - (stars || 0) }).map((_, i) => (
        <button
          key={genId(3)}
          type="button"
          aria-label={i + stars + 1}
          onClick={() => changeNumberOfStars('empty', i)}
        >
          <span className="fa fa-star-o fs-4 me-1" />
        </button>
      ))}
    </>
  )
}
export default Stars
