import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dumpSkills } from 'slices/profileSlice'

// import {
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   FormLabel,
//   FormGroup,
//   FormControlLabel,
//   FormHelperText,
//   Checkbox,
// } from '@mui/material'
// import { useSelector, useDispatch } from 'react-redux'

// @mui material components
// import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
// import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
// import MKButton from 'components/MKButton'

// import { FormattedMessage } from 'react-intl'

// import useHttp from '../../../hooks/use-http'
import Stars from '../components/Stars'

import './Plugin.css'

function Plugin({ setSaved, tipologia, freelanceTypes, value }) {

  const [skills, setSkills] = useState([])

  const dispatch = useDispatch()
  // const [show, setShow] = useState(false)
  // const [info, setInfo] = useState([])
  // eslint-disable-next-line
  // const { sendRequest: requestPluginList, isLoading: isPluginLoading } = useHttp()

  // const getStarsFromData = idSkill => {
  //   if (freelanceData !== null && freelanceData.skills !== undefined) {
  //     const dt = freelanceData.skills.find(el => el.id_skill === idSkill)
  //     if (dt) {
  //       return dt.stars
  //     }
  //   }

  //   return 0
  // }

  // const getIdFromData = idSkill => {
  //   if (freelanceData !== null && freelanceData.skills !== undefined) {
  //     const dt = freelanceData.skills.find(el => el.id_skill === idSkill)
  //     if (dt) {
  //       return dt.id
  //     }
  //   }

  //   return 0
  // }

  useEffect(() => {
    dispatch(dumpSkills())
  }, [])

  useEffect(()=>{
    setSkills(freelanceTypes?.find((el)=>el.id === value)?.skills || [])
  }, [freelanceTypes, value])
  


  return (
    <>
      <section id="valutazione" className="col-11 m-auto">
        <div className="row py-3">
          {skills?.map(e => (
            <>
              <div className="col-12 py-1">
                <div key={e.id} className="skillsRow">
                  <div className="form-control azzurro d-flex align-items-center w-100 justify-content-between">
                    <span className="fw-bold label">{e.name}</span>
                    <div className="starsContainer">
                      <Stars setSaved={setSaved} plugin={e} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  )
}
export default Plugin
