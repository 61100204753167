import LightGallery from 'lightgallery/react'

// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

import './Portfolio.css'

export default function Portfolio({ list, user }) {

  return (
    <div className="portfolio-webdesigner container d-flex flex-wrap">
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
        {user?.images?.map(item => (
          <a
            href={item.imagepath}
            className="col-12 col-sm-6 col-lg-4 text-white cursor-pointer"
            data-sub-html={`<span class="text-white fs-4">${item.name}</span>`}
          >
            <img src={item.imagepath} alt="" />
          </a>
        ))}
      </LightGallery>
    </div>
  )
}
