import React, { useState, createContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const { children } = props
  const initialState = JSON.parse(localStorage.getItem('userdata'))
  const navigate = useNavigate()

  const [clientID, setClientID] = useState()
  const [freelencerId, setFreelencerId] = useState()
  const [userId, setUserId] = useState()

  const [authState, setAuthState] = useState(
    initialState || {
      email: '',
      name: '',
      id: '',
      tipo: '',
      token: '',
      freelancedata: null,
      clientedata: null,
      isLoggedIn: false
    }
  )

  const authStateHandler = data => {
    setAuthState(data)
    localStorage.setItem('userdata', JSON.stringify(data))
    localStorage.setItem('token', data.token)
  }

  const authIdStateHandler = data => {
    if (data.tipo === 'Freelance') {
      setFreelencerId(data.freelancedata.id)
    }

    if (data.tipo === 'Cliente') {
      setClientID(data.clientedata.id)
    }

    setUserId(data.id)
  }

  const logoutHandler = () => {
    setAuthState({
      email: '',
      name: '',
      id: '',
      tipo: '',
      token: '',
      freelancedata: null,
      clientedata: null,
      isLoggedIn: false
    })
    localStorage.removeItem('userdata')
    localStorage.removeItem('token')
    navigate('/landing-web-designer')
  }

  const contextValue = {
    clientID,
    freelencerId,
    userId,
    authIdStateHandler,
    authState,
    sendAuthState: authStateHandler,
    sendLogout: logoutHandler,
    isLoggedIn: authState.isLoggedIn
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export default AuthContext
