import DefaultList from './component/DefaultList'
import Quotes from './component/Quotes'
import Products from './component/Products'
import './MyProjects.css'

export default function MyProjects({
  list = [],
  completed,
  quotes,
  products,
  onClickEvent
}) {
  const ManagePage = () => {
    let html

    if (!quotes && !products) {
      html = (
        <DefaultList
          list={list}
          onClickEvent={onClickEvent}
          completed={completed}
        />
      )
    } else if (quotes && !products) {
      html = <Quotes list={list} onClickEvent={onClickEvent} />
    } else if (!quotes && products) {
      html = <Products list={list} />
    } else html = <></>

    return html
  }

  return (
    <div
      className={`my-projects lato ${completed && 'completed'} ${
        products && 'products'
      }`}
    >
      <ManagePage />
    </div>
  )
}
