import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import useHttp from 'hooks/use-http'

// const { sendRequest: sendProgettiRequest, isLoading: isProgettiLoading } = useHttp();

const initialState = {
  entities: [],
  status: 'idle'
}

export const dumpProgetti = createAsyncThunk('progetti/dumpProgetti', () => {
  const response = fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/sitemeup/orders`,
    {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      // body: JSON.stringify({ stato: '0' })
    }
  )
    .then(res => res.json())
    .then(json => json.data)
    // eslint-disable-next-line
    .catch(err => console.error('Progetti', err))

  return response
})

const progettiSlice = createSlice({
  name: 'progetti',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dumpProgetti.pending, state => {
        /* eslint-disable no-param-reassign */
        state.status = 'loading'
      })
      .addCase(dumpProgetti.fulfilled, (state, action) => {
        // console.log('AAAAA', action)
        state.entities = action.payload
        state.status = 'idle'
      })
      .addCase(dumpProgetti.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
  }
})

export default progettiSlice.reducer
