import React from 'react'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import GridTemplate from './components/GridTemplate'

export default function Templates() {
  return (
    <>
      <SMUNavbar />
      <div className="container py-4">
        <GridTemplate showSelectionPanel={false} showHeader={false} selectPages={false} />
      </div>
    </>
  )
}
