import { useState, useEffect } from 'react'

/* A custom Hook that handles the input of the form
It takes the initial value and the type of the input to define all the slices of state of the form
It returns the value of the input, the function that updates the value of the input,
the state of the input validity and the error message */

const useHandleInput = ({ initialValue = '', type = '' }) => {
  const [value, setValue] = useState(initialValue)
  const [valid, setValid] = useState()
  const [error, setError] = useState('')

  // Validate the input based on the type of the input using Regex

  useEffect(() => {
    if (!value) {
      setValid(null)
    } else if (type === 'email') {
      setValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
    } else if (type === 'password') {
      setValid(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/.test(
          value
        )
      )
    } else if (type === 'username') {
      setValid(/^[a-zA-Z0-9\s]{3,}$/.test(value))
    }
  }, [value, type])

  // Update the error message based on the validity of the input

  useEffect(() => {
    if (valid === false && type === 'email') {
      setError('Inserisci un indirizzo email valido')
    }
    if (valid === false && type === 'password') {
      setError(
        'Inserisci una password che contenga almeno: 8 caratteri, 1 maiuscola, 1 minuscola, 1 numero, 1 carattere speciale'
      )
    }
    if (valid === false && type === 'username') {
      setError('Inserisci un valore valido (almeno 3 caratteri)')
    }
    if (valid === true || valid === null) {
      setError('')
    }
  }, [valid, type])

  const handleChange = e => {
    setValue(e.target.value)
  }
  return { value, handleChange, valid, error }
}

export default useHandleInput
