import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  entities: [],
  status: 'idle'
}

export const dumpTemplates = createAsyncThunk('templates/dumpTemplates', () => {
  const response = fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/sitemeup/templates`,
    {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
    .then(res => res.json())
    .then(json => json.data)
    // eslint-disable-next-line
    .catch(err => console.error('Templates', err))

  return response
})

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dumpTemplates.pending, state => {
        /* eslint-disable no-param-reassign */
        state.status = 'loading'
      })
      .addCase(dumpTemplates.fulfilled, (state, action) => {
        // console.log('TEMPLATES', action)
        state.entities = action.payload
        state.status = 'idle'
      })
      .addCase(dumpTemplates.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
  }
})

export default templatesSlice.reducer
