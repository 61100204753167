// @mui material components
import Grid from '@mui/material/Grid'

// Material Kit 2 PRO React examples
import RaisedBlogCard from 'commoncomponets/Cards/BlogCards/MinimalBlogCard'

function LastNews({ element }) {
  return (
    <>
      <section className="py-5">
        <div className="container-fluid">
          <div className="row px-0">
            <div className="col-12 mx-auto text-center px-0 px-md-1">
              <h2 className="my-3">Ultime News dal blog</h2>
            </div>
          </div>
          <div className="row px-3 px-lg-6">
            {element.map(({ image, title, text, link }) => (
              <Grid
                item
                xs={12}
                md={2}
                lg={3}
                pt={6}
                pb={3}
                px={1}
                sx={{ mx: 'auto', px: { xs: 0, lg: 1 } }}
              >
                <RaisedBlogCard
                  image={image}
                  title={title}
                  description={text}
                  action={{
                    type: 'internal',
                    route: { link },
                    color: 'secondary',
                    label: 'Leggi tutto'
                  }}
                />
              </Grid>
            ))}
          </div>
        </div>
        <div className="buttons text-center">
          <button type="button" className="btn btn-lg btn-primary my-4">
            Vai al blog
          </button>
        </div>
      </section>
    </>
  )
}

export default LastNews
