import { useState, useEffect } from 'react'

import ProfileBody from 'pagesitemeup/Profilo/components/ProfileBody'
import DettagliOfferta from 'pagesitemeup/DettagliOfferta'
import listProjects from 'pagesitemeup/Progetti/listProjects.json'
import OffertaHeader from 'pagesitemeup/DettagliOfferta/sections/OffertaHeader'
import OffertaBody from 'pagesitemeup/DettagliOfferta/sections/OffertaBody'
import Notifiche from 'pagesitemeup/Notifiche'
import StoricoProgetti from './sections/Progetti'
import Sidebar from './components/Sidebar'

import SMUDrawer from './components/Drawer'
import Offerte from './sections/Offerte'

import './ClientProfilo.css'

export default function ClientProgetti({ profileData, notifications }) {
  // Ricerca progetto interno ad oggetto statico - DA ELIMINARE
  const ID = 1
  const project = listProjects.filter(obj => obj.id === ID)[0]

  const [selectedPage, setSelectedPage] = useState('profilo')
  const [clientStatus, setClientStatus] = useState()

  useEffect(() => {
    if (clientStatus?.page !== null) setSelectedPage(clientStatus?.page)
  }, [clientStatus])

  const ManagePage = ({ page, data }) => {
    let html
    switch (page) {
      case 'profilo':
        html = <ProfileBody profileData={profileData} />
        break
      case 'project-list':
        html = <StoricoProgetti onClickEvent={setSelectedPage} />
        break
      case 'offerte-projects':
        html = <Offerte onClickEvent={setClientStatus} />
        break
      case 'offerte-details':
        html = (
          <div className="dettagliOfferta show-offer">
            <OffertaHeader project={project} />
            <OffertaBody project={project} />
          </div>
        )
        break
      case 'project-details':
        html = (
          <DettagliOfferta offerta={data} setSelectedPage={setSelectedPage} />
        )
        break
      case 'show-alert':
        html = <Notifiche notifications={notifications} />
        break
      default:
        html = <ProfileBody profileData={profileData} />
        break
    }
    return html
  }

  return (
    <div className="container-fluid bg-white p-0 m-0 overflow-hidden">
      <div className="p-0 m-auto ">
        <div className="row gx-0 position-relative">
          <div className="d-md-none">
            <SMUDrawer
              currentpage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
          <div className="col-lg-3 d-none d-md-grid bg-gray">
            <Sidebar
              currentpage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
          <div className="col-12 col-lg-9">
            Ciao
          </div>
        </div>
      </div>
    </div>
  )
}
