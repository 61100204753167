/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'store/auth-context'
import { useDispatch } from 'react-redux'
import { clearProfile } from 'slices/profileSlice'

// Routes
import footerRoutes from 'footer.routes'

// Images
import comefunziona from 'assets/images/sitemeup/header_comefunziona.png'

// Coworking page sections
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUFooter from 'commoncomponets/Footer/SMUFooter'
import LoginForm from 'components/SMU/Login/LoginForm'
import CFClient from './sections/CFClient/CFClient'
import CFServer from './sections/CFServer/CFServer'

// Style
import './ComeFunziona.css'


export default function ComeFunziona() {
  const [currentTab, setCurrentTab] = useState(0)
  const [show, setShow] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authContext = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  useEffect(() => setIsLoggedIn(authContext.isLoggedIn), [authContext])


  return (
    <>
      <SMUNavbar />
      <LoginForm
              show={show}
              sendLogin={() => {
                setIsLoggedIn(true)
                navigate('/profilo')
              }}
              sendLogout={() => {
                dispatch(clearProfile)
                setIsLoggedIn(false)
              }}
              close={() => setShow(false)}
            />

      <header>
        <div className="page-header bg-primary">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-9 col-lg-6 mx-auto d-flex text-center text-lg-start mt-3 mt-lg-0">
                <img
                  src={comefunziona}
                  alt="Come Funziona"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 d-flex justify-content-center text-center text-lg-start flex-column mt-sm-0 pt-3 pb-5">
                <p className="fs-5 zilla mb-4 text-white">
                  Puoi creare un sito professionale con Wordpress
                  <br />
                  semplicemente selezionando il miglior freelancer della tua
                  zona
                  <br />e scegliendo tra i migliori template scelti con cura per
                  te
                </p>
                <div className="d-lg-flex justify-content-lg-start mt-3 gap-2">
                  <button
                    type="button"
                    className="btn btn-lg mt-0 border-0 bg-white"
                    onClick={() =>
                      !isLoggedIn ? setShow(true) : navigate('/crea-sito')
                    }
                  >
                    Crea il tuo sito
                  </button>{' '}
                  <br />
                  <button
                    type="button"
                    className="btn btn-lg mt-0 border-0 bg-white"
                    onClick={() =>
                      !isLoggedIn ? setShow(true) : navigate('/profilo')
                    }
                  >
                    Guadagna come web designer
                  </button>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-white text-center">
                    COME FUNZIONA SITE ME UP
                  </h2>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div
                  className="btn-group group-comefunziona"
                  data-toggle="buttons"
                >
                  <label
                    htmlFor="option1"
                    className={`btn m-0  rounded-top-left ${
                      currentTab === 0 ? 'active btn-white' : 'btn-gray'
                    }`}
                  >
                    <input
                      type="radio"
                      name="options"
                      id="option1"
                      value="0"
                      onChange={() => setCurrentTab(0)}
                      checked={currentTab === 0}
                    />{' '}
                    Come creare il tuo sito
                  </label>
                  <label
                    htmlFor="option2"
                    className={`btn m-0 btn-gray rounded-top-right  ${
                      currentTab === 1 ? 'active btn-white' : 'btn-gray'
                    }`}
                  >
                    <input
                      type="radio"
                      name="options"
                      id="option2"
                      value="1"
                      onChange={() => setCurrentTab(1)}
                      checked={currentTab === 1}
                    />{' '}
                    Come guadagnare da web designer
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {currentTab === 0 ? <CFClient /> : <CFServer />}

      <SMUFooter content={footerRoutes} />
    </>
  )
}
