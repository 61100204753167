import React, { useState, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import { useNavigate } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import P1 from 'assets/images/ivana-square.jpg'
import LoginForm from 'components/SMU/Login/LoginForm'
import { useDispatch } from 'react-redux'
import { clearProfile } from 'slices/profileSlice'

export default function FreelancerPanel({ item }) {
  const authContext = useContext(AuthContext)
  const {
    id,
    name,
    // budget,
    desc,
    // data,
    // client,
    // offerte,
    img,
    tipo,
    skills
  } = item
  const [show, setShow] = useState(false)
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className="project-panel lato" id={`project-${id}`}>
      <section className="body">
        <div className="row p-0 m-0 w-100 d-flex align-items-center">
          <div className="col-12 col-md-3">
            <div className="avatar-img-xl mx-auto">
              <img src={img || P1} alt={`Freelance Sitemeup - ${name}`} />
            </div>
          </div>

          <section className="col-12 col-md-9 mt-3 mt-md-0 text-center text-md-start">
            <div className="col-12 desc">
              <div className="name mb-2">{name}</div>
              <span dangerouslySetInnerHTML={{__html:tipo}} />
              {desc}
            </div>
          </section>
        </div>

        <section className="col-12 list-skills mt-3 mt-md-2 text-center text-md-start">
          <b className="ps-2 fs-6 w-100">Competenze</b>
          {skills.map(skill => (
            <div className="col-lg-3 col-md-4 col-6 p-1">
              <div className="skill text-truncate">{skill}</div>
            </div>
          ))}
        </section>

        <section className="col-12 text-end mt-2">
          <LoginForm
            show={show}
            sendLogin={() => {
              setIsLoggedIn(true)
              navigate(`/freelancer/${id}`)
            }}
            sendLogout={() => {
              dispatch(clearProfile)
              setIsLoggedIn(false)
            }}
            close={() => setShow(false)}
          />
          <button
            type="button"
            className="btn btn-primary fs-7 fw-bold"
            onClick={() => navigate(`/freelancer/${id}`)}
          >
            Dettagli
          </button>
        </section>
      </section>
    </div>
  )
}
