import React, { useRef, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Pagination } from 'swiper'

// Icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import './ImgPreview.css'

export default function ImgPreview({ arrImg }) {
  const sliderRef = useRef(null)

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])

  return (
    <>
      <div className="img-preview">
        <Swiper
          ref={sliderRef}
          loop
          modules={[Pagination]}
          className="swiper-preview"
        >
          {arrImg.map(elem => (
            <SwiperSlide>
              <div className="preview">
                <img src={elem?.imagepath || elem} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="arrow-swiper">
        <KeyboardArrowLeftIcon
          onClick={handlePrev}
          sx={{ fontSize: '300% !important' }}
          className="left-icon custom_prev"
        />

        <KeyboardArrowRightIcon
          onClick={handleNext}
          sx={{ fontSize: '300% !important' }}
          className="right-icon custom_next"
        />
      </div>
    </>
  )
}
