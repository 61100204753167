/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    toggleLoading(state) {
      return !state
    },
    setLoading(state) {
      state = true
      return state
    },
    removeLoading(state) {
      state = false
      return state
    }
  }
})

export const { toggleLoading, setLoading, removeLoading } = loadingSlice.actions

export default loadingSlice.reducer
