import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import TemplateBox from 'pagesitemeup/CreaProgetto/components/TemplateBox'
import TemplateView from 'pagesitemeup/CreaProgetto/components/TemplateView'

import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { dumpTags } from 'slices/tagsSlice'
import { dumpTemplates } from 'slices/templatesSlice'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
))

export default function GridTemplate({showSelectionPanel, showHeader, selectPages, showNavbar, handleSelection, templateSelected}) {
  const [tag, setTag] = useState('all')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dumpTags())
    dispatch(dumpTemplates())
    
  }, [])

  const arrFilters = useSelector(state => state.tags.entities)
  const arrTemplate = useSelector(state => state.templates.entities)

  const getTemplateByID = id =>
    arrTemplate.filter(obj => obj.id === id && obj)[0]
  const [template, setTemplate] = useState(templateSelected || null)
  const selectedTemplate = ID => {
    setTemplate(getTemplateByID(ID))
  }


  return (
    <>
      
      <div className="template-list">
        {arrTemplate.filter((el)=>el.id === templateSelected?.id_template || !templateSelected).map(e => (
          <div
            className={`d-block col-6 col-lg-4 p-2 ${
              tag !== 'all' &&
              e.tags.every(t => t.descrizione !== tag) &&
              'd-none'
            }`}
          >
            <TemplateBox
              key={e.id}
              template={e}
              setTemplate={val => selectedTemplate(val)}
            />
          </div>
        ))}
      </div>

      <Dialog
        fullScreen
        open={template !== null}
        TransitionComponent={Transition}
      >
        <TemplateView handleSelection={handleSelection} showHeader={showHeader} showNavbar={showNavbar} showSelectionPanel={showSelectionPanel} selectPages={selectPages} template={template} setTemplate={setTemplate} />
      </Dialog>
    </>
  )
}
