import Box from '@mui/material/Box'

// import AlertPending from 'assets/images/nsp/btn-pending.png'
// import AlertInfo from 'assets/images/nsp/btn-alert.png'
// import AlertCheck from 'assets/images/nsp/btn-check.png'
// import AlertNotification from 'assets/images/nsp/btn-notification.png'

import PendingIcon from '@mui/icons-material/Pending'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CloseIcon from '@mui/icons-material/Close'
import './AlertDiv.css'

let typeAlert = 'default'
let underlineAlert = 'none'
let closeBtn = 0

export const AlertContainer = props => {
  const { children, type, closeOff } = props
  typeAlert = type
  closeBtn = closeOff

  return (
    <Box
      className={`alert-container border-radius-bottom-start-xl border-radius-bottom-end-xl montserrat 
      ${type === 'fill' && 'fill-container'}`}
    >
      {children}
    </Box>
  )
}

export const AlertLink = props => {
  const { children, onClick } = props
  return (
    <button
      type="button"
      className={`NSP-alertLink ${
        underlineAlert && 'text-decoration-underline'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default function Alert(props) {
  const {
    info,
    check,
    pending,
    notification,
    underline,
    inverse,
    children,
    readed,
    time
  } = props
  underlineAlert = underline

  const AlertIcon = () => {
    let image

    if (pending) {
      image = <PendingIcon className="pending-icon" />
    } else if (info) {
      image = <InfoIcon className="info-icon" />
    } else if (check) {
      image = <CheckCircleIcon className="check-icon" />
    } else if (notification) {
      image = <CircleNotificationsIcon className="notification-icon" />
    }

    return image
  }

  function DefaultAlert() {
    return (
      <div
        className={`custom-alert ${typeAlert === 'fill' && 'fill-alert'}
      ${pending ? 'message-alert' : 'w-97'}`}
      >
        <div
          className={`d-flex align-items-center ${
            closeBtn ? 'col-12' : 'col-11'
          }`}
        >
          <div
            className={`alert-icon
          ${typeAlert === 'fill' ? 'px-4' : 'col-2'} 
          ${inverse && 'inverse'}
          ${readed && 'readed'}
          `}
          >
            <AlertIcon />
          </div>

          <div
            className={`fs-8 fs-md-7 text-truncate text-start pe-2 d-flex flex-grow-1
            ${typeAlert === 'fill' ? 'px-2' : 'fst-italic'} 
            ${underline && 'text-decoration-underline'}
            ${readed && 'readed'}
            `}
          >
            <small> {children} </small>
          </div>
          {pending && <div className="col-1 time">{time || '1m'}</div>}
        </div>

        <div className={`col-1 text-center ${closeBtn && 'd-none'}`}>
          <CloseIcon />
        </div>
      </div>
    )
  }

  return <DefaultAlert />
}
