import * as React from 'react'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const styleBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: 'none',
  boxShadow: 24,
  p: 4,
  pb: 5
}

const styleModal = {
  border: 'none !important'
}

export default function SMUModal(props) {
  const { show, clear, message } = props

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
    if (clear)
      clear(false)
  }

  React.useEffect(() => {
    setOpen(show)
  }, [show])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={styleModal}
      >
        <Box
          sx={styleBox}
          className="border-radius-xl bg-cyan text-center text-lg-start border-2 border-info"
        >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
