import React from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useDispatch } from 'react-redux'

import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
// import { FormattedMessage } from 'react-intl'

import ExImgClient from 'assets/images/ivana-square.jpg'
// import useHttp from '../../../../hooks/use-http'

const Sidebar = ({ currentPage, setSelectedPage, showMails, showNotifications }) => {
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  const userdata = JSON.parse(localStorage.getItem('userdata'))

  // const setLoading = value => {
  //   const payload = { type: value ? 'setLoading' : 'removeLoading' }
  //   dispatch(payload)
  // }

  return (
    <>
      <div className="col-12 position-relative">
        <button
          type="button"
          className="text-secondary mail-icon"
          onClick={() => setSelectedPage('show-alert')}
        >
          <div className="position-relative">
            <MailIcon />
            { showMails ? (<span> 0 </span>) : null }
          </div>
        </button>
        <button
          type="button"
          className="text-secondary mail-icon"
          onClick={() => setSelectedPage('show-alert')}
        >
          <div className="position-relative">
            <NotificationsIcon />
            { showNotifications ? (<span> 0 </span>) : null }
          </div>
        </button>

        <div className="text-center">
          <p>
            <img
              src={ExImgClient}
              alt="Profilo"
              className="avatar avatar-xxl border-radius-100 mt-5 bg-primary p-1"
            />
          </p>
          <h1 className="profileHeading">@ {userdata.name}</h1>
        </div>

        <ul className="nav-secondary">
          <li className={`${currentPage === 'profilo' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('profile')}
            >
              Profilo
            </button>
          </li>
          <li className={`${currentPage === 'freelancer-list' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('project-list')}
            >
              Progetti
            </button>
          </li>
          <li className={`${currentPage === 'pagamenti' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('pagamenti')}
            >
              Pagamenti
            </button>
          </li>
          <li className={`${currentPage === 'prodotti-aggiuntivi' && 'active'}`}>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => setSelectedPage('prodotti-aggiuntivi')}
            >
              Prodotti aggiuntivi
            </button>
          </li>
          
        </ul>
      </div>
    </>
  )
}

export default Sidebar
