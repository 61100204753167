/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

import geometricD from 'assets/images/sitemeup/geometricD.svg'
import geometricC from 'assets/images/sitemeup/geometricC.svg'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'

import './footerStyles.css'

function LandingFooter({ content }) {
  const { socials } = content

  return (
    <MKBox component="footer" className="position-relative overflow-hidden">
      <img
        src={geometricC}
        className="geometricC_llogo_footer z-index-1"
        alt="Site Me Up - theme"
      />
      <img
        src={geometricD}
        className="geometricD_rlogo z-index-1"
        alt="Site Me Up - theme"
      />
      <div className="container-fluid py-3 position-relative z-index-2">
        <div className="row px-3 d-flex align-items-center">
          <div className="col-6">
            <MKBox display="flex" alignItems="center">
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="white"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </div>
          <div className="col-6 text-right">
            <p className="footerText mb-0 text-right">
              Site Me Up Srl
              <strong>
                <br />
                Via lorem ipsum
                <br />
                00100 Roma
              </strong>
            </p>
          </div>
        </div>
      </div>
    </MKBox>
  )
}

// Typechecking props for the DefaultFooter
LandingFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired
}

export default LandingFooter
