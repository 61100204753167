import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import SMUModal from 'commoncomponets/Modal/SMUModal'

const LoadProject = props => {
  const profileData = useSelector(state => state.profile)
  const arryImg = profileData?.freelancedata.images

  const {
    name,
    // className,
    setArryImg,
    // saveData,
    deleteImage
  } = props
  const [classImg, setClassImg] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [msgModal, setMsgModal] = useState(null)
  // const [picture, setPicture] = useState(null)
  // const [imgData, setImgData] = useState(null)
  // eslint-disable-next-line
  const [deleteComponent, setDeleteComponent] = useState(0)

  const ref = useRef()
  const reset = () => {
    ref.current.value = ''
  }

  const convertBase64 = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })

  const onChangePicture = e => {
    if (e.target.files[0]) {
      // setPicture(e.target.files[0]);

      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.onload = async () => {
          const { width, height, size } = image

          if (image.height < 150) {
            setMsgModal('Formato immagine non corretto, riprovare')
            setShowModal(true)
            reset()
          } else if (size > 1048576) {
            setMsgModal('Immagini supportate max 1Mb')
            setShowModal(true)
            reset()
          } else {
            // console.log('H', height, '  W', width)
            setClassImg(width < height ? 'landscape' : 'portrait')

            // setImgData(image.src);

            // console.log(e.target.files[0], 'aaaa0')
            // const nome = e.target.files[0].name.split(".").pop();
            const [nome, estensione] = e.target.files[0].name.split('.')

            // const estensione = e.target.files[0].name.split(".").pop();
            const imageApp = await convertBase64(e.target.files[0])
            const newImage = {
              name: nome,
              estensione,
              image: imageApp
            }
            setArryImg(newImage)
            // console.log('chiamo save data dentro')
            reset()
          }
        }

        image.src = reader.result
      }

      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <>
      {arryImg && arryImg.length > 0
        ? arryImg.map(elem => (
            <div
              className={`position-relative max-width-300 col-sm-6 col-md-4 col-lg-3 m-0 mb-4 ${
                deleteComponent ? 'd-none' : ''
              }`}
            >
              <div
                className="clear-icon position-absolute"
                onClick={async e => {
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                  await deleteImage(elem.id)
                }}
                aria-hidden="true"
              >
                <ClearIcon />
              </div>
              <div
                className={`overflow-hidden border-radius-xl min-height-150 position-relative ${classImg}`}
              >
                <img src={elem.imagepath} className="center" alt="" />
              </div>
            </div>
          ))
        : null}

      <label
        htmlFor={name}
        className="d-none position-relative max-width-300 col-sm-6 col-md-4 col-lg-3 m-0 mb-4"
      >
        <SMUModal message={msgModal} show={showModal} clear={setShowModal} />
        <div className="w-100 border-radius-xl uploadImage text-primary border-primary">
          <input
            className="d-none"
            id={name}
            placeholder=""
            ref={ref}
            type="file"
            name="port"
            accept=".png, .jpg, .jpeg"
            onClick={e => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
            onChange={e => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              onChangePicture(e)
            }}
          />
          <div className="center">
            <AddIcon />
          </div>
        </div>
      </label>
    </>
  )
}

export default LoadProject
