/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { FormattedMessage } from 'react-intl'
import StarRating from 'components/SMU/StarRating/StarRating'

import { dumpProfile, setProfile, saveProfile } from 'slices/profileSlice'
import { setLoading, removeLoading } from 'slices/loadingSlice'

const Sidebar = ({ profileData, setSelectedPage, showMails, showNotifications, notifications }) => {
  const dispatch = useDispatch()

  // useEffect(() => {
  // console.log('SIDEBAR', profileData)
  // }, [profileData])

  const convertBase64 = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })

  const onUploadProfileImageChange = async event => {
    const file = event.target.files[0]
    const dataToSave = {
      ...profileData,
      estensionefoto: file.name.split('.').pop(),
      foto: await convertBase64(file)
    }
    await dispatch(setLoading)
    await dispatch(saveProfile(dataToSave))
    await dispatch(dumpProfile(profileData))
    dispatch(removeLoading)
    window.reload()
  }

  return (
    <>
      <div className="col-12 position-relative">
        <button
          type="button"
          className="text-secondary mail-icon"
          onClick={() => setSelectedPage('show-alert')}
        >
          <div className="position-relative">
            <MailIcon />
            { showMails ? (<span> 0 </span>) : null }
          </div>
        </button>
        <button
          type="button"
          className="text-secondary mail-icon"
          onClick={() => setSelectedPage('show-alert')}
        >
          <div className="position-relative">
            <NotificationsIcon />
            { notifications && notifications.filter((el)=>el.stato === 0).length > 0 ? (<span> {notifications.filter((el)=>el.stato === 0).length} </span>) : null }
          </div>
        </button>

        <div className="text-center">
          <p>
            <img
              src={profileData?.pathfoto}
              alt="Profilo"
              className="avatar avatar-xxl border-radius-100 mt-5 bg-primary p-1"
            />
          </p>
          <h1 className="profileHeading">
            @ {profileData?.nome} {profileData?.cognome}
          </h1>
          <label
            className="text-primary font-weight-light lato cursor-pointer h6"
            onChange={onUploadProfileImageChange}
            htmlFor="profilo"
          >
            <input
              type="file"
              value=""
              id="profilo"
              name="profilo"
              // eslint-disable-next-line no-console
              onChange={console.log('FILE????')}
              accept=".png, .jpg, .jpeg"
              className="d-none"
            />
            <small>Cambia immagine di profilo</small>
          </label>
        </div>

        <div className="mt-5 px-2 py-3 bg-tertiary text-white text-center text-uppercase">
          <p className="pb-0 mb-0">
            <strong>
              {profileData?.punteggio} <FormattedMessage id="punti" />
            </strong>{' '}
            - <FormattedMessage id="livello" />{' '}
            <strong>
              <FormattedMessage id={`livello${profileData?.livello}`} />
            </strong>
          </p>
        </div>
        <ul className="nav-secondary">
         
          <li className="active">
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => {
                setSelectedPage('profile')
              }}
            >
              Profilo
            </button>
          </li>
          <li>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => {
                setSelectedPage('project-list')
              }}

            >
              Progetti
            </button>
          </li>
          <li>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => {
                setSelectedPage('portfolio')
              }}
            >
              Portfolio
            </button>
          </li>
          <li>
            <button
              type="button"
              className="btn m-0 p-0 fs-7 box-shadow-none"
              onClick={() => {
                setSelectedPage('wallet')
              }}
            >
              My Wallet
            </button>
          </li>
        </ul>
        <StarRating />
      </div>
    </>
  )
}

export default Sidebar
