import React, { useEffect, useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Navigation } from 'swiper'

// Material Kit
import Icon from '@mui/material/Icon'
import MKTypography from 'components/MKTypography'
import Checkbox from '@mui/material/Checkbox'

import './Freelance.css'

// img freelance
import freel1 from 'assets/images/sitemeup/freelance1.png'
import freel2 from 'assets/images/sitemeup/freelance2.png'
import freel3 from 'assets/images/sitemeup/freelance3.png'
import freel4 from 'assets/images/sitemeup/freelance4.png'
import freel5 from 'assets/images/sitemeup/freelance5.png'
import freel6 from 'assets/images/sitemeup/freelance6.png'
import logo from 'assets/images/sitemeup/sitemeup_marchio.svg'
import useHttp from 'hooks/use-http'

const arrayFreeLance = [
  {
    image: freel1,
    check: true,
    disable: false,
    name: 'freel1',
    title: 'Programmazione',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    image: freel2,
    check: false,
    disable: false,
    name: 'freel2',
    title: 'Social Media',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    image: freel3,
    check: false,
    disable: false,
    name: 'freel3',
    title: 'SCO e Scrittura testi',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    image: freel4,
    check: false,
    disable: false,
    name: 'freel4',
    title: 'Grafica',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    image: freel5,
    check: false,
    disable: false,
    name: 'freel5',
    title: 'Digital Marketing',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    image: freel6,
    check: false,
    disable: false,
    name: 'freel6',
    title: 'Project Manager',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  }
]

export default function Freelance({value, onChange, freelanceTypes}) {
  SwiperCore.use([Navigation])

  console.log(freelanceTypes.findIndex((el)=>el.id === value), 'val');

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15%',
    height: '100%',
    textAlign: 'center',
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    fontSize: '3em',

    '&:hover, &:focus': {
      opacity: 1
    }
  }


  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  return (
    <>
    {freelanceTypes.length > 0 ? (<>
      <section className="col-12 position-relative p-3 pt-5 overflow-hidden bg-radial-trasparent">
        <Swiper
          onInit={({ params, navigation }) => {
            const { navigation: nav } = params

            nav.prevEl = navigationPrevRef.current
            nav.nextEl = navigationNextRef.current
            navigation.init()
            navigation.update()
          }}
          initialSlide={parseInt(freelanceTypes.findIndex((el)=>el.id === value), 10) || 0}
          onSlideChange={(swiper)=>{

            onChange(freelanceTypes[swiper.activeIndex]?.id || null)
          }}
          spaceBetween={100}
          className="mySwiper min-height-300 d-flex justify-content-center align-items-center overflow-visible p-1 position-relative"
        >
          {freelanceTypes.map(item => (
            <SwiperSlide key={item.name}>
              <div className="w-80 m-auto">
                <div
                  className={`card rounded-2 fl-skew ${
                    item?.id === value ? 'bg-light-azure' : 'bg-gray-300'
                  } min-height-160 py-4`}
                  ref={() => item.name}
                >
                  <input
                    name="id_freelance"
                    id={item.name}
                    type="radio"
                    value={item.id}
                    className="d-none"
                  />
                  <div className="no-fl-skew d-flex align-items-center justify-content-end">
                    <img
                      className={`fl-img z-index-2 ${item.name} ${
                        item?.id === value ? '' : 'grayscale-100'
                      }`}
                      src={item.pathfoto}
                      alt="sitemeup"
                      width="200"
                      height="auto"
                      style={{height:'auto'}}
                    />
                    <div
                      className={`col-7 p-2 zilla z-index-3 font-weight-bold ${
                        item?.id === value ? 'bg-fl-desc' : 'bg-fl-desc-disabled'
                      }`}
                    >
                      {item?.id === value ? (
                        <Checkbox {...label} checked={item?.id === value} color="success" />
                      ) : (
                        <></>
                      )}

                      <h6
                        className={`${
                          item?.id !== value ? 'text-secondary' : 'text-dark'
                        } text-start text-uppercase`}
                      >
                        {item.name}
                      </h6>
                      <p
                        className={`${
                          item?.id !== value ? 'text-secondary' : 'text-dark'
                        } fs-6 lh-1`}
                      ><span dangerouslySetInnerHTML={{__html:item.description}} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </SwiperSlide>
          ))}

          <MKTypography
            variant="h2"
            color="info"
            sx={{
              ...navigationStyles,
              left: -20
            }}
            ref={navigationPrevRef}
            className="slider-larrow"
          >
            <Icon>chevron_left</Icon>
          </MKTypography>
          <MKTypography
            variant="h2"
            color="info"
            sx={{
              ...navigationStyles,
              right: -20
            }}
            ref={navigationNextRef}
            className="slider-rarrow"
          >
            <Icon>chevron_right</Icon>
          </MKTypography>
        </Swiper>
      </section>
      </>) : null}
    </>
  )
}
