/* eslint-disable react/no-array-index-key */
import './StarRating.css'

export default function StarRating() {
  return (
    <div className="mt-4 mb-4 mb-lg-auto profileRating">
      <h4>Valutazione media</h4>
      <div>
        {/* {Array.from({ length: 3 }).map((el, i) => (
              <span className="fa fs-2 fa-star text-warning me-1" />
            ))} */}
        {Array.from({ length: 5 }).map((_, i) => (
          <span key={i} className="fa fa-star-o fs-2 me-1" />
        ))}
        <p className="mt-1">
          <strong>Non ci sono recensioni</strong>
        </p>
      </div>
    </div>
  )
}
