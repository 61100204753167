import SiteMeUpMarchio from 'assets/images/sitemeup/sitemeup_marchio.svg'
import SiteMeUpGaranzia from 'assets/images/sitemeup/garanzia.jpg'

function Warranty() {
  return (
    <>
      <section className="pt-5 pb-5 bg-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto text-center pb-0">
              <div className="immagine apertura">
                <img
                  src={SiteMeUpMarchio}
                  alt="sitemeup"
                  style={{ width: 'auto', height: '35px' }}
                  className="logo"
                />
              </div>
              <h2 className="text-white zilla">Garanzia Site me Up</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto pb-lg-5">
              <div className="card border-radius-lg bg-white p-3 mt-3 p-lg-4 text-center text-lg-start">
                <div className="row d-flex justify-content-center zilla">
                  <div className="col-md-6 col-lg-7 col-12 mb-lg-0 mb-4">
                    <h5 className="lato mb-0">SODDISFATTI O RIMBORSATI</h5>
                    <h2 className="fw-normal">
                      Metti al sicuro il tuo investimento
                      <br />
                      <span className="fw-bold text-secondary">
                        Affidati a Site Me Up
                      </span>
                    </h2>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam eaque ipsa, quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt, explicabo. Nemo
                      enim ipsam voluptatem, quia voluptas sit, Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium.
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-5 col-12 mb-lg-0 mb-4">
                    <img
                      src={SiteMeUpGaranzia}
                      className="w-100"
                      alt="sitemeup garanzia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Warranty
