import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { dumpProfile } from 'slices/profileSlice'
import { AuthContext } from 'store/auth-context'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import './Login.css'
import logo from 'assets/images/sitemeup/sitemeup_logo-01.svg'
import FormAccesso from './FormAccesso'
import FormRegistrazione from './FormRegistrazione'
import useHttp from '../../../hooks/use-http'

const LoginForm = ({ show, origin, close, sendLogin, sendLogout }) => {
  const authContext = useContext(AuthContext)
  // eslint-disable-next-line no-unused-vars
  const [registered, setRegistered] = useState(false)
  const [loginForm, setLoginForm] = useState(0)
  const [error, setError] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [passRecuperata, setPassRecueprata] = useState(false)
  const { sendRequest: sendLoginRequest, isLoading: isLoginLoading } = useHttp()
  const dispatch = useDispatch()

  const recupera = (user, from) => {
    sendLoginRequest({
      url: '/api/recuperapassword',
      methodH: 'POST',
      bodyH: user,
      manageData: async e => {
        setCompleted(true)
        setPassRecueprata(true)
      }
    })
  }



  const login = (user, from) => {
    // console.log('LOADING?', isLoginLoading)
    let data = {}
    sendLoginRequest({
      url: '/api/login',
      methodH: 'POST',
      bodyH: user,
      manageData: async e => {
        if (e.status === 'KO') {
          setError(e.message)
          data = {
            ...data,
            token: '',
            email: '',
            name: '',
            id: '',
            tipo: '',
            isLoggedIn: false,
            freelancedata: null,
            clientedata: null
          }
          authContext.sendAuthState(data)
          sendLogout()
        } else {
          data = {
            ...data,
            token: e.data.token,
            email: user.email,
            name: e.data.userdata.name,
            id: e.data.userdata.id,
            tipo: e.data.userdata.tipo.toLowerCase(),
            isLoggedIn: true,
            freelancedata: e.data.freelancedata ? e.data.freelancedata : null,
            clientedata: e.data.clientedata ? e.data.clientedata : null
          }
          setError(false)
          authContext.sendAuthState(data)
          // authContext.authIdStateHandler(e.data)
          await dispatch(dumpProfile(data))
          if (origin === '/crea-sito' && data.tipo === 'cliente') {
            sendLogin(origin)
          } else if (
            origin.includes('progetto') &&
            data.tipo === 'freelance' &&
            data.freelancedata?.approved
          ) {
            sendLogin(origin)
          } else sendLogin('/profilo')
          if (from === 'register') {
            setCompleted(true)
          }
        }
      }
    }).then(() => {})
  }
  return (
    <Dialog open={show} onClose={close} className="SMURegistration">
      <DialogTitle>
        <div className="container">
          <div className="row">
            <div className="col-9 offset-1 mx-auto">
              <img src={logo} alt="SiteMeUp" className="img-fluid" />
            </div>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        {!completed ? (<>
        {!registered ? (
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="btn-login-form">
                  <button
                    type="button"
                    className={`${!loginForm && 'active'}`}
                    alt=""
                    onClick={() => setLoginForm(0)}
                  >
                    Accesso
                  </button>
                  |
                  <button
                    type="button"
                    className={`${loginForm && 'active'}`}
                    alt=""
                    onClick={() => setLoginForm(1)}
                  >
                    Registrati
                  </button>
                </h2>
              </div>
            </div>

            {loginForm ? (
              <FormRegistrazione
                login={(u)=>login(u, 'register')}
                isLoginLoading={isLoginLoading}
                close={close}
                setCompleted={setCompleted}
              />
            ) : (
              <FormAccesso
                login={login}
                isLoginLoading={isLoginLoading}
                error={error}
                recupera={recupera}
              />
            )}
          </div>
        ) : (
          <>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="mt-3 text-white">Accesso effettuato.</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn-lg d-cell border-0 btn-block"
                    onClick={close}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        </>) : (<>
          <div className="container">
            {passRecuperata ? (<>
              <div className="row">
                <div className="col-12">
                  <p className="mt-3 text-white text-center">Controlla la tua mail per recuperare la tua password.</p>
                </div>
              </div>
            </>) : (<>
              <div className="row">
                <div className="col-12">
                  <p className="mt-3 text-white text-center">Registrazione completata.</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center text-white">
                  <p>Verrai ora reindirizzato al tuo profilo utente per completare i tuoi dati.</p>
                </div>
              </div>
              </>)}
          </div>
        </>)}
      </DialogContent>
    </Dialog>
  )
}

export default LoginForm
