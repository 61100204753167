import { useEffect, useState } from 'react'
import useHttp from 'hooks/use-http'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SMUPagination from 'components/SMU/Pagination/Pagination'
import DrawerFilterBar from 'pagesitemeup/WebDesigners/sections/DrawerFilterBar'
import FilterBar from 'pagesitemeup/WebDesigners/sections/FilterBar'
import SearchBar from 'pagesitemeup/WebDesigners/sections/SearchBar'
import FreelancerPanel from 'pagesitemeup/WebDesigners/sections/FreelancerPanel'
import listFreelancer from 'pagesitemeup/WebDesigners/listWebDesigners.json'
import { SMULoading } from 'components/SMU'

import './WebDesigners.css'


export default function WebDesigners() {
  const webDesignerForPage = 5
  const list = listFreelancer

  const [freelancesList, setFreelancesList] = useState([])
  const [filteredFreelancesList, setFilteredFreelancesList] = useState([])
  const [skillList, setSkillList] = useState([])
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState(0)
  const [filters, setFilters] = useState({})

  const {
    sendRequest: sendFreelancesRequest,
    isLoading: isFreelancesLoading
  } = useHttp()

  const {
    sendRequest: sendSkillsRequest,
    isLoading: isSkillLoading
  } = useHttp()

  const manageFreelances = (dt, dts) => {
    setFreelancesList(dt.data.map((el)=>({
      ...el,
      name:el?.nome,
      img:el?.pathfoto || '',
      desc:el?.presentazionebreve,
      tipo:el?.desctipologia,
      skills:el?.skills.map((ej)=> dts.find((ex)=>ex.id === ej.id_skill)?.name || '' )
    })))
  }

  const refreshFreelances = (dts) => {
    sendFreelancesRequest({
      url: '/sitemeup/freelances',
      manageData: (dt)=>manageFreelances(dt, dts)
    })
  }

  const manageSkills = (dt) => {
    setSkillList(dt.data)
    refreshFreelances(dt.data)
  }

  const refreshSkills = () => {
    sendSkillsRequest({
      url: '/sitemeup/freelanceskills',
      manageData: manageSkills
    })
  }

  useEffect(()=>{
    refreshSkills()
  }, [])

  useEffect(()=>{ 
    console.log(filters, 'filtering')
    const filt = freelancesList.filter((el)=>{
      if (filters.text && filters.text.trim() !== '') {
        return el.name.toLowerCase().includes(filters.text.trim().toLowerCase())
      }
      return el
    }).filter((el)=>{
     if (filters?.citta && filters.citta !== '') {
      return el.citta === filters.citta
     }
     return el
    }).filter((el)=>{
      if (filters.skills && filters.skills.length > 0 ){
        return el.skills.some((item) => filters.skills.includes(item))
      }
      return el
    }).sort((a,b)=>{ 
      if (order === 1) 
        return a.name < b.name ? 1 : -1
      return a.name > b.name ? 1 : -1 
    })

    setFilteredFreelancesList(filt)
  }, [freelancesList, filters, order])

  const getNumPages = () => {
    const base = Math.floor(filteredFreelancesList.length / webDesignerForPage)
    const diff = filteredFreelancesList.length / webDesignerForPage - base
    return base + (diff > 0)
  }

  const orderChange = e => {
    setOrder(e.target.value)
  }
  const handleKeyWord = (e) => {
    setFilters({...filters, text:e})
  }
  const handleFilter = (e) => {
    setFilters(e)
  }

  return (
    <>
      <SMUNavbar />
      <SMULoading show={isFreelancesLoading || isSkillLoading} />

      <div className="d-flex flex-grow-1 bg-white py-4">
        <div className="col-11 h-100 mx-auto">
          <SearchBar handleKeyWord={handleKeyWord} />
          <div className="d-flex">
            <div className="d-block d-lg-none">
              <DrawerFilterBar handleFilter={handleFilter} skills={skillList.map((el)=>el.name)} cities={freelancesList.map((el)=>el.citta).filter((el)=>el !== '' && el !== null)} />
            </div>
            <div className="col-3 py-3 d-lg-block d-none">
              <FilterBar handleFilter={handleFilter} skills={skillList.map((el)=>el.name)} cities={freelancesList.map((el)=>el.citta).filter((el)=>el !== '' && el !== null)} />
            </div>
            <div className="col-12 col-lg-9 py-3">
              <div className="col-12 d-flex flex-wrap">
                <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <Select
                    value={order}
                    onChange={orderChange}
                    InputLabelProps={{ shrink: true }}
                    label="Ordinamento"
                    sx={{ height: '50px', width: '150px' }}
                  >
                    <MenuItem value={0}>A-Z</MenuItem>
                    <MenuItem value={1}>Z-A</MenuItem>
                  </Select>
                  &nbsp;&nbsp;
                  <b className="fs-7">{filteredFreelancesList.length} web designer trovati</b>
                </div>

                <div className="col-12 col-lg-7 mt-3 d-flex justify-content-center justify-content-lg-end">
                  <SMUPagination
                    pages={getNumPages()}
                    currPage={page}
                    setPage={setPage}
                  />
                </div>
              </div>
              <div className="col-12">
                {filteredFreelancesList.map((item, index) => {
                  const curr = index + 1
                  const min =
                    webDesignerForPage * page - (webDesignerForPage - 1)
                  const max = webDesignerForPage * page
                  const boolean = curr <= max && curr >= min

                  return boolean && <FreelancerPanel item={item} />
                })}
              </div>

              <div className="col-12 d-flex justify-content-center justify-content-lg-end">
                <SMUPagination
                  pages={getNumPages()}
                  currPage={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
