import './Caratteristiche.css'

const CaratteristicheDesktop = props => {
  const { show, template } = props

  return (
    <div className={`caratteristiche ${show && 'active'}`}>
      <desc>Caratteristiche tecniche</desc>
      <ul>
        <li>
          <span>Ultimo aggiornamento</span>
          <span>25 Dicembre 2021</span>
        </li>
        <li>
          <span>Data pubblicazione</span>
          <span>25 Marzo 2015</span>
        </li>
        <li>
          <span>Alta risoluzione</span>
          <span>SI</span>
        </li>
        <li>
          <span>I file includono</span>
          <span>{template?.descrizionetecnica}</span>
        </li>
        <li>
          <span>Browser compatibili</span>
          <span dangerouslySetInnerHTML={{__html: template?.compatibilita}} />
        </li>
      </ul>
    </div>
  )
}
export default CaratteristicheDesktop
