/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef } from 'react'

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// SwiperJS react components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

// SwiperJS styles
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// @mui material components
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'

// Material Kit 2 PRO React components
import MKTypography from 'components/MKTypography'

import BgTestimonialLeft from 'assets/images/sitemeup/bg_testimonial_left.svg'
import BgTestimonialRight from 'assets/images/sitemeup/bg_testimonial_right.svg'

function OurClient({ element }) {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation])

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15%',
    height: '80%',
    textAlign: 'center',
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    fontSize: '5em',

    '&:hover, &:focus': {
      opacity: 1
    }
  }

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <>
      <section className="bg-primary position-relative overflow-hidden">
        <div className="img_bg">
          <img
            src={BgTestimonialLeft}
            className="img-fluid position-absolute z-index-1 w-auto h-100 top-0 start-0 translate-middle-x"
            alt=""
          />
          <img
            src={BgTestimonialRight}
            className="img-fluid position-absolute z-index-1 w-auto h-100 top-0 start-100 translate-middle-x"
            alt=""
          />
        </div>
        <div className="container p-3 container-skew bg-light-azure w-100 h-100 position-relative z-index-3 px-6 px-lg-3">
          <div className="no-skew mx-1 pt-5 pb-3 mx-lg-5 py-lg-5 position-relative z-index-3">
            <div className="row">
              <div className="col-md-8 mx-auto text-center">
                <h2 className="">Soddisfazione dei nostri clienti</h2>
              </div>
            </div>
            <div className="row overflow-hidden px-0 pb-4">
              <Swiper
                onInit={({ params, navigation }) => {
                  const { navigation: nav } = params

                  nav.prevEl = navigationPrevRef.current
                  nav.nextEl = navigationNextRef.current
                  navigation.init()
                  navigation.update()
                }}
                autoplay={{ delay: 5000 }}
                speed={800}
                spaceBetween={0}
                slidesPerView={4}
                breakpoints={{
                  1080: {
                    slidesPerView: 4
                  },
                  768: {
                    spaceBetween: 20,
                    slidesPerView: 2
                  },
                  320: {
                    slidesPerView: 1
                  }
                }}
                loop
              >
                {element.map(item => (
                  <SwiperSlide>
                    <Container>
                      <>
                        <div className="col-lg-12 col-xs-12 mb-md-4 mb-7">
                          <div className="bg-ultra-light-azure skew-y mt-6 d-flex positon-relative border-radius-lg">
                            <div className="no-skew-y">
                              <div className="text-center position-relative pb-5">
                                <div className="rounded-circle text-center position-absolute translate-middle-x start-50 z-index3 overflow-visible mt-n5">
                                  <img
                                    className="avatar bg-white avatar-xxl"
                                    src={item.image}
                                    alt="avatar"
                                  />
                                </div>
                              </div>
                              <div className="card-body mt-3">
                                <h5 className="text-capitalize mb-0 zilla">
                                  {item.name}
                                </h5>
                                <p className="mt-1 zilla">{item.text}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Container>
                  </SwiperSlide>
                ))}
                <MKTypography
                  variant="h2"
                  color="info"
                  sx={{
                    ...navigationStyles,
                    left: -10
                  }}
                  ref={navigationPrevRef}
                  className="slider-larrow"
                >
                  <Icon>arrow_left</Icon>
                </MKTypography>
                <MKTypography
                  variant="h2"
                  color="info"
                  sx={{
                    ...navigationStyles,
                    right: -10
                  }}
                  ref={navigationNextRef}
                  className="slider-rarrow"
                >
                  <Icon>arrow_right</Icon>
                </MKTypography>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurClient
