import React, { useState } from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import Input from '@mui/material/Input'
import { Select, MenuItem } from '@mui/material'
import SMUModal from 'commoncomponets/Modal/SMUModal'

import '../components/common.css'
import NuovoProgetto from './images/nuovo-progetto_2x.png'
import AggiornaEsistente from './images/aggiorna_esistente_2x.png'


const ArtDirection = props => {
  const { project, sendUpdate, handleNext, handleBack, publishProject } = props
  const { templatesceltodafreelance } = project
  const [showModal, setShowModal] = useState(false)

  const handlePublish = () => {
    setShowModal(true);
  }

  const confirmPublish = () => {
    setShowModal(false)
    publishProject()
  }

  return (

    
    <div className="col-11 mx-auto">
      <SMUModal 
        show={showModal}
        clear={()=>setShowModal(false)}
        message={(<>
          <h2>Sicuro di voler {templatesceltodafreelance ? 'pubblicare' : 'salvare'}?</h2>
          <p>Siamo contenti tu voglia pubblicare con noi il tuo progetto :<br />
Ricorda che da questo momento le specifiche che hai inserito non potranno più essere modificate e saranno utilizzate dai nostri freelance per formulare le offerte.</p>
          <button
            type="button"
            className="btn btn-lg btn-primary letter-spacing-1 swpier-next"
            alt=""
            onClick={() => confirmPublish()}
          >
            {templatesceltodafreelance ? 'Pubblica' : 'Salva e procedi'}
          </button>
        </>)}
      />

      <div className="row">
        <div className="col-12">
          <p className="fs-5 text-center">
            <h2>Vuoi dare una direzione artistica al tuo progetto?</h2>
          </p>
          
        </div>
      </div>
      
      
      <div className="row mt-5">

        <div className="col-md-4 col-6 offset-md-2">
        <button
          type="button"
          className={`selection-button no-ratio ${
            templatesceltodafreelance && 'selected'
          }`}
          onClick={() =>  sendUpdate('templatesceltodafreelance', 1)}
          style={{ borderStyle: 'solid' }}
        >
            <div className="selection-button--text align-items-center d-flex" style={{aspectRatio:"4/3"}}>
                Lascio libero il web designer
            </div>
        </button>
        </div>
        <div className="col-md-4 col-6">
        <button
          type="button"
          className={`selection-button no-ratio ${
            !templatesceltodafreelance && 'selected'
          }`}
          onClick={() =>  sendUpdate('templatesceltodafreelance', 0)}
          style={{ borderStyle: 'solid' }}
        >
            <div className="selection-button--text align-items-center d-flex" style={{aspectRatio:"4/3"}}>
                Scelgo io il template per il progetto
            </div>
        </button>
        </div>
      </div>
      

      <div className="row mt-3">
        <div className="col-6">
            <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBack()}
                >
                  <small>Indietro</small>
            </button>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-lg btn-primary letter-spacing-1 swpier-next"
            alt=""
            onClick={() => handlePublish()}
          >
            {templatesceltodafreelance ? 'Pubblica' : 'Salva e procedi'}
          </button>

        </div>
      </div>
    </div>
  )
}

export default ArtDirection
