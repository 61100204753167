import React, { useEffect, useRef } from 'react'
import { IntlProvider } from 'react-intl'
import it from 'locale/it.json'
import en from 'locale/en.json'
import { useCookies } from 'react-cookie'

export const Context = React.createContext()

export const IntlProviderWrapper = ({ children }) => {
  const [LocCookie, setLocCookie] = useCookies(['language'])
  const DateExpires = new Date()
  DateExpires.setDate(DateExpires.getDate() + 999)
  let LanguageCookie
  const refRender = useRef(true)

  const setCookie = lang => {
    if (lang === 'it-IT' || lang === 'it') {
      setLocCookie('language', 'it', { path: '/', expires: DateExpires })
    } else {
      setLocCookie('language', 'en', { path: '/', expires: DateExpires })
    }
    window.location.reload()
  }

  useEffect(() => {
    refRender.current = false
  }, [])

  if (LocCookie.language === undefined) {
    if (refRender.current) {
      const locale = navigator.language
      if (locale === 'it-IT' || locale === 'it') {
        LanguageCookie = 'it'
      } else {
        LanguageCookie = 'en'
      }
    }
  } else {
    LanguageCookie = LocCookie.language
  }
  //   const contextValue = {
  //     LanguageCookie,
  //   };

  return (
    <Context.Provider value={{ LanguageCookie, setCookie }}>
      <IntlProvider
        messages={LanguageCookie === 'it' ? it : en}
        locale={LanguageCookie}
        defaultLocale={LanguageCookie}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}
