/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import sendRequest from 'use-http-in-slices'

const initialState = {
  data: null,
  status: 'idle'
}

export const dumpUser = createAsyncThunk('user/dumpUser', isv =>
  sendRequest({
    url: '/api/user',
    manageData: e => {
      // console.log('ED', e.data)
      if (isv) e.data.email_verified_at = null
      // console.log('ED', e.data)
      return e.data
    }
  })
)
// console.log('cisv', isv)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dumpUser.pending, state => {
        state.status = 'loading'
      })
      .addCase(dumpUser.fulfilled, (state, { payload }) => {
        // destructure to { payload }
        // console.log('USERRR', payload)
        state.data = payload
        state.status = 'idle'
      })
      .addCase(dumpUser.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
  }
})

export const selectIsEmailVerified = state =>
  // console.log('SELECT', state.user)
  !!state.user.data?.email_verified_at

export default userSlice.reducer
