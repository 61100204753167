/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'

// Material Kit 2 PRO React examples
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'

import DefaultFooter from 'commoncomponets/Footer/SMUFooter'

// Coworking page sections

import HeaderHp from 'pagesitemeup/HomePage/sections/HeaderHp'
import AboutUs from 'pagesitemeup/HomePage/sections/AboutUs'
import RecentWork from 'pagesitemeup/HomePage/sections/RecentWork'
import Warranty from 'pagesitemeup/HomePage/sections/Warranty'
import LastNews from 'pagesitemeup/HomePage/sections/LastNews'
import OurClient from 'pagesitemeup/HomePage/sections/OurClient'
import SiteMeUpInfo from 'pagesitemeup/HomePage/sections/SiteMeUpInfo'

// Routes
import footerRoutes from 'footer.routes'

// Images

import recentWorkhomePage1 from 'assets/images/sitemeup/recentWork1.jpg'
import recentWorkhomePage2 from 'assets/images/sitemeup/recentWork2.jpg'
import recentWorkhomePage3 from 'assets/images/sitemeup/recentWork3.jpg'

import phUserA from 'assets/images/examples/ph_userA.png'
import phUserB from 'assets/images/examples/ph_userB.png'
import phUserC from 'assets/images/examples/ph_userC.png'
import phUserD from 'assets/images/examples/ph_userD.png'

import phBlog1 from 'assets/images/examples/ex_blog1.png'
import phBlog2 from 'assets/images/examples/ex_blog2.png'
import phBlog3 from 'assets/images/examples/ex_blog3.png'
import phBlog4 from 'assets/images/examples/ex_blog4.png'

function HomePage() {
  const arrayOurClient = [
    {
      image: phUserB,
      name: 'Nome C.',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
    },
    {
      image: phUserA,
      name: 'Nome C.',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
    },
    {
      image: phUserC,
      name: 'Nome C.',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
    },
    {
      image: phUserD,
      name: 'Nome C.',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
    }
  ]
  const arrayRecentWork = [
    {
      image: recentWorkhomePage1,
      freelencername: 'Luigi Lo Cascio',
      clientname: 'MarkersBros.it'
    },
    {
      image: recentWorkhomePage2,
      freelencername: 'Jennnifer Lawrence',
      clientname: 'Vesteeti.com'
    },
    {
      image: recentWorkhomePage3,
      freelencername: 'Morgan Freeman',
      clientname: 'SevenSeconds.com'
    }
  ]
  const arrayLastNews = [
    {
      image: phBlog1,
      title: 'Le Novità di WordPress 5.5  Analisi Approfondita',
      text: 'WordPress 5.5 “Eckstine” è arrivato e per noi è giunto il momento di presentare le modifiche e le funzionalità più rilevanti che vengono aggiunte al Core con la seconda release di WordPress dell’anno.',
      link: '/homepage'
    },
    {
      image: phBlog2,
      title: 'Come descrivere un prodotto in maniera efficace sul tuo sito',
      text: 'Oggi affrontiamo un argomento che ruota attorno ad una strategia davvero sottovalutata: l’importanza della scelta delle parole quando decidi di “presentare”, cioè vendere, il tuo prodotto…',
      link: '/homepage'
    },
    {
      image: phBlog3,
      title: 'Foto e video, perché sono importanti nei social media',
      text: 'Sono lontanissimi e quasi dimenticati i tempi in cui pubblicare semplicemente un aggiornamento di testo sui social media era sufficiente per farsi notare. Ora per attirare davvero l’attenzione…',
      link: '/homepage'
    },
    {
      image: phBlog4,
      title: 'Cosa sono gli aggiornamenti dell’algoritmo di Google?',
      text: 'Il tuo sito è improvvisamente crollato e fa un decimo delle visite registrate il giorno prima? Improvvisamente ti ritrovi con un traffico talmente basso e non sai il motivo? La risposta…',
      link: '/homepage'
    }
  ]
  return (
    <>
      <MKBox variant="contained" bgColor="secondary" shadow="sm">
        <SMUNavbar />
      </MKBox>
      <MKBox component="header" position="relative">
        <HeaderHp />
      </MKBox>
      <Card className="bg-gray">
        <AboutUs />
      </Card>
      <Card className="rounded-0">
        <Warranty />
        <RecentWork element={arrayRecentWork} />
        <SiteMeUpInfo />
        <LastNews element={arrayLastNews} />
        <OurClient element={arrayOurClient} />
      </Card>
      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  )
}

export default HomePage
