import React, { useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import './ClientOffer.css'

const ClientOffer = () => {
  const [data, setData] = useState({})

  const handleBudget = e => setData(obj => ({ ...obj, budget: e.target.value }))
  const handleLocation = e =>
    setData(obj => ({ ...obj, location: e.target.value }))
  // const handleLinks = e => setData(obj => ({ ...obj, links: [...obj.links, e.target.value] }))
  const handleDescription = e =>
    setData(obj => ({ ...obj, desc: e.target.value }))
  const handleImg = () => setData(obj => ({ ...obj, img: [...obj.img] }))
  // console.log('FILE', e.target)

  const PlusBtn = () => (
    <button className="plus-btn" alt="" type="button">
      {' '}
      <AddIcon />{' '}
    </button>
  )
  const MinusBtn = () => (
    <button className="plus-btn" alt="" type="button">
      {' '}
      <RemoveIcon />{' '}
    </button>
  )

  const InputFile = ({ add }) => {
    const btn = useRef(null)

    return (
      <div className="col-12 inputFile" ref={btn}>
        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, .pdf"
          className="files-template"
          onChange={handleImg}
        />
        {add ? <PlusBtn /> : <MinusBtn />}
      </div>
    )
  }

  // useEffect(() => if (data.img) setData((obj)), []) // onMount

  return (
    <div className="client-offer lato col-lg-10">
      <div className="col-12 col-lg-6">
        <span className="desc">Budget per il tuo prodotto</span>
        <input
          type="text"
          placeholder="Es. € 3500"
          className={`form-control ${data.budget && 'active'}`}
          onKeyDown={handleBudget}
          required
        />
      </div>

      <div className="col-12 col-lg-6">
        <span className="desc">Inserisci la località</span>
        <input
          type="text"
          placeholder="es. Città del messico"
          className={`form-control ${data.location && 'active'}`}
          onKeyDown={handleLocation}
          required
        />
      </div>

      <div className="col-12">
        <span className="desc">
          Inserisci link di siti COME ispirazione per il web designer
        </span>
        <div className="col-12">
          <div className="d-flex align-items-center">
            <input
              type="text"
              placeholder="Inserisci URL del sito"
              className="form-control w-90 mb-2"
            />
            <MinusBtn />
          </div>
          <div className="d-flex align-items-center">
            <input
              type="text"
              placeholder="Inserisci URL del sito"
              className="form-control w-90 mb-2"
            />
            <PlusBtn />
          </div>
        </div>
      </div>

      <div className="col-12">
        <span className="desc">
          CARICA DOCUMENTI O IMMAGINI COME ISPIRAZIONE PER IL WEB DESIGNER
        </span>
        <span className="desc fs-7 text-primary mt-n1 fw-lighter">
          Caricamenti (pdf, jpg, png MAX 1 MB)
        </span>
        <div className="col-12 d-flex flex-column mt-4">
          <InputFile />
          <InputFile />
          <InputFile add />
        </div>
      </div>

      <div className="col-12">
        <span className="desc">Descrivi il tuo progetto</span>
        <textarea
          name="descProject"
          className={`form-control ${data.desc && 'active'}`}
          rows={5}
          placeholder="Racconta i particolari del tuo progetto"
          onKeyDown={handleDescription}
          maxLength={4000}
        />
      </div>
    </div>
  )
}

export default ClientOffer
