import Cookies from 'js-cookie'

const apiURL = `${process.env.REACT_APP_API_ENDPOINT}`

const sendRequest = async ({ url, methodH, headersH, bodyH, manageData }) => {
  // console.log('URL', url)
  let result = {
    redirect: false,
    data: null,
    error: null
  }

  try {
    // console.log('BODYHHHHH', JSON.stringify(bodyH))

    if (methodH?.toUpperCase() === 'POST' || methodH?.toUpperCase() === 'PUT') {
      const csrfRequest = await fetch(`${apiURL}/csrf-cookie`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json'
        },
        credentials: 'include'
      })
    }

    const mainHeaderSettings = !localStorage.getItem('token')
          ? {
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || ''
            }
          : {
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }


    const response = await fetch(apiURL + url, {
      credentials: 'include',
      method: methodH || 'GET',
      headers: headersH
            ? {
                ...mainHeaderSettings,
                ...headersH
              }
            : {
                ...mainHeaderSettings,
                'content-type': 'application/json',
                accept: 'application/json'
              },
      body: JSON.stringify(bodyH) || null
    })

    if (!response.ok)
      return { ...result, error: 'Error thrown from sendRequest function' }

    const json = await response.json()
    if (json.message === 'Unauthenticated.')
      return { ...result, redirect: true }

    if (json.status === 'KO')
      return { ...result, error: 'Error thrown from status ko' }

    result = { ...result, data: json.data ?? json }
  } catch (err) {
    localStorage.removeItem('token')
    localStorage.removeItem('userdata')
    // console.log(err || "Something's Wrong")
    return { ...result, redirect: true, error: true }
  }

  return manageData(result)
}

export default sendRequest
