import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  entities: [],
  status: 'idle'
}

export const dumpTags = createAsyncThunk('tags/dumpTags', () => {
  const response = fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/sitemeup/tags`,
    {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
    .then(res => res.json())
    .then(json => json.data)
    // eslint-disable-next-line
    .catch(err => console.error('Tags', err))

  return response
})

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(dumpTags.pending, state => {
        /* eslint-disable no-param-reassign */
        state.status = 'loading'
      })
      .addCase(dumpTags.fulfilled, (state, action) => {
        // console.log('TAGS', action)
        state.entities = action.payload
        state.status = 'idle'
      })
      .addCase(dumpTags.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
  }
})

export default tagsSlice.reducer
