/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import sendRequest from 'use-http-in-slices'
// import { setLoading, removeLoading } from './loadingSlice'

const initialState = {
  freelancedata: null,
  clientedata: null,
  status: 'idle'
}
// Volendo si puo' gestire la loadingSlice da qui.
// export const dumpProfile = createAsyncThunk('profile/dumpProfile', (reference, { dispatch }) => {
export const dumpProfile = createAsyncThunk(
  'profile/dumpProfile',
  reference => {
    // console.log('DUMP', reference)
    // dispatch(setLoading())
    const tipo = reference.tipo.toLowerCase()
    const url =
      reference.freelancedata || reference.clientedata
        ? `/sitemeup/${tipo}?id=${reference[`${tipo}data`].id}`
        : `/sitemeup/${tipo}?id=${reference.id}`

    return sendRequest({
      url,
      manageData: e => ({ ...e.data, tipo })
      // dispatch(removeLoading()) // Come sopra.
    })
  }
)

export const dumpValutazioni = createAsyncThunk('profile/dumpValutazioni', id =>
  sendRequest({
    url: `/sitemeup/valutazionifreelance?id=${id}`,
    manageData: e => e
  })
)

// eslint-disable-next-line camelcase
export const dumpSkills = createAsyncThunk('profile/dumpSkills', () =>
  sendRequest({
    url: '/sitemeup/freelanceskills?id_category=1',
    manageData: e => e
  })
)

export const saveProfile = createAsyncThunk(
  'profile/saveProfile',
  (profile, { getState }) => {
    if (!profile)
      profile =
        getState().profile.freelancedata || getState().profile.clientedata
    const url = profile.id
      ? `/sitemeup/${profile.tipo}update`
      : `/sitemeup${profile.tipo}save`
    const methodH = profile.id ? 'POST' : 'PUT'
    return sendRequest({
      url,
      methodH,
      bodyH: profile,
      manageData: e => !e.error
    })
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, { payload: { tipo, key, value } }) {
      // console.log('SETTT', state, tipo, key, value)
      state[`${tipo}data`][key] = value
    },
    setProfileStars(state, { payload: { tipo, id, totalStars } }) {
      const profile = state[`${tipo}data`]
      const selectedSkill = profile.skills.find(skill => skill.id_skill === id)
      if (selectedSkill) {
        selectedSkill.stars = totalStars
      } else {
        profile.skills.push({
          id_skill: id,
          stars: totalStars,
          questions: [],
          id_freelance: state.id
        })
      }
    },
    clearProfile(state) {
      state.freelancedata = {}
      state.clientedata = {}
      state.profile.freelancedata = {}
      state.profile.clientedata = {}
      console.log(state, 'STATO CLEAR PROFILE');
    }
  },
  extraReducers: builder => {
    builder
      .addCase(dumpProfile.pending, state => {
        state.status = 'loading'
      })
      .addCase(dumpProfile.fulfilled, (state, { payload }) => {
        // destructure to { payload }
        // if (tipoRef === 'freelance') state.freelancedata = action.payload.data
        // if (tipoRef === 'cliente') state.clientedata = action.payload.data
        state[`${payload.tipo}data`] = payload
        state.status = 'idle'
      })
      .addCase(dumpProfile.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
      .addCase(dumpSkills.pending, state => {
        state.status = 'loading'
      })
      .addCase(dumpSkills.fulfilled, (state, action) => {
        state.skills = action.payload.data
        state.status = 'idle'
      })
      .addCase(dumpSkills.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
      .addCase(dumpValutazioni.pending, state => {
        state.status = 'loading'
      })
      .addCase(dumpValutazioni.fulfilled, (state, action) => {
        state.freelancedata.valutazioni = action.payload.data
        state.status = 'idle'
      })
      .addCase(dumpValutazioni.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
      .addCase(saveProfile.pending, state => {
        state.status = 'loading'
      })
      .addCase(saveProfile.fulfilled, state => {
        // console.log('BBBBBB', action)
        state.status = 'idle'
      })
      .addCase(saveProfile.rejected, state => {
        // console.log('Error happened')
        state.status = 'idle'
      })
  }
})

export const { setProfile, setProfileStars, clearProfile } =
  profileSlice.actions
export const selectProfile = state =>
  state.profile.freelancedata || state.profile.clientedata

export default profileSlice.reducer
