import { styled } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination'

const SmuPagination = styled(props => <Pagination {...props} />)(() => ({
  '& .MuiPaginationItem-root': {
    background: '#EDF1F4',
    transition: 'all .5s'
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    background: '#013057',
    color: 'white'
  },
  '& .MuiPaginationItem-root:hover, .MuiPaginationItem-root.Mui-selected:hover ':
    {
      background: '#0565A2',
      color: 'white'
    }
}))

const SMUPagination = props => {
  const { pages, currPage, setPage } = props
  return (
    <SmuPagination
      count={pages}
      page={currPage}
      shape="rounded"
      showFirstButton
      showLastButton
      onChange={(event, value) => setPage(value)}
    />
  )
}
export default SMUPagination
