// import { useEffect, useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import ErrorIcon from '@mui/icons-material/Error'

import { styled } from '@mui/material/styles'
import './AccordionProject.css'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  // { theme }
  border: `none`,
  backgroundColor: 'white',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  },
  '& .Mui-expanded': {
    color: '#0686d8',
    background: 'white'
  },
  '&.Mui-disabled': {
    backgroundColor: 'gray'
  }
}))

const AccordionSummary = styled(props => (
  // const { setExpanded } = props
  <MuiAccordionSummary expandIcon={<ExpandIcon />} {...props} />
))(() => ({
  // { theme }
  backgroundColor: '#D2E9F7',
  padding: '0 2em 0 1em',
  margin: '.3em 0',
  borderRadius: '5px',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#013A63',
    background: 'white'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg) scaleX(1) scaleY(1)',
    color: '#0686d8',
    background: 'white'
  },
  '& .MuiAccordionSummary-content': {
    color: '#013A63',
    fontWeight: 'bold'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#0686d8',
    background: 'white'
  },
  '&.Mui-disabled': {
    opacity: 1
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  // { theme }
  padding: '1em 0 2em 0'
}))

export default function AccordionProject({
  title,
  id,
  // notification,
  children,
  expanded,
  status,
  setExpanded
  // mobile = 0,
}) {
  const IconAccordion = () => {
    let html
    switch (status) {
      case 1:
        html = <CheckCircleIcon fontSize="large" className="success" />
        break
      case 2:
        html = <ErrorIcon fontSize="large" className="error" />
        break
      default:
        html = <PendingIcon fontSize="large" className="pending" />
        break
    }
    return <div className="icon-status">{html}</div>
  }

  return (
    <Accordion expanded={expanded === id} onChange={() => setExpanded(id)}>
      <span className="d-block">
        <AccordionSummary>
          <IconAccordion />
          <span className="w-90 text-truncate fs-4">{title}</span>
        </AccordionSummary>
      </span>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

function ExpandIcon() {
  return <KeyboardArrowDownIcon fontSize="large" className="center" />
}
