import React, { useState, useEffect } from 'react'

import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import { TextField, FormControl } from '@mui/material'

import useHandleInput from 'hooks/useHandleInput'
import useHttp from 'hooks/use-http'

export default function FormRegistrazione({ login, close, setCompleted }) {
  // const { setRegistered, sendLogin, sendLogout, close } = props
  // const authContext = useContext(AuthContext)
  const [formError, setFormError] = useState(false)
  const [pwdMatchError, setPwdMatchError] = useState('')
  const {
    sendRequest: sendRegistrationRequest,
    isLoading: isRegistrationLoading
  } = useHttp()

  // Freelancer-> id_category 1
  // Cliente -> id_category 2

  const [user, setUser] = useState({
    name: '',
    nome: '',
    surname: '',
    cognome: '',
    email: '',
    password: '',
    id_category: 2,
    event_detail: {}
  })

  const isEmpty = object => {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in object) return false
    return true
  }

  const setUserAttr = e => {
    if (e.target.type !== 'checkbox') {
      if (e.target.name === 'nome') {
        setUser({ ...user, name: e.target.value, nome: e.target.value })
      } else if (e.target.name === 'cognome') {
        setUser({ ...user, surname: e.target.value, cognome: e.target.value })
      } else setUser({ ...user, [e.target.name]: e.target.value })
    }
    // console.log('USR', user)
  }

  /* eslint-disable prefer-const */
  let {
    value: nome,
    handleChange: setNome,
    valid: isNomeValid,
    error: nomeError
  } = useHandleInput({ initialValue: '', type: 'username' })

  let {
    value: cognome,
    handleChange: setCognome,
    valid: isCognomeValid,
    error: cognomeError
  } = useHandleInput({ initialValue: '', type: 'username' })

  let {
    value: email,
    handleChange: setEmail,
    valid: isEmailValid,
    error: emailError
  } = useHandleInput({ initialValue: '', type: 'email' })

  let {
    value: password,
    handleChange: setPassword,
    valid: isPasswordValid,
    error: passwordError
  } = useHandleInput({ initialValue: '', type: 'password' })

  const checkPassword = e => {
    if (e.target.value !== user.password) {
      setPwdMatchError('Le password non coincidono')
    } else setPwdMatchError(passwordError)
  }

  const canSubmit = () =>
    (
      [
        isNomeValid,
        isCognomeValid,
        isEmailValid,
        isPasswordValid,
        !pwdMatchError.length
      ] || []
    ).every(i => i)

  const register = () => {
    sendRegistrationRequest({
      url: '/api/register',
      methodH: 'POST',
      bodyH: user,
      manageData: e => {
        if (e.status === 'KO') {
          // console.log(e)
          setFormError(e.message)
        } else {
          // console.log(e)
          // setRegistered(true)
          setFormError(false)
        }
      }
    })
      .then(() => {
        login({
          email: user.email,
          password: user.password
        })
        setCompleted(true)
      })
      .catch(error => setFormError(error.message))
  }

  useEffect(() => {
    isNomeValid = true
    isCognomeValid = true
    isEmailValid = true
    isPasswordValid = true
  }, [])

  return (
    <div className="row">
      {formError ? (
        <div className="col-12 text-center">
          <p className="text-warning">
            Errore durante la registrazione:
            <br />
            <strong>{formError}</strong>
          </p>
        </div>
      ) : null}

      <div className="col-12 d-flex align-items-center justify-content-center mb-2">
        <span
          className={`transition-3 ${!user.id_category === 1 && 'text-white'} `}
        >
          Cliente
        </span>
        <Switch
          checked={Boolean(user.id_category === 1)}
          onClick={() =>
            user.id_category === 1
              ? setUser({ ...user, id_category: 2 })
              : setUser({ ...user, id_category: 1 })
          }
        />
        <span className={`transition-3 ${user.freelancer && 'text-white'} `}>
          Freelancer
        </span>
      </div>

      <div className="col-12 mb-3">
        <FormControl fullWidth>
          <TextField
            error={!!nome.length && !isNomeValid}
            required
            label="Nome"
            name="nome"
            onKeyUp={setNome}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
            helperText={nomeError}
          />
        </FormControl>
      </div>

      <div className="col-12 mb-3 mt-2">
        <FormControl fullWidth>
          <TextField
            error={!!cognome.length && !isCognomeValid}
            required
            label="Cognome"
            name="cognome"
            onKeyUp={setCognome}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
            helperText={cognomeError}
          />
        </FormControl>
      </div>

      <div className="col-12 mb-3 mt-2">
        <FormControl fullWidth>
          <TextField
            error={!!email.length && !isEmailValid}
            required
            label="Email"
            name="email"
            onKeyUp={setEmail}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
            helperText={emailError}
          />
        </FormControl>
      </div>

      <div className="col-12 mb-3 mt-2">
        <FormControl fullWidth>
          <TextField
            error={!!password.length && !isPasswordValid}
            required
            label="Password"
            name="password"
            type="password"
            onKeyUp={setPassword}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
            helperText={passwordError}
          />
        </FormControl>
      </div>

      <div className="col-12 mb-3 mt-2">
        <FormControl fullWidth>
          <TextField
            error={!!pwdMatchError.length}
            required
            label="Conferma password"
            name="confirmPassword"
            type="password"
            onChange={checkPassword}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
            helperText={pwdMatchError}
          />
        </FormControl>
      </div>

      <span className="lh-sm fs-7 text-white mt-3">
        <Checkbox
          className="p-0 m-0 me-2"
          checked={!isEmpty(user.event_detail)}
          onClick={() =>
            isEmpty(user.event_detail)
              ? setUser({
                  ...user,
                  event_detail: JSON.stringify({
                    consensoprivacy: 'utilizzo dati',
                    testoconsensoprivacy: 'aaaaa',
                    consensoutilizzo: 'utilizzo dati',
                    testoconsensoutilizzo: 'aaaaaaa'
                  })
                })
              : setUser({ ...user, event_detail: {} })
          }
        />
        Accetto il <u>contratto cliente</u> di site me up e la{' '}
        <u>privacy policy</u>
      </span>

      <div className="col-12 mb-3 text-center mt-4">
        <button
          type="button"
          disabled={
            !canSubmit() || isEmpty(user.event_detail) || isRegistrationLoading
          }
          className="btn-lg d-cell border-0 btn-block text-primary"
          onClick={register}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            isRegistrationLoading
              ? 'Accesso in corso...'
              : user.id_category === 1
              ? 'Inizia a guadagnare'
              : 'Crea il tuo sito'
          }
        </button>
      </div>
    </div>
  )
}
