import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Dialog } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import templateImg from 'assets/images/sitemeup/creaprogetto/template_view.png'

import P1 from 'assets/images/products/product-1-min.jpg'
import P2 from 'assets/images/products/product-2-min.jpg'
import P3 from 'assets/images/products/product-3-min.jpg'
import ImgPreview from './components/ImgPreview'

import CaratteristicheDesktop from './components/CaratteristicheDesktop'

import './PreviewTemplate.css'

const arrImgExample = [P1, P2, P3]

const PreviewTemplate = ({template, editable, handlePageSelection}) => {
  const [showOptions, setShowOptions] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [livePreviewLink, setLivePreviewLink] = useState(null)
  const [openLivePreview, setOpenLivePreview] = useState(false)
  const path = window.location.protocol.concat(
    '//',
    window.location.host,
    '/',
    'livePreview'
  )
  const openTab = (p) => {
    /* setLivePreviewLink(p)
    setOpenLivePreview(true) */
    window.open(p, '_blank', 'noopener,noreferrer')
  }
  const [selected, setSelected] = useState([]);


  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.25,
    slidesToScroll: 5,
    centerMode:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.25,
          slidesToScroll: 4,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleSelection = (pageID, selectionID) => {

    if (selected.find((el)=>el.id_page === pageID)){

      if (selected.find((el)=>el.id_page === pageID && el.id === selectionID)) {
        setSelected(selected.filter((el)=>el.id_page !== pageID))
      } else {
        setSelected(
          selected.map((el)=>{
            if (el.id_page === pageID) {
              return { ...el, id:selectionID}
            }
            return el
          })
        )
      }
    } else {
      setSelected([...selected, {id:selectionID, id_page:pageID}])
    }

    console.log(selected, 'PAGINE SELEZIONATE');
  }

  useEffect(()=>{ 
    if (handlePageSelection) {
      handlePageSelection(selected, 'PAGINE SELEZIONATE')
    }
  }, [selected])

  return (
    <>
    <Dialog fullscreen maxWidth="xl" open={livePreviewLink} onClose={()=>setLivePreviewLink(false)}>
      {livePreviewLink ? (<>
        <iframe title="Live preview" src={livePreviewLink} width="100%" height="100%" border="0" />
      </>) : null}
    </Dialog>
    <Dialog open={openDialog} onClose={()=>setOpenDialog(false)} maxWidth="xl">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img className="img-fluid" src={openDialog} alt="Preview" />
            </div>
          </div>
        </div>
    </Dialog>
      <div className="col-12 text-center text-primary fs-5 mb-2">
        Sfoglia le sezioni del template utilizzando le frecce laterali
      </div>
      <div className="position-relative">
        <div className="template-info">
          <img src={templateImg} alt="template" className="d-none d-lg-block" />
          <div className="btnGroup d-none d-lg-block">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => openTab(template?.linkdemo || path)}
            >
              Live Preview
            </button>
            <br />
            <button
              type="button"
              className={`btn ${showOptions ? 'btn-secondary' : 'btn-primary'}`}
              onClick={() => setShowOptions(state => !state)}
            >
              Caratteristiche <br /> tecniche
            </button>
          </div>

          <ImgPreview arrImg={template?.images || []} />
        </div>

        <div className="col-12 text-center pb-3 d-block d-lg-none">
          <button
            type="button"
            className="btn btn-primary mx-auto"
            onClick={() => openTab('')}
          >
            Live Preview
          </button>
        </div>
        <CaratteristicheDesktop template={template} show={showOptions} />
      </div>

      <div className="row">
        <div className="col-12">
          {template?.pages && template?.pages.length > 0 ? (<>
            {template.pages.map((el)=>(
              <div className="row">
                <div className="col-12 bg-secondary text-white text-center text-uppercase">{el?.title}</div>
                <div className="col-12 pe-0">
                  <div className="p-3 mb-4" style={{border:'1px solid #EDF1F4', borderBottomLeftRadius:'12px', borderBottomRightRadius:'12px'}}>
                    <Slider {...slickSettings}>
                      {el.images.map((img)=>(<div className="slide-element">
                        <button type="button" className={`btn templatePageSelection p-3 ${selected.find((ee)=>ee.id_page === img.id_page && img.id === ee.id)?.id ? 'active' : ''}`} 
                        onClick={()=>handleSelection( img.id_page, img.id)}>
                          <img src={img.imagepath} alt={img?.name} className="img-fluid" />
                        </button>
                        {selected.find((ee)=>ee.id_page === img.id_page && img.id === ee.id)?.id ? (<><button type="button" className="btn btn-primary d-block w-100" onClick={()=>setOpenDialog(img.imagepath)}><ZoomInIcon /> Espandi</button></>) : null }
                      </div>))} 
                    </Slider>
                  </div>
                </div>
              </div>
            ))}
          </>) : (<>
            <p>Preview delle pagine interne non disponibili</p>
          </>)}
        </div>
      </div>
    </>
  )
}

export default PreviewTemplate
