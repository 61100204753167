/* eslint-disable no-restricted-syntax, guard-for-in */
import { useEffect, useState, useContext } from 'react'

import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import { SMULoading } from 'components/SMU'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { AuthContext } from 'store/auth-context'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { dumpProgetti } from 'slices/progettiSlice'
import SMUPagination from 'components/SMU/Pagination'
import DrawerFilterBar from './sections/DrawerFilterBar'
import FilterBar from './sections/FilterBar'
import SearchBar from './sections/SearchBar'
import ProjectPanel from './sections/ProjectPanel'
import useHttp from '../../hooks/use-http'


// import listProjects from './listProjects.json'

export default function Progetti() {
  const projectForPage = 4
  const { authState } = useContext(AuthContext)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState(0)
  const [freelanceTypes, setFreelanceTypes] = useState([])

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceTypesLoading
  } = useHttp()
  const manageFreelanceTypes = data => {
    setFreelanceTypes(data?.data || [])
  }

  const loadFreelanceTypes = () => {
    sendFreelanceTypesRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: manageFreelanceTypes
    })
  }

  const [projFilter, setProjFilter] = useState({
    skills: [],
    min: 0,
    max: 0,
    choice: null,
    stato: null,
    keyword: null
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dumpProgetti())
    loadFreelanceTypes()
  }, [])

  const orderChange = e => setOrder(parseInt(e.target.value, 10))

  const handleKeyWord = e =>
    setProjFilter(oldFilter => ({ ...oldFilter, keyword: e.trim() }))

  const filterSkillList = useSelector(state =>
    state?.progetti?.entities?.flatMap(e => e.skill.map(s => s.name))?.filter((e, i, arr) => i === arr.lastIndexOf(e))
  )

  // console.log('FILTERLIST', filterSkillList)

  const minBudgetChange = e =>
    setProjFilter(oldFilter => ({
      ...oldFilter,
      min: parseInt(e.target.value, 10)
    }))

  const maxBudgetChange = e =>
    setProjFilter(oldFilter => ({
      ...oldFilter,
      max: parseInt(e.target.value, 10)
    }))

  const choiceChange = e =>
    setProjFilter(oldFilter => ({
      ...oldFilter,
      choice: parseInt(e.target.value, 10)
    }))

  const stateChange = e =>
    setProjFilter(oldFilter => ({
      ...oldFilter,
      stato: parseInt(e.target.value, 10)
    }))

  const skillsChange = e =>
    setProjFilter(oldFilter => {
      if (oldFilter.skills === undefined)
        return { ...oldFilter, skills: [e.target.value] }
      if (oldFilter.skills.includes(e.target.value)) {
        const arr = oldFilter.skills.filter(s => s !== e.target.value)
        return { ...oldFilter, skills: arr }
      }
      return { ...oldFilter, skills: [...oldFilter.skills, e.target.value] }
    })

  /* funzione ordinante -> funzione di uscita del selector di redux
    Oppure semplicemente si usa .reverse() */
  // function sortFn(a, b) {
  //   return Number(new Date(a.created_at)) - Number(new Date(b.created_at))
  // }

  /* funzione filtrante -> funzione di uscita del selector di redux */
  function filterFn(item, filterObj) {
    // console.log('FIL INT', item, projFilter)
    let result = true
    if (filterObj) {
      for (const key of Object.keys(filterObj)) {
        switch (key) {
          case 'keyword':
            if (filterObj.keyword) {
              let searchables = []
              Object.entries(item).forEach(([k, v]) => {
                if (/titolo|descrizione|localit./.test(k) && v)
                  searchables = [...searchables, v]
              })
              if (
                searchables.every(
                  term =>
                    !term.includes(filterObj.keyword) &&
                    !filterObj.keyword.includes(term)
                )
              )
                result = false
            }
            break
          case 'choice':
            if (filterObj.choice !== null) {
              if ((item.templatesceltodafreelance || 0) !== filterObj.choice)
                result = false
            }
            break
          case 'stato':
            if (filterObj.stato !== null) {
              if (item.stato !== filterObj.stato) result = false
            }
            break
          case 'max':
            if (filterObj.max !== 0) {
              if (item.budget !== null && item.budget > filterObj.max)
                result = false
            }
            break
          case 'min':
            if (filterObj.min !== 0) {
              if (item.budget !== null && item.budget < filterObj.min)
                result = false
            }
            break
          case 'skills':
            if (filterObj.skills.length) {
              const matchingSkills = item.skill
                .map(e => e.name)
                .filter(e => filterObj.skills.includes(e))
              result = matchingSkills.length > 0
            }
            break
          default:
            result = true
        }
      }
    }
    return result
  }

  /* implementazione del filtro: derived state di redux toolkit:
    2 funzioni in input -> 2 parametri della funzione in uscita,
    3 funzioni di input -> 3 parametri in uscita, etc. */
  const selectProjects = createSelector(
    [state => state?.progetti?.entities, () => order, () => projFilter],
    (items, doSort, filters) =>
      doSort
        ? [...items].reverse().filter(item => filterFn(item, filters))
        : items.filter(item => filterFn(item, filters))
  )

  const listProjects = useSelector(selectProjects)
  // console.log('BBBBB', listProjects)

  const pages = Math.ceil(listProjects.length / projectForPage)
  useEffect(() => {
    if (pages > 0 && page > pages) setPage(pages)
  }, [listProjects.length])

  return (
    <>
      <SMUNavbar />
      <SMULoading show={listProjects.length === 0} />
      <div className="d-flex flex-grow-1 bg-white py-4">
        <div className="col-11 h-100 mx-auto">
          <SearchBar handleKeyWord={handleKeyWord} />
          <div className="d-flex">
            <div className="d-block d-lg-none">
              <DrawerFilterBar
                projFilter={projFilter}
                filterSkillList={filterSkillList}
                maxBudgetChange={maxBudgetChange}
                minBudgetChange={minBudgetChange}
                stateChange={stateChange}
                choiceChange={choiceChange}
                skillsChange={skillsChange}
              />
            </div>
            <div className="col-3 py-3 d-lg-block d-none">
              <FilterBar
                projFilter={projFilter}
                filterSkillList={filterSkillList}
                maxBudgetChange={maxBudgetChange}
                minBudgetChange={minBudgetChange}
                stateChange={stateChange}
                choiceChange={choiceChange}
                skillsChange={skillsChange}
              />
            </div>
            <div className="col-12 col-lg-9 py-3">
              <div className="col-12 d-flex flex-wrap">
                <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <Select
                    value={order}
                    onChange={orderChange}
                    InputLabelProps={{ shrink: true }}
                    label="Ordinamento"
                    sx={{ height: '50px', width: '150px' }}
                  >
                    <MenuItem value={0}>Dal più recente</MenuItem>
                    <MenuItem value={1}>Dal meno recente</MenuItem>
                  </Select>
                  &nbsp;&nbsp;
                  <b className="fs-7">{listProjects.length} lavori trovati</b>
                </div>

                <div className="col-12 col-lg-7 mt-3 d-flex justify-content-center justify-content-lg-end">
                  <SMUPagination
                    pages={pages}
                    currPage={page}
                    setPage={setPage}
                  />
                </div>
              </div>
              <div className="col-12">
                {listProjects.map((item, index) => {
                  const curr = index + 1
                  const min = projectForPage * page - (projectForPage - 1)
                  const max = projectForPage * page
                  // const boolean = curr <= max && curr >= min

                  return (
                    curr <= max &&
                    curr >= min && (
                      <ProjectPanel
                        key={item.id}
                        item={item}
                        freelances={freelanceTypes}
                      />
                    )
                  )
                })}
              </div>

              <div className="col-12 d-flex justify-content-center justify-content-lg-end">
                <SMUPagination
                  pages={pages}
                  currPage={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
