import { useState } from 'react'

const PanelSelezione = ({ event, showSelectionPanel, disableSelection }) => {
  const { eventConfirm, setTemplate } = event
  const [confirm, setConfirm] = useState(false)

  return (
    <footer
      className={`col-12 d-flex flex-wrap justify-content-center align-items-center
            ${confirm ? 'd-none' : 'bg-lightcyan'}`}
    >
      <div className="col-12 col-md-7 text-center text-lg-end d-none d-md-block">
        <span
          className={`font-weight-bold fs-4 d-flex align-items-center justify-content-end gap-3
                ${confirm && 'd-none'}`}
        >
          {showSelectionPanel ? (<>
          Scegli questo template e vai allo step successivo
          </>) : null}
        </span>
      </div>

      {!confirm && (
        <div className="col-12 col-md-5 text-center py-3">
          {showSelectionPanel ? (<>
            <button
              type="button"
              disabled={disableSelection || false}
              className="btn btn-sm btn-primary m-0 mx-1 py-2 px-3"
              onClick={() => {
                setConfirm(true)
                eventConfirm()
              }}
            >
              Seleziona
            </button>
          </>) : null}
          <button
            type="button"
            className="btn btn-sm btn-default m-0 mx-1 py-2 px-3"
            onClick={() => setTemplate(null)}
          >
            Annulla
          </button>
        </div>
      )}
    </footer>
  )
}

export default PanelSelezione
