import LocationOnIcon from '@mui/icons-material/LocationOn'
import PC from 'assets/images/sitemeup/creaprogetto/riassunto-PC.svg'
import './Summary.css'

const Summary = () => (
  <div className="summary-project lato">
    <div>Riassunto progetto</div>
    <div className="first-panel">
      <div className="info">
        <desc className="text-truncate">SITO - My portal</desc>
        <p className="d-none d-md-flex">
          <LocationOnIcon fontSize="large" color="white" />
          <div>
            <h6>Località</h6>
            <desc className="text-truncate">KATHMANDU</desc>
          </div>
        </p>
      </div>
      <div className="template">
        <img src={PC} alt="" />
      </div>
    </div>
    <div className="second-panel">
      <li className="d-md-none">
        <desc>Località</desc>
        <h6>KATHMANDU</h6>
      </li>
      <li>
        <desc>Budget</desc>
        <h6>4500€</h6>
      </li>
      <li>
        <desc>Funzionalità</desc>
        <h6>2</h6>
      </li>
      <li>
        <desc>Piano</desc>
        <h6>soddisfatti o rimborsati</h6>
      </li>
      <li>
        <desc>Prodotti agg.</desc>
        <h6>2</h6>
      </li>
      <li>
        <desc>Template</desc>
        <h6>PORTO</h6>
      </li>
    </div>

    <div className="row list">
      <li>
        <desc>Località</desc>
        <h6>KATHMANDU</h6>
      </li>
      <li>
        <desc>Budget</desc>
        <h6>4500€</h6>
      </li>
      <li>
        <desc>Funzionalità</desc>
        <h6>Ecommerce, Multilingua</h6>
      </li>
      <li>
        <desc>Piano</desc>
        <h6>soddisfatti o rimborsati</h6>
      </li>
      <li>
        <desc>Prodotti agg.</desc>
        <h6>Grafica ad-hoc, Seo di primo livello</h6>
      </li>
      <li>
        <desc>Template</desc>
        <h6>PORTO</h6>
      </li>
    </div>
  </div>
)
export default Summary
