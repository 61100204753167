import React from 'react'

// Image
import cfWeb5 from 'assets/images/sitemeup/cfWeb5.png'
import logo from 'assets/images/sitemeup/sitemeup_marchio.svg'

// Coworking page sections
import Strenghts from 'pagesitemeup/Landing/sections/Strenghts'
import Collabs from './Collabs'

export default function CFServer() {
  return (
    <>
      <Collabs />
      <Strenghts />

      <section className="py-6 py-lg-4 bg-secondary">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-11 col-sm-10 col-md-6 col-lg-6 mx-lg-auto mb-6 mb-md-0 d-flex justify-content-center">
              <img src={cfWeb5} alt="sitemeup" className="w-80" />
            </div>
            <div className="col-md-6 col-lg-6 mx-lg-0 mb-4 d-flex justify-content-center align-items-center">
              <div className="text-center text-lg-start zilla">
                <img src={logo} className="w-15 mb-4" alt="Site Me Up" />
                <p className="text-lg text-secondary text-white fw-bold">
                  Vuoi crescere di livello? <br />
                  <span className="fw-normal">
                    Visita la nostra sezione di corsi online dedicati ai
                    freelancer che vogliono sempre migliorarsi!
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-matisse py-5">
        <div className="container p-0 mx-auto text-center">
          <div className="col-7 text-white text-center fs-3 zilla mx-auto pb-2">
            <span className="fw-bold">SELEZIONA IL TUO PIANO</span> <br />
            <span>
              mandaci subito una tua presentazione e iniziamo a collaborare!
            </span>
          </div>
          <div className="row m-0 py-6">
            <div className="col-md-3 mb-3 d-none d-md-block">
              <div className="pricing-container">
                <div className="pricing-row pricing-title pricing-label" />
                <div className="pricing-row pricing-label">Costo al mese</div>
                <div className="pricing-row pricing-label">
                  Proposte in contemporanea
                </div>
                <div className="pricing-row pricing-label">
                  Proposte illimitate
                </div>
                <div className="pricing-row pricing-label">
                  Proposte in evidenza
                </div>
                <div className="pricing-row pricing-label">
                  Tutor a disposizione
                </div>
                <div className="pricing-row pricing-label">Punti in regalo</div>
                <div className="pricing-row pricing-label">
                  Posticipa pagamento fee
                </div>
                <div className="pricing-row pricing-label">Commissioni</div>
              </div>
            </div>

            <div className="col-md-3 mb-3 ">
              <button type="button" className="pricing-container">
                <div className="pricing-row pricing-title">Free</div>
                <div className="pricing-row">Gratis</div>
                <div className="pricing-row">1</div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">Da livello KING</div>
                <div className="pricing-row">6%</div>
              </button>
            </div>

            <div className="col-md-3  mb-3">
              <button type="button" className="pricing-container">
                <div className="pricing-row pricing-title standard">
                  Standard
                </div>
                <div className="pricing-row">30€ / mese</div>
                <div className="pricing-row">5</div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">Al costo di €x al mese</div>
                <div className="pricing-row">
                  <span className="text-danger">X</span>
                </div>
                <div className="pricing-row">Da livello Prince</div>
                <div className="pricing-row">5%</div>
              </button>
            </div>

            <div className="col-md-3">
              <button type="button" className="pricing-container">
                <div className="pricing-row pricing-title premium">Premium</div>
                <div className="pricing-row">50€ / mese</div>
                <div className="pricing-row">
                  <span className="text-success">Illimitate</span>
                </div>
                <div className="pricing-row">
                  <span className="fa fa-check text-success" />
                </div>
                <div className="pricing-row">
                  <span className="fa fa-check text-success" />
                </div>
                <div className="pricing-row">
                  <span className="fa fa-check text-success" />
                </div>
                <div className="pricing-row">
                  <span className="text-success">40</span>
                </div>
                <div className="pricing-row">Da livello Wizard</div>
                <div className="pricing-row">4%</div>
              </button>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-lg mt-0 text-info border-0 bg-white text-uppercase fw-bold letter-spacing-1 px-5"
          >
            inizia a guadagnare
          </button>
        </div>
      </section>
    </>
  )
}
