import React from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import Input from '@mui/material/Input'
import { Select, MenuItem } from '@mui/material'
import '../components/common.css'

import NuovoProgetto from './images/nuovo-progetto_2x.png'
import AggiornaEsistente from './images/aggiorna_esistente_2x.png'

const TypeOfProject = props => {
  const { project, projectTypes, sendProject, sendUpdate, handleNext } = props
  const { titolo, descrizione, tiposito, projectType, localita, templatesceltodafreelance } = project


  const updateProjectType = e => {
    sendProject({
      ...project,
      projectType: projectTypes.find(el => el.id === e.target.value) || {},
      profiles:
        projectTypes
          .find(el => el.id === e.target.value)
          ?.tipifreelance.map(ej => ({
            id_tipofreelance: ej.id_tipofreelance,
            obbligatorio: ej.obbligatorio,
            attivita: '',
            budget: 0,
            prezzonuovo: ej.prezzonuovo || 0,
            prezzorifacimento: ej.prezzorifacimento || 0,
            answers: []
          }))
          .filter(el => el.obbligatorio === 1) || []
    })
  }

  const updateValues = e => {
    sendUpdate(e.target.name, e.target.value)
  }

  return (
    <div className="col-11 mx-auto">
      <div className="row">
        <div className="col-12">
          <p className="fs-5 text-center">
            <h2>Che tipo di progetto vuoi realizzare?</h2>
          </p>
          
        </div>
      </div>
      
      <div className="row mt-3">
        <div className="col-md-9 col-12 mx-auto">
          <div className="d-flex justify-content-center align-items-center selection-button-container">
           
              <button
                type="button"
                className={`flex-fill selection-button${tiposito === 1 ? ' selected' : ''}`}
                onClick={() => sendUpdate('tiposito', 1)}
              >
                <div className="selection-button--over-image">
                    <img src={NuovoProgetto} className="img-fluid" alt="Nuovo progetto" />
                </div>
                <div className="selection-button--text">Nuovo Progetto</div>
               
              </button>
              <button
                type="button"
                className={`flex-fill selection-button${tiposito === 2 ? ' selected' : ''}`}
                onClick={() => sendUpdate('tiposito', 2)}
                style={{position:'relative'}}
                disabled
              >
                <div className="selection-button--over-image">
                    <img src={AggiornaEsistente} className="img-fluid" alt="Aggiorna esistente" />
                </div>
                <div className="selection-button--text">Aggiorna esistente</div>
                <div style={{position:'absolute', bottom:-30, left:0, width:'100%', textAlign:'center'}}><strong>In arrivo</strong></div>
              </button>
          </div>
        </div>
      </div>
      

        <div className="row mt-3">
            <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-lg btn-primary mt-4 letter-spacing-1 swpier-next"
            alt=""
            disabled={!tiposito}
            onClick={() => handleNext()}
          >
            Avanti
          </button>
          </div>
        </div>
    </div>
  )
}

export default TypeOfProject
