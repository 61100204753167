import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  koStatusAlert: false,
  errorMessage: ''
}

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    toggleKOStatus(state, action) {
      /* eslint-disable no-param-reassign */
      state.koStatusAlert = !state.koStatusAlert
      state.errorMessage = action.payload
    },
    disableKOStatus(state, action) {
      /* eslint-disable no-param-reassign */
      state.koStatusAlert = false
      state.errorMessage = action.payload
    }
  }
})

export const { toggleKOStatus, disableKOStatus } = eventSlice.actions

export default eventSlice.reducer
