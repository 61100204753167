// import SkillRating from 'components/SMU/SkillRating'
import StarRating from 'components/SMU/StarRating/StarRating'

import EXavatar from 'assets/images/ivana-square.jpg'
import EXsilverBadge from 'assets/images/sitemeup/kingoftheweb-badge.svg'
import EXsilverBadgeSmall from 'assets/images/sitemeup/kingoftheweb-badge-sm.svg'

const EXbio =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.'

export default function Info({user}) {
  return (
    <div className="bg-aliceblue py-3">
      <div className="position-relative mb-3">
        <section className="avatar-img-sm mx-auto">
          <img src={user?.pathfoto || EXavatar} alt={`${user?.nome} ${user?.cognome}`} />
        </section>
      </div>

      <h1 className="profileHeading">@ {user?.nome} {user?.cognome}</h1>

      <section className="badge">
        <img src={EXsilverBadge} alt="" />
      </section>

      <StarRating />

      <div className="bar" />

      <section className="bio">
        <div>Bio</div>
        <div>{user?.presentazionebreve}</div>
      </section>

      {/* <SkillRating type="detail" /> */}
    </div>
  )
}
