import { useState, useCallback, useContext } from 'react'
import { AuthContext } from 'store/auth-context'

import { toggleKOStatus, disableKOStatus } from 'slices/eventSlice'
// import { setLoading, removeLoading } from 'slices/loadingSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearProfile } from 'slices/profileSlice'
import Cookies from 'js-cookie'

const useHttp = () => {
  const authContext = useContext(AuthContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [apiURL] = useState(`${process.env.REACT_APP_API_ENDPOINT}`)

  const sendRequest = useCallback(
    async ({ url, methodH, headersH, bodyH, manageData }) => {
      setIsLoading(true)
      // dispatch(setLoading())
      setError(null)

      try {
        if (!Cookies.get('XSRF-TOKEN') || methodH?.toUpperCase() === 'POST' || methodH?.toUpperCase() === 'PUT') {
          const csrfRequest = await fetch(`${apiURL}/csrf-cookie`, {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              accept: 'application/json'
            },
            credentials: 'include'
          })
        }

        const mainHeaderSettings = !localStorage.getItem('token')
          ? {
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || ''
            }
          : {
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        const response = await fetch(apiURL + url, {
          credentials: 'include',
          method: methodH || 'GET',
          headers: headersH
            ? {
                ...mainHeaderSettings,
                ...headersH
              }
            : {
                ...mainHeaderSettings,
                'content-type': 'application/json',
                accept: 'application/json'
              },

          body: JSON.stringify(bodyH) || null
        })

        const data = await response.json()
        if (data.message === 'Unauthenticated.') navigate('/come-funziona')

        if (data.status === 'KO') {
          dispatch(toggleKOStatus(data.message))
          throw new Error('Error thrown from status ko')
        } else {
          dispatch(disableKOStatus())
        }

        manageData(data)
        setIsLoading(false)
        
      } catch (err) {
        console.log(err, 'ERROR')

        setIsLoading(false)
        // localStorage.removeItem('token')
        // localStorage.removeItem('userdata')
        // dispatch(clearProfile)
        // authContext.sendLogout()
        // navigate('/landing-web-designer')
        // console.log(err || "Something's Wrong")
        setError(err)
      }
    }
  )

  return {
    isLoading,
    error,
    sendRequest
  }
}
export default useHttp
