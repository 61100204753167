// import BgPercheSitemeup from 'assets/images/sitemeup/bg_perche_sitemeup.svg'
import Freccetta from 'assets/images/sitemeup/freccetta.png'
import IconePerche1 from 'assets/images/sitemeup/1icone_perche.png'
import IconePerche2 from 'assets/images/sitemeup/2icone_perche.png'
import IconePerche3 from 'assets/images/sitemeup/3icone_perche.png'
import IconePerche4 from 'assets/images/sitemeup/4icone_perche.png'
import IconePerche5 from 'assets/images/sitemeup/5icone_perche.png'

const arrayInfo = [
  {
    img: IconePerche1,
    title: 'Libertà di scelta',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    img: IconePerche2,
    title: 'Qualità assicurata',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    img: IconePerche3,
    title: 'Assistenza diretta',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    img: IconePerche4,
    title: 'Contratti sicuri',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  },
  {
    img: IconePerche5,
    title: 'Soddisfatti o rimborsati',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.'
  }
]

function SiteMeUpInfo() {
  return (
    <>
      <section className="pt-4 pb-7 bg-tertiary position-relative bg-img-info">
        <div className="container-fluid z-index-2 position-relative">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <h2 className="text-white">Perché Site Me UP</h2>
              <div className="immagine-apertura">
                <img className="mt-n2 mb-4" src={Freccetta} alt="sitemeup" />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {arrayInfo.map(e => (
              <div className="col-lg-2 col-md-6 pb-5 pb-lg-0 d-flex">
                <div className="bg-ultra-light-azure rounded-0 skew-y p-2 mt-3 ">
                  <div className="no-skew-y">
                    <div className="p-3 text-left">
                      <div className="icon icon-shape icon-md bg-ultra-light-azure rounded-circle mx-auto position-absolute start-50 translate-middle-x p-2 mt-n5">
                        <img className="w-100" src={e.img} alt="sitemeup" />
                      </div>
                      <h4 className="mt-2">{e.title}</h4>
                      <p className="lh-sm zilla">{e.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="buttons text-center position-relative z-index-3 mt-lg-6">
          <button
            type="button"
            className="btn btn-white"
            style={{ boxShadow: 'none' }}
          >
            Crea il tuo sito
          </button>
        </div>
      </section>
    </>
  )
}

export default SiteMeUpInfo
