/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { setLoading, removeLoading } from 'slices/loadingSlice'

import imgNoProject from 'assets/images/sitemeup/empty-portfolio.png'
import { FaFileUpload } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { dumpProfile, saveProfile } from 'slices/profileSlice'
import { toggleKOStatus } from 'slices/eventSlice'
import LoadProject from './sections/LoadProject/LoadProject'
import './Portfolio.css'
import 'pagesitemeup/Profilo/components/LeftProfileNavigation.css'
import 'pagesitemeup/Profilo/Profilo.css'

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const Profilo = ({ profileData }) => {
  const dispatch = useDispatch()

  const saveData = async newData => {
    dispatch(setLoading())
    try {
      await dispatch(saveProfile(newData))
      await dispatch(dumpProfile(newData))
    } catch {
      dispatch(toggleKOStatus())
    } finally {
      dispatch(removeLoading())
    }
  }

  const pushImageInArray = async payload => {
    const confData = {
      ...profileData,
      images: [...profileData.images, payload]
    }

    await saveData(confData)
  }

  const deleteImageFromIndex = async index => {
    const confData = {
      ...profileData,
      images: [...profileData.images.filter(fil => fil.id !== index)]
    }

    await saveData(confData)
  }

  const onDrop = useCallback(
    async acceptedFiles => {
      const newImagesPromises = acceptedFiles.map(async file => {
        const image = await getBase64(file)
        return {
          name: file.name,
          estensione: file.name.split('.').pop(),
          image
        }
      })

      Promise.all(newImagesPromises).then(results => {
        const dataToSave = {
          ...profileData,
          images: [...profileData.images, ...results]
        }
        saveData(dataToSave)
      })
    },
    [profileData]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div className="col-lg-12 position-relative z-index-1 bg-white px-0">
        <div className="p-4">
          <div className="text-info text-uppercase px-md-4">
            <h6 className="pl-md-4">I miei lavori</h6>
          </div>

          <div
            {...getRootProps()}
            className="row bg-light-azure px-0 py-3 border-radius-xl mx-auto"
          >
            <input
              onClick={e => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
              }}
              {...getInputProps()}
            />

            {profileData?.images && profileData?.images.length > 0 ? (
              <>
                <div
                  className={`col-12 drag-drop-files ${
                    isDragActive && 'active'
                  }`}
                >
                  <p>
                    <IconContext.Provider
                      value={{
                        style: { verticalAlign: 'middle', fontSize: '40px' }
                      }}
                    >
                      <FaFileUpload />
                    </IconContext.Provider>
                    <br />
                    {isDragActive ? (
                      <>Drop the files here ...</>
                    ) : (
                      <>Drag n drop some files here, or click to select files</>
                    )}
                  </p>
                </div>

                <div className="row mx-auto p-0 justify-content-center justify-content-sm-start">
                  <LoadProject
                    name="test1"
                    setArrayImg={pushImageInArray}
                    deleteImage={deleteImageFromIndex}
                    arrayImg={profileData?.images}
                    saveData={saveData}
                  />
                </div>
              </>
            ) : (
              <div className="row mx-auto justify-content-around">
                <div className="col-12 m-auto text-center p-lg-5 zilla">
                  <img src={imgNoProject} alt="No project" />
                  <h1 className="my-4 col-lg-9 col-md-11 mx-md-auto text-primary">
                    Non hai ancora caricato il tuo primo lavoro
                  </h1>
                  <LoadProject
                    name="firstProject"
                    className="position-relative max-width-250 col-sm-6 col-md-4 m-0 mb-4 max-width-250"
                    setArrayImg={pushImageInArray}
                    // setArryImg={setArryImg}
                    deleteImage={deleteImageFromIndex}
                    arrayImg={profileData?.images}
                    saveData={saveData}
                  />
                  <h3 className="text-secondary text-capitalize">
                    Accresci La Visibilità Del Tuo Profilo
                  </h3>
                  <h5 className="col-lg-9 col-md-11 mx-md-auto mt-2 text-secondary font-weight-light text-capitalize">
                    Il cliente ha bisogno di conoscerti prima di poterti
                    scegliere per il prossimo progetto. Che stai aspettando?
                  </h5>
                  <h3 className="text-decoration-underline text-capitalize">
                    Mostra a tutti quanto vali!
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Profilo
