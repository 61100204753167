import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dumpUser, selectIsEmailVerified } from 'slices/userSlice'

import Stepper, {
  StepperContainer
} from 'pagesitemeup/CreaProgetto/components/Stepper'

import MKBox from 'components/MKBox'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import { SMULoading } from 'components/SMU'

// Sections
import NameProject from './sections/NameProject'
import TeamSelection from './sections/TeamSelection'
import TeamInstructions from './sections/TeamInstructions'
import DesignerChoice from './sections/DesignerChoice'
import ProjectPublished from './sections/ProjectPublished'
import HeaderTemplate from './sections/Header'
import ProjectSummary from './sections/ProjectSummary/ProjectSummary'


import useHttp from '../../hooks/use-http'

import ListTemplates from './sections/ListTemplates/index'
import './CreaProgetto.css'
import TypeOfProject from './sections/TypeOfProject'

import ProjectManager from './sections/images/project-manager-art.png';
import ArtDirection from './sections/ArtDirection'
import ProgettoCaricato from './sections/images/progetto_caricato.png'
import Esaudito from './sections/images/esaudito.png'
import ProjectStandBy from './sections/ProjectStandBy'
import ScegliTemplate from './sections/ScegliTemplate/ScegliTemplate'

function CreaProgetto() {
  const [userID, setUserID] = useState()
  const dispatch = useDispatch()
  const isEmailVerified = useSelector(selectIsEmailVerified) // state => state.user?.data?.email_verified_at

  const [dynamicHeaderData, setDynamicHeaderData] = useState({})
  const [templates, setTemplates] = useState([])


  const {
    sendRequest: sendProjectTypesRequest,
    isLoading: isProjectTypesLoading
  } = useHttp()
  const {
    sendRequest: sendProductsRequest,
    isLoading: isProductsLoading
  } = useHttp()

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceTypesLoading
  } = useHttp()
  const {
    sendRequest: sendPublishProjectRequest,
    isLoading: isPublishing,
    error: publishError
  } = useHttp()

  const {
    sendRequest: sendUpdateProjectRequest,
    isLoading: isUpdating,
    error: updateError
  } = useHttp()

  const {
    sendRequest: sendTemplatesListRequest,
    isLoading: isTemplatesLoading
  } = useHttp()
  

  const [project, setProject] = useState({
    id_cliente: userID,

    titolo: '',
    localita: '',
    tiposito: 1,
    durata:4,
    projectType: {},

    profiles: [],

    descrizione: '',
    templates: [],
    prodotti: [],
    addedProducts: [],

    tipocontratto: 1,
    templatesceltodafreelance: 1,
    pubblicato:1,

    siti: [],
    images:[],
    chiavefirmacliente: '',
    chiavefirmafreelance: '',

    budget: 0,
    skills: []
  })

  const [freelanceTypes, setFreelanceTypes] = useState([])
  const [projectTypes, setProjectTypes] = useState([])
  const [products, setProducts] = useState([])
  const [published, setPublished] = useState(0);

  const theStepTextData = [
    { title: 'Sitemeup Intro', description: 'Benvenuto nella creazione del tuo nuovo progetto' },
    { title: 'Sitemeup Intro', description: 'Benvenuto nella creazione del tuo nuovo progetto' },
    { 
      title: (<>Il <span className="gold">project manager</span> te lo regaliamo noi</>), 
      description: `Un progetto funziona solo quando c'è un bravo timoniere e per questo ti regaliamo il nostro 
                    miglior timoniere per poter navigare sicuro.`,
      icon:ProjectManager,
      iconTitle:"Project Manager"
    },
    { title: 'Istruisci il Team', description: 'Lorem ipsum dolor sit amet'},
    { title: 'La tua squadra è pronta!', description: 'Verifica che tutte le risposte che hai inserito precedentemente corrispondano ai parametri che vuoi dare al tuo progetto come base dei preventivi che inizierai a ricevere tra poco.', includeTeam:true},
    { title: 'Sitemeup Intro', description: 'Benvenuto nella creazione del tuo nuovo progetto' },
    { 
      title: 'Il tuo progetto è stato pubblicato!', 
      description:(<>Adesso non ti resta altro da fare che <span className="gold">attendere
        i primi preventivi</span> dei nostri professionisti</>),
      icon:ProgettoCaricato
    }
  ]

  const [stepTextData, setStepTextData] = useState(theStepTextData)
  

  const [activeStep, setActiveStep] = useState(0)
  const handleNext = (bud) => { 
    if (bud) { setProject({...project,  budget:bud})}
    setActiveStep(state => state + 1); 
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const handleBack = () => { 
    setActiveStep(state => state - 1)
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const goToStep = i => { 
    setActiveStep(i)
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const manageTemplates = (data) => {
    setTemplates(data?.data || [])
  }
  const loadTemplates = (id) => {
    sendTemplatesListRequest({
      url: `/sitemeup/templates${id ? `?id_tipoprogetto=${id}&withdeleted=0` : ''}`,
      manageData: manageTemplates
    })
  }

  const [params, setParams] = useState({
    template: null
  })

  let lastSetTimeout = null
  useEffect(() => {
    /* eslint-disable camelcase */
    async function getUserAndRepeat() {
      // console.log('I AM CHECKING USER')
      const {
        payload: { id, email_verified_at }
      } = await dispatch(dumpUser())
      // console.log('RESSSSSSS', email_verified_at)
      if (!email_verified_at)
        lastSetTimeout = setTimeout(getUserAndRepeat, 5 * 1000)

      setUserID(id)
    }
    getUserAndRepeat()
    return () => clearTimeout(lastSetTimeout)
  }, [isEmailVerified])

  const nameProject = () => {
    // console.log('Aggiunto nome progetto')
    handleNext()
  }

  const designerChoice = () => {
    // console.log('A fantasia del designer')
    handleNext()
  }

  const selectTemplate = e => {
    console.log('Template selezionato', e)

    setParams({ ...params, template: e })
    handleNext()
  }

  const updateProjectData = (k, v) => {
    setProject({ ...project, [k]: v })
  }

  const manageFreelanceTypes = data => {
    setFreelanceTypes(data?.data || [])
  }
  const manageProjectTypes = data => {
    setProjectTypes(data?.data || [])
  }

  useEffect(()=>{
    if (project.templatesceltodafreelance) {
      setStepTextData([...theStepTextData])
    } else {
      setStepTextData([...theStepTextData, 
        {
          title:'Il template è la veste del tuo progetto',
          description:(<>
            Magari hai bisogno di più tempo per scegliere quale sarà la base da cui far partire i nostri professionisti a realizzare il tuo progetto.
          <br /><span className="gold">Hai 24 ore a disposizione!</span> Se vuoi tornare più tardi salva la bozza del tuo progetto.
          </>)

        }, 
        {
          title: 'Il tuo progetto è stato pubblicato!', 
      description:(<>Adesso non ti resta altro da fare che <span className="gold">attendere
        i primi preventivi</span> dei nostri professionisti</>),
      icon:ProgettoCaricato
        }
      ])
    }
  }, [project.templatesceltodafreelance])

  const managePublishedProject = data => {
    if (publishError) {
      console.log(publishError)
    } else {
      console.log(data)
      if (data.status === 'OK'){
        setProject({ ...project, id: data?.data?.id, id_cliente: data?.data?.id_cliente })
        setPublished(project?.templatesceltodafreelance === 1 ? 1 : 2)
        loadTemplates(project?.projectType?.id)
      }
    }
  }

  useEffect(()=>{
    if (published === 1){
      setActiveStep(6);
    } else if (published === 2) {
      setActiveStep(7);
    }
  }, [published])

  

  const manageProducts = (data) => {
    setProducts(data?.data || [])
  }
  

  const loadFreelanceTypes = () => {
    sendFreelanceTypesRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: manageFreelanceTypes
    })
  }
  const loadProjectTypes = () => {
    sendProjectTypesRequest({
      url: '/sitemeup/tipologieprogetto',
      manageData: manageProjectTypes
    })
  }
  
  const loadProducts = () => {
    sendProductsRequest({
      url: '/sitemeup/prodotti',
      manageData: manageProducts
    })
  }

  const handlePublish = () => {
    sendPublishProjectRequest({
      url: '/sitemeup/ordersave',
      methodH: 'post',
      bodyH: { ...project, _method: 'put', budget:0, profiles:project.profiles.map((el)=>({...el, budget:0})), id_tipoprogetto:project?.projectType?.id || 0 },
      manageData: managePublishedProject
    })
  }

  const manageUpdateProjectForProducts = (data) => {
    if (updateError) {
      console.log(updateError)
    }
  }

  const manageUpdateProject = (data) => {
    if (!updateError) {
      setDynamicHeaderData({
        title:'Il tuo primo desiderio è stato esaudito!',
        description:'Scegli un altro servizio per rendere il tuo progetto ancora più magico.',
        icon:Esaudito,
        iconTitle:''
      
      })
      setActiveStep(8);
      
    } else {
      console.log(updateError)
    }
  }


  const updateProjectTemplate = (e) => {
    if (project?.id) {
      sendUpdateProjectRequest({
        url: '/sitemeup/orderupdate',
        methodH: 'post',
        bodyH: { ...project, templates:[{id_template:e}], pubblicato:1, profiles:[], siti:[], skills:[], id_tipoprogetto:project?.projectType?.id || 0  },
        manageData: manageUpdateProject
      })
    }
  }

  const addProductsToProject = (e) => {
    if (project?.prodotti) {
      sendUpdateProjectRequest({
        url: '/sitemeup/orderupdate',
        methodH: 'post',
        bodyH: { ...project, templates:[], profiles:[], siti:[], skills:[], id_tipoprogetto:project?.projectType?.id || 0  },
        manageData: manageUpdateProjectForProducts
      })
    }
  }

  useEffect(() => {
    loadFreelanceTypes()
    loadProjectTypes()
    loadTemplates()
    loadProducts()

    return () => null
  }, [])

  useEffect(() => {
    console.log('Progetto: ', project)
  }, [project])
  return (
    <>
      <div
        className="d-flex flex-column bg-white"
        style={{ minHeight: '100vh' }}
      >
        <SMULoading
          show={
            isFreelanceTypesLoading || isProjectTypesLoading || isPublishing || isUpdating || isProductsLoading
          }
        />

        { /* <SMUModal
          show={!isEmailVerified}
          message="Prego conferma la tua mail per continuare"
        /> */ }
        
        <SMUNavbar />
        <HeaderTemplate steps={stepTextData.length} dynamicHeaderData={dynamicHeaderData} profiles={project.profiles} freelanceTypes={freelanceTypes} stepTextData={stepTextData} activeStep={activeStep} sendStep={(e)=>{ if (e<activeStep && !project?.id) {
          setActiveStep(e)
        } }}  />
          
        {isFreelanceTypesLoading || isProjectTypesLoading ? (
          <>
          <div className="container">
            <div className="row">
            <div className="col-11 col-md-10 col-lg-10 mx-auto position-relative z-index-3 p-0 flex-grow-1">
              <div className="lato progetto-container">
                {isFreelanceTypesLoading ? (
                  <>
                    <p>Caricamento figure professionali in corso...</p>
                  </>
                ) : null}
                {isProjectTypesLoading ? (
                  <>
                    <p>Caricamento tipologie progetto in corso...</p>
                  </>
                ) : null}
              </div>
              </div>
            </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 col-md-11 col-lg-10 mx-auto position-relative z-index-3 p-0 d-flex flex-grow-1">
              <div className="pt-5 lato progetto-container">
                
                <StepperContainer activeStep={activeStep}>

                  <Stepper activeStep={activeStep} index={0}>
                  {activeStep === 0 ? (
                    <TypeOfProject
                      project={project}
                      projectTypes={projectTypes}
                      sendProject={p => setProject(p)}
                      sendUpdate={updateProjectData}
                      handleNext={nameProject}
                    />) : null }
                  </Stepper>

                  <Stepper activeStep={activeStep} index={1}>
                  {activeStep === 1 ? (
                    <NameProject
                      project={project}
                      projectTypes={projectTypes}
                      sendProject={p => setProject(p)}
                      sendUpdate={updateProjectData}
                      handleNext={nameProject} 
                      handleBack={handleBack}
                    />) : null }
                  </Stepper>

                  <Stepper activeStep={activeStep} index={2}>
                    {activeStep === 2 ? (
                      <TeamSelection
                        project={project}
                        freelances={freelanceTypes}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        sendUpdate={updateProjectData}
                      />
                    ) : null}
                  </Stepper>

                  <Stepper activeStep={activeStep} index={3}>
                    {activeStep === 3 ? (
                      <TeamInstructions
                        project={project}
                        freelances={freelanceTypes}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        sendUpdate={updateProjectData}
                        sendCurrentHeaderData={setDynamicHeaderData}
                      />
                    ) : null}
                  </Stepper>

                  <Stepper activeStep={activeStep} index={4}>
                    {activeStep === 4 ? (
                      <ProjectSummary
                        project={project}
                        freelances={freelanceTypes}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        publishProject={handlePublish}
                      />
                    ) : null}
                  </Stepper>

                  <Stepper activeStep={activeStep} index={5}>
                  {activeStep === 5 ? (
                    <ArtDirection
                      project={project}
                      sendUpdate={updateProjectData}
                      handleNext={nameProject}
                      handleBack={handleBack}
                      publishProject={handlePublish}
                    />) : null }
                  </Stepper>

                  <Stepper activeStep={activeStep} index={6}>
                    
                    {activeStep === 6 ? (<>

                      {published === 1 ? (<>
                        <ProjectPublished
                          project={project}
                          sendUpdate={updateProjectData}
                          handleNext={handleNext}
                          products={products}
                          addProducts={addProductsToProject}
                        />
                      </>) : (<>
                        <ProjectStandBy
                          project={project}
                          sendUpdate={updateProjectData}
                          handleNext={handleNext}
                          sendCurrentHeaderData={setDynamicHeaderData}
                          products={products}
                          addProducts={addProductsToProject}
                        />
                      </>)}
                    </>) : null}
                  </Stepper>

                

                  <Stepper activeStep={activeStep} index={7}>
                    {activeStep === 7 ? (
                      <ScegliTemplate 
                        project={project}
                        templates={templates}
                        sendProjectTemplate={updateProjectTemplate}
                        handleNext={handleNext} />
                    ) : null}
                  </Stepper>

                  <Stepper activeStep={activeStep} index={8}>
                    {activeStep === 8 ? (
                      <>
                        <ProjectPublished
                          project={project}
                          sendUpdate={updateProjectData}
                          handleNext={handleNext}
                          products={products}
                          addProducts={addProductsToProject}
                        />
                      </>
                    ) : null}
                  </Stepper>
                </StepperContainer>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CreaProgetto
