/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import { IntlProviderWrapper } from 'store/intl-context'
import { AuthContextProvider } from 'store/auth-context'
import { Provider } from 'react-redux'
// TODO change createStore with configureStore from toolkit
// import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// import reducers from "./reducers";
// import eventsReducer from "reducers/eventsReducer";
import eventsReducer from 'slices/eventSlice'
import profileReducer from 'slices/profileSlice'
import loadingReducer from 'slices/loadingSlice'
import userReducer from 'slices/userSlice'
import progettiReducer from 'slices/progettiSlice'
import tagsReducer from 'slices/tagsSlice'
import templatesReducer from 'slices/templatesSlice'

// const store = createStore(reducers);
const store = configureStore({
  reducer: {
    events: eventsReducer,
    progetti: progettiReducer,
    profile: profileReducer,
    loading: loadingReducer,
    user: userReducer,
    tags: tagsReducer,
    templates: templatesReducer
  }
})

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthContextProvider>
        <IntlProviderWrapper>
          <App />
        </IntlProviderWrapper>
      </AuthContextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
