import React, { useState } from 'react'

import { TextField, FormControl } from '@mui/material'

export default function FormAccesso({ login, isLoginLoading, error, recupera}) {

  const [recuperaPassword, setRecuperaPassword] = useState(false)
  const [user, setUser] = useState({
    email: '',
    password: ''
  })

  const setUserAttr = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  return (
    <div className="row">
      {error ? (
        <div className="col-12 text-center">
          <p className="text-warning">
            Errore nell&lsquo;effettuare l&lsquo;accesso:
            <br />
            <strong>{error}</strong>
          </p>
        </div>
      ) : null}

{!recuperaPassword ? (<>
      <div className="col-12 mb-3">
        <FormControl fullWidth>
          <TextField
            required
            className="outlined-required"
            label="Email"
            // defaultValue={user.email}
            name="email"
            type="email"
            value={user.email}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
          />
        </FormControl>
      </div>

      <div className="col-12 mt-2">
        <FormControl fullWidth>
          <TextField
            required
            className="outlined-required"
            label="Password"
            // defaultValue={user.password}
            name="password"
            type="password"
            value={user.password}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
          />
        </FormControl>
      </div>
      <div className="col-12 mt-2 text-end">
        <p className="text-white"><button type="button" className="btn p-0 m-0 text-white" style={{fontSize:'0.75em'}} onClick={()=>setRecuperaPassword(true)}>Hai dimenticato la password?</button></p>
      </div>

      <div className="col-12 text-center mt-2">
        <button
          type="button"
          disabled={isLoginLoading}
          className="btn-lg d-cell border-0 btn-block text-primary"
          onClick={() => login(user)}
        >
          {isLoginLoading ? 'Accesso in corso...' : 'Accedi'}
        </button>
      </div>
      </>) : (<>
      
      <div className="col-12 mb-3">
        <p className="text-white" style={{fontSize:'0.85em'}}>Se hai dimenticato la password, inserisci qui la tua email. Riceverai tutte le indicazioni per reimpostare la password via posta elettronica.</p>
      </div>
        <div className="col-12 mb-3">
        <FormControl fullWidth>
          <TextField
            required
            className="outlined-required"
            label="Email"
            // defaultValue={user.email}
            name="email"
            type="email"
            value={user.email}
            onChange={setUserAttr}
            variant="outlined"
            style={{ background: '#fff', borderRadius: '8px' }}
          />
        </FormControl>
      </div>

      <div className="col-12 text-center mt-2">
        <button
          type="button"
          disabled={isLoginLoading}
          className="btn-lg d-cell border-0 btn-block text-primary"
          onClick={() => recupera(user)}
        >
          {isLoginLoading ? 'Recupero password in corso...' : 'Recupera password'}
        </button>
      </div>
      
      </>)}

    </div>
  )
}
