import { useEffect, useState } from 'react'
import './index.css'

export function StepperContainer({ children, activeStep }) {
  return <div className={`stepper-container elem${activeStep}`}>{children}</div>
}

export default function Stepper(props) {
  const { children, index, close, activeStep } = props
  const [classes, setClasses] = useState('')

  useEffect(() => {
    if (activeStep === index) setClasses('active')
    else setClasses('')
  }, [activeStep]) 

  return (
    <>
      <div
        className={`col-12 smu-vstepper scrollbar-typeA ${classes}`}
        id={`step-${index}`}
      >
        {!close && (
          <>{children && <div className="vstep-body row">{children}</div>}</>
        )}
      </div>
    </>
  )
}

export const ButtonStep = ({ handleNext }) => {
  const [btnHide, setBtnHide] = useState(false)

  return (
    <button
      type="button"
      className={`btn btn-primary me-0 ms-auto float-end ${
        btnHide && 'd-none'
      }`}
      onClick={() => {
        handleNext()
        setBtnHide(true)
      }}
    >
      Continua
    </button>
  )
}
