import React from 'react'

import gold from 'assets/images/sitemeup/gold.svg'
import silver from 'assets/images/sitemeup/silver.svg'
import bronze from 'assets/images/sitemeup/bronze.svg'
import metal from 'assets/images/sitemeup/metal.svg'

import comeottenerepunti from 'assets/images/sitemeup/comeottenerepunti.svg'
import scontoking from 'assets/images/sitemeup/sconto-king.svg'
import scontodigital from 'assets/images/sitemeup/sconto-digitalsuperhero.svg'

// TBD
// import { FormattedMessage } from 'react-intl'

import './Strenghts.css'

function Strenghts() {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 text-center m-auto mb-6 zilla">
              <h2>
                il nostro vero punto di forza sei tu!
                {/* <FormattedMessage id="il nostro vero punto di forza sei tu!" /> */}
              </h2>
              <p className="subtitle">
                Se ci dimostri di essere un vero professionista scalerai le
                nostre vette. Ogni anno riconosciamo premi e contratti a tempo
                indeterminato ai migliori freelancer del portale.
                {/* <FormattedMessage id="Se ci dimostri di essere un vero professionista scalerai le nostre vette. Ogni anno riconosciamo premi e contratti a tempo indeterminato ai migliori freelancer del portale." /> */}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h3>
                SISTEMA <b>LIVELLI & PUNTEGGIO</b>
              </h3>
              <div className="row">
                <div className="col-6 col-md-3">
                  <img src={gold} alt="400 punti" className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={silver} alt="300 punti" className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={bronze} alt="200 punti" className="img-fluid" />
                </div>
                <div className="col-6 col-md-3">
                  <img src={metal} alt="100 punti" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 p-0">
              <div className="py-2 bg-tertiary text-white text-center">
                <p className="text-white h3">Come ottenere punti</p>
              </div>
              <ul className="howto col-12 col-lg-7 m-auto my-5 font-weight-bold text-start lato">
                <li>
                  +&nbsp;20 PUNTI &nbsp; &gt; &nbsp; Completamento del profilo
                </li>
                <li>+&nbsp;10 PUNTI &nbsp; &gt; &nbsp; Proposta accettata</li>
                <li>+&nbsp;10 PUNTI &nbsp; &gt; &nbsp; Contratto firmato</li>
                <li>+&nbsp;1 &nbsp;PUNTO &nbsp; &gt; &nbsp; Proposta</li>
              </ul>
              <div className="py-2 bg-tertiary text-white text-center">
                <p className="text-white h3">Sconti sulle commissioni</p>
              </div>
              <div className="row mt-4 mt-lg-0 justify-content-between mb-5 mb-lg-0">
                <div className="row col-12 col-lg-6 mt-3 mt-lg-auto">
                  <div className="col-6 col-lg-12">
                    <img
                      src={scontoking}
                      alt="Sconto King"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-6 col-lg-12 p-0 text-start text-lg-center text-gray zilla">
                    Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                    eiusmod tempor incidunt ut labore et dolore magna aliqua.
                  </div>
                </div>
                <div className="row col-12 col-lg-6 mt-3 mt-lg-auto">
                  <div className="col-6 col-lg-12">
                    <img
                      src={scontodigital}
                      alt="Sconto Digital SuperHero"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-6 col-lg-12 p-0 text-start text-lg-center text-gold zilla">
                    Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                    eiusmod tempor incidunt ut labore et dolore magna aliqua.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-4">
              <img
                src={comeottenerepunti}
                alt="come ottenere punti"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Strenghts
