import React, { useEffect, useRef, useState } from 'react'

import AddProducts from 'pagesitemeup/CreaProgetto/sections/AddProducts'
import Summary from 'pagesitemeup/CreaProgetto/sections/Summary/Summary'

import MKBox from 'components/MKBox'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'

import Checkbox from '@mui/material/Checkbox'
import Stepper, {
  StepperContainer
} from 'pagesitemeup/CreaProgetto/components/Stepper'

import PanelSelezione from '../PanelSelezione'

import HeaderTemplate from '../../sections/Header'
import PreviewTemplate from '../../sections/PreviewTemplate'
import CheckAction from '../../sections/CheckAction'
import ClientOffer from '../../sections/ClientOffer'
import PaymentPlan from '../../sections/PaymentPlan'
import './TemplateView.css'

const TemplateBox = ({ template, setTemplate, selectPages, showSelectionPanel, showHeader, showNavbar, handleSelection }) => {
  const [activeStep, setActiveStep] = useState(0)

  const compRef = useRef(null)
  const scrollTo = () => {
    compRef.current.children.footer_comp.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })
  }
  const handleNext = () => {
    scrollTo()
    setTimeout(() => setActiveStep(step => step + 1), 900)
  }
  const handleBack = () => {
    scrollTo()
    setTimeout(() => setActiveStep(step => step - 1), 900)
  }

  const [objTemplate, setObjTemplate] = useState({
    ...template,
    confirm: false
  })
  const eventConfirm = () => {
    setObjTemplate({ ...objTemplate, confirm: true })
    if (handleSelection) {
      handleSelection(objTemplate)
    }
  }

  const handlePageSelection = (pgs) => {
    console.log(pgs)
    setObjTemplate({ ...objTemplate, pages:pgs, confirm: true })
  }
  useEffect(() => {
    if (objTemplate === null) setTemplate(null)
    console.log(selectPages, "Select pages")
    console.log(objTemplate)
  }, [objTemplate])

  const BtnStep = ({ back, className }) => (
    <button
      type="button"
      className={`btn btn-primary 
            ${
              !className &&
              (back ? 'ms-0 me-auto float-start' : 'me-0 ms-auto float-end')
            }
            ${className}`}
      onClick={back ? handleBack : handleNext}
    >
      {back ? 'Indietro' : 'Avanti'}
    </button>
  )

  return (
    <div className="template-view lato" ref={compRef}>
      <div name="footer_comp" />
      {showNavbar ? (<>
      <MKBox variant="contained" bgColor="secondary" shadow="sm">
        <SMUNavbar />
      </MKBox>
      </>) : null}

    {showHeader ? (<>
      <HeaderTemplate
        activeStep={5}
        template={{ objTemplate, setObjTemplate }}
      />
    </>) : null}

      <PanelSelezione disableSelection={selectPages && objTemplate?.pages?.length !== template?.pages?.length} showSelectionPanel={showSelectionPanel} event={{ eventConfirm, setTemplate}} />

      <StepperContainer activeStep={activeStep}>
        <Stepper activeStep={activeStep} index={0}>
          <div className="py-5">
            <PreviewTemplate handlePageSelection={handlePageSelection} template={template} handleNext={() => handleNext()} />
          </div>
        </Stepper>

        <Stepper activeStep={activeStep} index={1}>
          <CheckAction />
          <div className="col-sm-11 col-lg-10 mx-auto mt-n4">
            <BtnStep />
            <BtnStep back />
          </div>
        </Stepper>

        <Stepper activeStep={activeStep} index={2}>
          <div className="pt-2">
            <ClientOffer />
            <div className="col-11 col-lg-10 mx-auto">
              <BtnStep />
              <BtnStep back />
            </div>
          </div>
        </Stepper>

        <Stepper activeStep={activeStep} index={3}>
          <PaymentPlan handleNext={handleNext} />
          <div className="col-12 bg-white">
            <div className="col-11 col-lg-10 mx-auto pb-4">
              <BtnStep />
              <BtnStep back />
            </div>
          </div>
        </Stepper>

        <Stepper activeStep={activeStep} index={4}>
          <AddProducts />
          <div className="col-10 mx-auto mt-4">
            <BtnStep />
            <BtnStep back />
          </div>
        </Stepper>

        <Stepper activeStep={activeStep} index={5}>
          <Summary handleNext={handleNext} />
          <div className="col-12">
            <div className="resoconto pb-4">
              <div className="checkbox-label mb-4">
                <Checkbox />
                <span>
                  Accetto <b> Termini & Condizioni</b> e la{' '}
                  <b> Privacy Policy </b> Copyright © 2022 SiteMeUp
                </span>
              </div>

              <div className="footer">
                <div className="col-10 col-md-6 px-0 px-md-2 mx-auto">
                  <BtnStep back className="mx-auto mx-md-0 w-100" />
                </div>
                <div className="col-10 col-md-6 px-0 px-md-2 mx-auto">
                  <button
                    className="btn btn-primary w-100"
                    alt=""
                    type="button"
                  >
                    Pubblica il tuo progetto
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Stepper>
      </StepperContainer>
    </div>
  )
}

export default TemplateBox
