export default function Products({ list = [] }) {
  return (
    <>
      <h5 className="text-secondary">Prodotti aggiuntivi</h5>
      <table>
        <tr>
          <th>Nome</th>
          <th>Descrizione</th>
          <th className="text-center">Prezzo</th>
        </tr>

        {list.map(obj => (
          <tr>
            <td className="product ps-2">{obj.name}</td>
            <td className="description">
              <div className="">{obj.description}</div>
            </td>
            <td className="cost-product">€&nbsp;{obj.price}</td>
          </tr>
        ))}
      </table>
    </>
  )
}
