/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from 'store/auth-context'
// import { useNavigate } from 'react-router-dom'
import { setLoading } from 'slices/loadingSlice'
import { dumpProfile, selectProfile } from 'slices/profileSlice'
import { dumpUser, selectIsEmailVerified } from 'slices/userSlice'
import useHttp from 'hooks/use-http'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUFooter from 'commoncomponets/Footer/SMUFooter'
import footerRoutes from 'footer.routes'
import SMUModal from 'commoncomponets/Modal/SMUModal'
import Notifiche from 'pagesitemeup/Notifiche'
import ProfileBody from './components/ProfileBody'
import Sidebar from './components/Sidebar'
import Drawer from './components/RightDrawer'
import Wallet from '../Wallet/Wallet'
import Portfolio from '../Portfolio/Portfolio'

import ClientProgetti from './Client/ClientProgetti'
import ClientProfilo from './Client/ClientProfilo'

import 'pagesitemeup/Profilo/components/LeftProfileNavigation.css'
import 'pagesitemeup/Profilo/Profilo.css'




const ProgettiPage = () => {
  const [boolWallet, setBoolWallet] = useState(false)
  const [boolPortfolio, setBoolPortfolio] = useState(false)
  const { authState } = useContext(AuthContext)
  const [clientPanel, setClientPanel] = useState(0)
  const [selectedPage, setSelectedPage] = useState('profile')
  const [tabPage, setTabPage] = useState(0)

  const {
    sendRequest: sendStripeConnection,
    isLoading: isLoadingStripeConnection,
    error: stripeConnectionError
  } = useHttp()

  const searchParams = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    async function getProfile(data) {
      dispatch(setLoading())
      // console.log('APERTURAAAAAA')

      const { tipo } = data
      if (tipo === 'cliente') setClientPanel(1)
      // console.log('AUTH', data)
      await dispatch(dumpProfile(data))
      // dispatch(removeLoading())
    }
    getProfile(authState)
  }, [])

  const profileData = useSelector(selectProfile)
  // console.log('ueueueue', profileData)

  const isEmailVerified = useSelector(selectIsEmailVerified) // state => state.user?.data?.email_verified_at
  // console.log('ISVVVVVV', isEmailVerified)

 /* let lastSetTimeout = null
  useEffect(() => {
    async function getUserAndRepeat() {
      // console.log('I AM CHECKING USER')
      const {
        payload: { email_verified_at }
      } = await dispatch(dumpUser())
      // console.log('RESSSSSSS', email_verified_at)
      if (!email_verified_at)
        lastSetTimeout = setTimeout(getUserAndRepeat, 5 * 1000)
    }
    getUserAndRepeat()
    return () => clearTimeout(lastSetTimeout)
  }, [isEmailVerified]) */

  // const isEmailVerified = useSelector(selectIsEmailVerified)
  // state => state.user?.data?.email_verified_at
  // console.log('ISVVVVVV', isEmailVerified)

  const setPageTabs = (event, value) => {
    setTabPage(value)
    if (value === 0) setSelectedPage('profile')
    else if (value === 1 && boolPortfolio) setSelectedPage('portfolio')
    else if (value === 2 && boolWallet) setSelectedPage('wallet')
    else setSelectedPage('profile')
  }

  const getMetaValue = key => {
    const meta = profileData?.metas?.find(m => m.key === key)
    return meta?.value || null
  }

  const isClient = Boolean(authState.tipo === 'cliente')

  const manageStripeUpdate = (data) => {
    console.log(data)
  }

  useEffect(() => {
    setBoolPortfolio(
      Boolean(
        !isClient &&
          profileData?.telefono &&
          getMetaValue('citta') &&
          getMetaValue('tipo_professionista') &&
          getMetaValue('ragionesociale') &&
          getMetaValue('piva') &&
          getMetaValue('univoco')
      )
    )
    setBoolWallet(Boolean(!isClient && profileData?.images.length > 3))

    const query = new URLSearchParams(window.location.search);

    console.log(profileData, 'DATI PROFILO');
    if (profileData?.id && profileData?.tipo === 'freelance' && query.get('code')) {
      sendStripeConnection({
          url: '/sitemeup/setstrypeid',
          methodH: 'post',
          bodyH: { code:query.get('code'), id_freelance:profileData.id },
          manageData: manageStripeUpdate
        })
      
    }

    /* if (query.get('error') || query.get('error_description')){
      setSelectedPage('wallet')
    } */

  }, [profileData])

  // const navigate = useNavigate()

  // useEffect(() => {
  //   if (isEmailVerified) return () => clearTimeout(lastSetTimeout)
  // }, [isEmailVerified])

  // const [valutazioni, setValutazioni] = useState({})

  // useEffect(() => {
  //   if (profileData?.tipo === 'freelance') {
  //     setClientPanel(0)
  //     console.log('JJJJJ', valutazioni)
  //     setValutazioni(profileData.valutazioni)
  //   } else setClientPanel(1)
  // }, [])

  // profile, wallet, portfolio

  // useEffect(() => {
  //   dispatch(setLoading)
  //   getData()
  // }, [])

  return (
    <>
      {profileData?.id && (
        <>
          <SMUNavbar />
          { /* <SMUModal
            show={!isEmailVerified}
            message="Prego conferma la tua mail per continuare"
            /> */ }

          {clientPanel ? (
            <ClientProgetti profileData={profileData} />
          ) : (
          <>ciao</>
          )}
          <SMUFooter content={footerRoutes} />
        </>
      )}
    </>
  )
}

export default ProgettiPage
