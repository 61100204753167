import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectProfile, setProfile } from 'slices/profileSlice'
import useHttp from '../../hooks/use-http'
import Payments from './sections/Payments'


const Wallet = (props) => {
  // eslint-disable-next-line
  const profileData = useSelector(selectProfile)
  console.log(profileData)
  const { sendRequest: saveProfile, isLoading: isSavingProfile } = useHttp()
  // const { sendRequest: getFullData, isLoading: isDataLoading } = useHttp()
  // const { sendRequest: getValutazioni, isLoading: isValutazioniLoading } = useHttp()
  const transazioni = false
  const dispatch = useDispatch()
  const configurationData = useSelector(state => state.profileReducer)

  const setLoading = value => {
    const payload = { type: value ? 'setLoading' : 'removeLoading' }
    dispatch(payload)
  }

  // eslint-disable-next-line
  const [user, setUser] = useState(profileData)
  const [saved, setSaved] = useState(true)

  const datiUtenteHandler = (key, value) => {
    const { tipo } = profileData
    dispatch(setProfile({ tipo, key, value }))
    setSaved(false)
  }

  const saveData = () => {
    setLoading(true)
    if (profileData.id) {
      saveProfile({
        url: '/sitemeup/freelanceupdate',
        methodH: 'POST',
        bodyH: profileData,
        manageData: () => { setSaved(true) }
      })
    } else {
      saveProfile({
        url: '/sitemeup/freelancesave',
        methodH: 'PUT',
        bodyH: profileData,
        manageData: () => { setSaved(true) }
      })
    }
  }


  return (
    <>
      <div className="col-lg-12 bg-white d-flex flex-wrap align-content-between">
        <div className="w-100 p-lg-5">
          <Payments
            freelanceData={user}
            sendUpdate={datiUtenteHandler}
          />

          <div className="row mt-4">
            <div className="col-12">
              <div className="w-100 text-center text-lg-start pb-4 pt-1 pb-lg-0">
                <b>ARCHIVIO TRANSAZIONI</b>
              </div>
              <div className="container border-lg-radius-xl w-100 m-auto p-0 bg-cyan">
                <div className="card-body p-1 pl-3">
                  <table className="table">
                    <colgroup>
                      <col span="1" style={{ width: '15%' }} />
                      <col span="1" style={{ width: '20%' }} />
                      <col span="1" style={{ width: '40%' }} />
                      <col span="1" style={{ width: '25%' }} />
                    </colgroup>
                    <thead className="text-primary">
                      <tr>
                        <th colSpan={3} className="px-2 pb-2 border-0">
                          ENTRATE
                        </th>
                        <th
                          colSpan={1}
                          className="text-center pb-2 bg-lightcyan border-radius-top-end-lg"
                        >
                          PAGAMENTI
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      {!transazioni ? (
                        <>
                          <td colSpan={4}>
                            <div className="text-center font-weight-bold p-2 p-lg-3 text-dark">
                              Non ci sono ancora transazioni
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>Cod.</td>
                          <td>Dati Pagamento</td>
                          <td>Causale</td>
                          <td className="text-end px-2">Importo</td>
                        </>
                      )}
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 bg-tertiary p-3 text-center text-lg-end">
          <button
            className="btn btn-lg btn-white mb-0"
            type="button"
            onClick={saveData}
          >
            {!saved ? (
              'Salva'
            ) : (
              <>
                <i
                  className="fa fas fa-solid fa-check"
                  style={{ color: 'green' }}
                />{' '}
                Profilo aggiornato
              </>
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default Wallet
