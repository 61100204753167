import { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import { Dialog } from '@mui/material'
import GridTemplate from 'pagesitemeup/Templates/components/GridTemplate'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import './DettagliFooter.css'

export default function DettagliFooter({ freelances, project, makeOffer, canChooseTemplate }) {
  const { budget, offers, profiles, canoffer, canoffermsg, templatesceltodafreelance, templates } = project
  
  const authContext = useContext(AuthContext)
  const freelanceID = authContext.authState?.freelancedata?.id
  const freelanceIDTipo = authContext.authState?.freelancedata?.id_tipologia;
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
  const [offerData, setOfferData] = useState({
    giorni: 0,
    descrizione: '',
    offerta: 0
  })
  const [disabled, setDisabled] = useState(true)

  const [answers, setAnswers] = useState([])

  const [chosenTemplate, setChosenTemplate] = useState(null)
  const [templateDialog, setTemplateDialog] = useState(false)


  useEffect(() => {
    if (offerData.giorni > 0 && offerData.descrizione && offerData.offerta > 0 && !answers.find((ans)=>!ans.resp))
      setDisabled(false)
    else setDisabled(true)

  }, [offerData, answers])

  useEffect(()=>{
    if (freelanceID) { 
      const answ = [];
      Object.keys(profiles?.find(ej => ej.id_tipofreelance === freelanceIDTipo)?.answers || {}).map((el)=>
        profiles.find(ej => ej.id_tipofreelance === freelanceIDTipo)?.answers[el].map((ej)=>{ 
          answ.push({...ej, resp:false})
          return ej
        })
      )
      setAnswers(answ)
    }
  }, [freelanceIDTipo])

  const updateOfferWithTemplate = tmp => {
    setChosenTemplate(tmp)
    setOfferData({
      ...offerData, 
      "templates":[{id_template:tmp.id}],
      "templateimages":tmp.pages.map((el)=>({id_page:el.id_page, id_image:el.id}))
    })
  }

  const sendOffer = () => {
    makeOffer(offerData)
  }

  return (
   <>
   <Dialog 
      open={templateDialog}
      onClose={()=>setTemplateDialog(false)}
      maxWidth="xl"
      fullWidth
   >
    <GridTemplate templateSelected={!templatesceltodafreelance ? templates[0] : null} showSelectionPanel selectPages handleSelection={(tmp)=>{ updateOfferWithTemplate(tmp); setTemplateDialog(false) }} />

   </Dialog>
        {!offers.find(el => el.id_freelance === freelanceIDTipo) && canoffer ? (
          <>
            <div className="dettagli-footer">
              <div className="container">
                <div className="col-12 header fs-3">
                  &Egrave; il progetto giusto per te?
                </div>
              </div>
            </div>
            <div className="background-grey py-3" style={{background:'#cae0ef'}}>
              <div className="container">

            <div className="col-12 header fs-3 text-center">
              <h3 style={{textTransform:'none'}}>Fai la tua offerta come <span className="text-gold">{freelances.find((el)=>el.id === freelanceIDTipo)?.name}</span></h3>
            </div>

         
            <section className="col-11 d-flex flex-wrap mx-auto text-secondary mt-3 lato">

          {freelanceID && profiles.find((el)=>el.id_tipofreelance === freelanceIDTipo) ? (<>
              

              <div className="row align-items-end justify-content-center mt-4">
                

                  <div className="col-6 col-md-6 d-flex flex-wrap mt-3 mt-md-0">
                    <span className="w-100 fs-6 ps-1 text-uppercase">Budget:</span>
                    <label
                      data-default="€"
                      htmlFor="budget-offer"
                      className="m-0 w-100"
                    >
                      <input
                        id="budget-offer"
                        type="text"
                        name="budget"
                        value={offerData.offerta || ''}
                        onChange={e =>
                          setOfferData({ ...offerData, offerta: e.target.value })
                        }
                        placeholder={budget + 50}
                        className="smu-form-control bg-white text-center p-1"
                      />
                    </label>
                  </div>

                  <div className="col-6 col-md-6 d-flex flex-wrap">
                    <span className="w-100 fs-6 ps-1 text-uppercase">
                      Tempi di realizzazione (giorni):
                    </span>
                    <input
                      type="number"
                      name="days"
                      value={offerData.giorni || ''}
                      onChange={e =>
                        setOfferData({ ...offerData, giorni: e.target.value })
                      }
                      className="smu-form-control bg-white p-1 w-100"
                    />
                  </div>

                  <div className="col-12 d-flex flex-wrap align-items-center text-center text-md-start mt-3">
                <span className="col-12 fs-6 ps-2 text-uppercase">
                  Descrizione:
                </span>
                <textarea
                  rows={2}
                  name="desc"
                  value={offerData.descrizione}
                  onChange={e =>
                    setOfferData({ ...offerData, descrizione: e.target.value })
                  }
                  className="smu-form-control bg-white p-1 w-100 mx-auto"
                />
              </div>

                <div className="mt-3 pt-3 col-10 offset-1">
                  <h3>Riepilogo delle richieste per il progetto</h3>
                <span className="w-100 fs-6 ps-1 pb-3">Prima di poter inviare l&lsquo;offerta controlla che tutte le richieste siano state recepite.
                <br />&Egrave; necessario spuntare per presa visione:</span>
                {Object.keys(profiles.find(ej => ej.id_tipofreelance === freelanceIDTipo)?.answers).map((el)=>
                   (<>
                    <p>{el}</p>
                    {profiles.find(ej => ej.id_tipofreelance === freelanceIDTipo)?.answers[el].map((ej)=>{ 
                      console.log(ej);

                      return (<>
                    <p><input type="checkbox" checked={answers.find((as)=>as.id === ej.id)?.resp || false} onClick={(ev)=>{
                      console.log(answers);

                      setAnswers(answers.map((as)=>{
                        if (as.id === ej.id)
                          return {...as, resp:ev.target.checked}

                        return {...as};
                      }))

                    }} 
                    /> <strong className="pe-2">{ej?.text}</strong> {ej?.answer?.answer}</p>
                    </>) }  ) 
                  } 
                  </>)

                )}

                </div>
                
                {chosenTemplate ? (<>
                <div className="col-10 offset-1 mt-5">
                  <h3>Template selezionato</h3>
                  <p><img src={chosenTemplate?.immaginepath || chosenTemplate?.immagine} alt="Chosen template" className="img-fluid" /></p>
                </div>
                </>) : null}

                <div className="col-12 d-flex justify-content-end mt-5 mt-md-4 text-right">
                  {canChooseTemplate ? (<>
                    <button
                      type="button"
                      onClick={()=>setTemplateDialog(true)}
                      className="btn btn-primary m-0 me-3"
                    >
                      {chosenTemplate ? 'Cambia ' : 'Scegli '} template
                    </button>
                  </>) : null}
                  <button
                    type="button"
                    disabled={disabled || (canChooseTemplate && !chosenTemplate)}
                    onClick={sendOffer}
                    className="btn m-0"
                    style={{background:'#FFB703', color:'#FFF'}}
                  >
                    Fai la tua offerta
                  </button>
                </div>

                 
              </div>
              </>) : (<>
            <div className="dettagli-footer">
              <div className="container">
                <div className="col-12 d-flex flex-wrap align-items-center text-center text-md-start mt-3">
                  <span className="col-12 fs-6 ps-2 text-center">
                    {isLoggedIn ? (<>
                      Non puoi effettuare un&lsquo;offerta con questo profilo.
                    </>) : (<>
                      Esegui il login per effettuare un&lsquo;offerta
                    </>)}
                  </span>
                </div>
                </div></div>
             </>)}
            </section>
          </div>
        </div>
   
          </>
        ) : (
          <>
          <div className="dettagli-footer">
              <div className="container">
                <div className="col-12 header fs-3">
                {canoffer ? (<>Hai già effettuato un&lsquo;offerta per questo progetto</>) : (<>
                  {(<><span dangerouslySetInnerHTML={{__html:canoffermsg}} /></>) || 'Non puoi effettuare un&lsquo;offerta per questo progetto'}
                </>)}
              
              </div>
            </div>
          </div>
          </>
        )}
    </>
  )
}
