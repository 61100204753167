import { useState } from "react"

export default function Stars({stars, onChange}) {

    const [currentStars, setCurrentStars] = useState(stars)

    return (
        <div>
            {Array.from({length:currentStars}).map((e, i)=>(<button type="button" className="btn p-0 m-0" onMouseLeave={()=>setCurrentStars(stars)} onMouseEnter={()=>setCurrentStars(i+1)} onClick={()=>onChange(i+1)}><span className="fa fs-4 fa-star text-warning me-1" /><span className="d-none">{i+1}</span></button>))}
            {Array.from({length:5 - currentStars}).map((e, i)=>(<button type="button" className="btn p-0 m-0" onMouseLeave={()=>setCurrentStars(stars)} onMouseEnter={()=>setCurrentStars(i+parseInt(currentStars, 10)+1)} onClick={()=>onChange(i+1+parseInt(currentStars, 10))}><span className="fa fa-star-o fs-4 me-1" /><span className="d-none">{i+1+parseInt(currentStars, 10)}</span></button>))}
        </div>
    )
  }
  