import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading, removeLoading } from 'slices/loadingSlice'
import { setProfile, saveProfile } from 'slices/profileSlice'
import useHttp from 'hooks/use-http'
// @mui material components
import Switch from '@mui/material/Switch'
import Create from '@mui/icons-material/Create'
// import MailIcon from '@mui/icons-material/Mail'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'

import curriculum from 'assets/images/sitemeup/curriculum-svgrepo-com.svg'
import curriculumok from 'assets/images/sitemeup/curriculum-svgrepo-com-ok.svg'
// import footerRoutes from 'footer.routes'

import Plugin from '../sections/Plugin'
import Freelance from '../sections/Freelance'


const ProfileBody = ({ profileData }) => {
  // const profileData = useSelector((state) => state.profileReducer);
  const dispatch = useDispatch()
  // console.log('PBODY', profileData)
  const existsCVFile = !!profileData.documents?.length
  const [cvMessage, setCvMessage] = useState(existsCVFile)
  const isClient = Boolean(profileData.tipo === 'cliente')
  const [saved, setSaved] = useState(true)
  const [updateBiography, setUpdateBiography] = useState(false)
  const [checked, setChecked] = useState(false)

  const [freelanceTypes, setFreelanceTypes] = useState([])
  const [errors, setErrors] = useState({})
  const [skills, setSkills] = useState([])

  const {
    sendRequest: sendFreelanceSkillsRequest,
    isLoading: isLoadingFreelanceSkills,
    error: skillsError
  } = useHttp()


  const manageBiography = () => setUpdateBiography(!updateBiography)

  const toggleSwitch = () => {
    const value = !checked
    setChecked(value)
    dispatch(setProfile({ tipo: profileData.tipo, key: 'rivalsainps', value }))
  }
  const manageSkillsResponse = (dt) => {
    setSkills(dt.data)
  }

  const {
    sendRequest: sendFreelanceTypesRequest,
    isLoading: isFreelanceLoading,
    error: freelanceError
  } = useHttp()

  const manageFreelanceTypes = (e) => {
    setFreelanceTypes(e.data)
  }


  useEffect(() => {
    dispatch(removeLoading())
    console.log(profileData)
    
    

  }, [profileData])

  useEffect(()=>{
    sendFreelanceSkillsRequest({
      url: `/sitemeup/freelanceskills`,
      manageData: manageSkillsResponse
    })

      sendFreelanceTypesRequest({
        url: '/sitemeup/tipologiefreelance',
        methodH: 'get',
        manageData: manageFreelanceTypes
      })
  }, [])
  

  
    

  const metaUtenteHandler = (key, value) => {
    // eslint-disable-next-line prefer-const
    let { id, tipo, metas } = { ...profileData }
    if (!metas) metas = []
    if (metas.find(e => e.key === key) !== undefined) {
      let newMeta = metas.find(m => m.key === key)
      newMeta = { ...newMeta, value }
      metas = [...metas.filter(m => m.key !== newMeta.key), newMeta]
    } else {
      const idTipo = tipo === 'cliente' ? 'id_cliente' : 'id_freelance'
      metas = [...metas, { key, value, [idTipo]: id }]
    }
    // console.log(dt)
    dispatch(setProfile({ tipo, key: 'metas', value: metas }))
    setSaved(false)
  }

  const findMetaValue = key => {
    const data = { ...profileData }
    // console.log('METAAAAAAA', data)
    const meta = data.metas?.find(m => m.key === key)
    return meta?.value || ''
  }

  const datiUtenteHandler = (key, value) => {
    const { tipo } = profileData
    if (key === 'telefono') {
      if (value.length < 10) {
        setErrors({...errors, telefono:true})
       
      } else {
        setErrors({...errors, telefono:null})
      }
    }

      dispatch(setProfile({ tipo, key, value }))
      setSaved(false)
    
    
  }

  const saveData = async () => {
    dispatch(setLoading())
    await dispatch(saveProfile())
    dispatch(removeLoading())
    setSaved(true)
  }

  const convertBase64 = file =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })

  const onUploadCVFileChange = async event => {
    const newCVFile = event.target.files[0]
    // eslint-disable-next-line prefer-const
    let { tipo, documents } = { ...profileData }
    const item = {
      id_document: 1, // id document CV
      filepath: '',
      estensione: newCVFile.name.split('.').pop(),
      name: newCVFile.name.replace(/\..+$/, ''),
      description: '',
      file: await convertBase64(newCVFile)
    }
    // const cv = documents.find(elem => elem.id_document === 1)
    // if (cv) item = { ...item, filepath: cv.filepath }

    documents = documents.filter(e => e.id_document !== 1)
    documents = [...documents, item]

    setCvMessage(true)
    setSaved(false)
    dispatch(
      setProfile({
        tipo,
        key: 'documents',
        value: documents
      })
    )
  }

  return (
    <>
      <div className="col-lg-12 bg-white">
        <div className={`p-lg-5 ${isClient && 'col-lg-12'}`}>
          <div className="row">
            <div className="col-12 px-1">
              <div className="card form-card bg-lightblue border-radius-none border-lg-radius-xl">
                <div className="card-header text-white text-uppercase pb-0">
                  <strong>Anagrafica</strong>
                </div>
                <div className="card-body text-white ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          NOME
                          <input
                            className="form-control"
                            placeholder="nome"
                            type="text"
                            id="nome"
                            name="nome"
                            value={profileData ? profileData.nome : ''}
                            onChange={e =>
                              datiUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          COGNOME
                          <input
                            className="form-control"
                            placeholder="cognome"
                            type="text"
                            id="cognome"
                            name="cognome"
                            value={profileData ? profileData.cognome : ''}
                            onChange={e =>
                              datiUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          EMAIL
                          <input
                            className="form-control"
                            placeholder="email"
                            type="text"
                            id="email"
                            name="email"
                            value={profileData ? profileData.email : ''}
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="password" className="d-block w-100">
                          PASSWORD
                          <input
                            className="form-control"
                            placeholder="*****"
                            type="password"
                            id="password"
                            name="password"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          CELLULARE
                          <input
                            className="form-control"
                            placeholder="+39 999999"
                            type="text"
                            id="telefono"
                            name="telefono"
                            value={profileData ? profileData.telefono : ''}
                            onChange={e =>
                              datiUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                            {errors.telefono ? (<><p className="text-white small">Il telefono deve essere composto da almeno 10 numeri</p></>) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="citta" className="d-block w-100">
                          CITTÀ
                          <input
                            className="form-control"
                            placeholder="eg. Roma"
                            type="text"
                            id="citta"
                            name="citta"
                            value={findMetaValue('citta')}
                            onChange={e =>
                              metaUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={`row  ${isClient && 'd-none'}`}>
                    <div className="col-md-12">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label
                          htmlFor="tipo_professionista"
                          className="d-block w-100"
                        >
                          TIPO DI PROFESSIONISTA
                          <select
                            className="form-control"
                            id="tipo_professionista"
                            name="tipo_professionista"
                            value={findMetaValue('tipo_professionista')}
                            onChange={e =>
                              metaUtenteHandler(e.target.name, e.target.value)
                            }
                          >
                            <option value="">Seleziona</option>
                            <option value="azienda">Azienda</option>
                            <option value="liberoprofessionista">
                              Libero Professionista
                            </option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          RAGIONE SOCIALE
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            id="ragionesociale"
                            name="ragionesociale"
                            value={findMetaValue('ragionesociale')}
                            onChange={e =>
                              metaUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="citta" className="d-block w-100">
                          P.IVA
                          <input
                            className="form-control"
                            placeholder="00000"
                            type="text"
                            id="piva"
                            name="piva"
                            value={findMetaValue('piva')}
                            onChange={e =>
                              metaUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          INDIRIZZO
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            id="indirizzo"
                            name="indirizzo"
                            value={profileData ? profileData.indirizzo : ''}
                            onChange={e =>
                              datiUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-center input-group input-group-static input-group-outline mb-4">
                        <label
                          htmlFor="citta"
                          className={`d-block w-55  ${isClient && 'w-100'}`}
                        >
                          CODICE UNIVOCO
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            id="univoco"
                            name="univoco"
                            value={findMetaValue('univoco')}
                            onChange={e =>
                              metaUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                        <div className={`w-45  ${isClient && 'd-none'}`}>
                          <div className="float-end mb-n3">
                            <MKBox display="flex" alignItems="center">
                              <Switch
                                checked={checked}
                                onChange={toggleSwitch}
                                color="error"
                              />
                              <MKTypography
                                variant="button"
                                color="white"
                                fontWeight="regular"
                                ml={1}
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={toggleSwitch}
                              >
                                Rivalsa INPS
                              </MKTypography>
                            </MKBox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`row  ${!isClient && 'd-none'}`}>
                    <div className="col-md-6">
                      <div className="input-group input-group-static input-group-outline mb-4">
                        <label htmlFor="telefono" className="d-block w-100">
                          CODICE FISCALE
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            id="codicefiscale"
                            name="codicefiscale"
                            value={profileData ? profileData.codicefiscale : ''}
                            onChange={e =>
                              datiUtenteHandler(e.target.name, e.target.value)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isClient ? (<>

          <div className={`row my-4 p-2  ${isClient && 'd-none'}`}>
            <div className="col-12 col-sm-9 col-md-7 col-lg-8 col-xl-6 mt-3 mt-xl-0 mx-auto">
              <div className="text-uppercase pb-0 px-2 text-center m-auto max-height-50">
                <strong>
                  Seleziona il tipo di freelancer che ti rappresenta
                </strong>
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                <Freelance 
                  value={profileData?.id_tipologia || null}
                  onChange={val =>
                      datiUtenteHandler('id_tipologia', val)
                  } 
                  freelanceTypes={freelanceTypes}
                />
              </div>
            </div>
            <div className="col-11 col-lg-8 col-xl-6 mx-auto d-flex flex-column">
              <div className="col-12 text-uppercase pb-0 text-center mx-auto my-0">
                <strong>AUTOVALUTAZIONE SULLA GESTIONE DEI PLUGIN </strong>
              </div>
              <div className="col-12 d-flex my-auto">
                <Plugin
                  // freelanceData={profileData.tipo === 'freelance' ? profileData : null}
                  setSaved={setSaved}
                  tipologia={profileData?.id_tipologia}
                  skills={skills}
                  value={profileData?.id_tipologia || null}
                  freelanceTypes={freelanceTypes}
                  // sendUpdate={skillsUtenteHandler}
                />
              </div>
            </div>
          </div>
          <div className={`row mt-lg-3 mb-lg-0  ${isClient && 'd-none'}`}>
            <div className="col-lg-6 d-flex">
              <div className="bg-cyan border-radius-none border-lg-radius-xl p-lg-2 w-100">
                <div className="card-header bg-none pb-0">
                  <strong className="text-uppercase">
                    INFORMAZIONI AGGIUNTIVE
                  </strong>
                </div>
                <div className="card-body">
                  <div className="input-group input-group-static input-group-outline mb-4 position-relative">
                    <label
                      htmlFor="presentazionebreve"
                      className="d-block w-100"
                    >
                      BIOGRAFIA
                      <button
                        type="button"
                        className="text-secondary my-auto float-end cursor-pointer btn box-shadow-none text-decoration-none border-0 btn-modifica-bio"
                        onClick={manageBiography}
                      >
                        {updateBiography ? (
                          <span>
                            <small>Modifica</small> <Create />
                          </span>
                        ) : (
                          <span>
                            <small>Modifica</small> <Create />
                          </span>
                        )}
                      </button>
                      <textarea
                        name="presentazionebreve"
                        className="form-control bg-white"
                        placeholder="Inserisci la tua bio"
                        id="presentazionebreve"
                        rows="10"
                        disabled={!updateBiography}
                        onChange={e =>
                          datiUtenteHandler(e.target.name, e.target.value)
                        }
                        value={profileData.presentazionebreve}
                      />
                    </label>
                  </div>
                </div>
              </div>
              
            </div>

            {existsCVFile || cvMessage ? (
              <div className="col-lg-6 d-flex">
                <div className="card form-card bg-lightblue border-radius-none border-lg-radius-xl py-5 py-lg-0 w-100">
                  <div className="card-body text-center d-flex">
                    <div className="w-100 input-group-static input-group-outline mb-4 justify-content-center">
                      <img
                        src={curriculumok}
                        alt="Curriculum Vitae"
                        width="88"
                        className="pb-3"
                      />
                      <h4 className="text-white lato">
                        CURRICULUM VITAE INSERITO CORRETTAMENTE
                      </h4>

                      <div className="row p-0 mt-5 mt-lg-4 mx-auto">
                        {!cvMessage ? (
                          <div className="col-lg-6 p-0 ">
                            {profileData &&
                            profileData.documents &&
                            profileData.documents.length > 0 ? (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                className="btn btn-lg btn-white font-weight-bold text-info"
                                href={
                                  profileData.documents.find(
                                    item => item.id_document === 1
                                  ).filepath
                                }
                              >
                                Scarica CV{' '}
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {!cvMessage ? (
                          <div className="col-lg-6 p-0 ">
                            <label htmlFor="cv">
                              <span className="btn btn-lg btn-white font-weight-bold text-info w-100">
                                {' '}
                                Aggiorna
                              </span>
                              <input
                                type="file"
                                id="cv"
                                name="cv"
                                onChange={onUploadCVFileChange}
                                className="d-none"
                                accept=".doc, .pdf"
                              />
                            </label>
                          </div>
                        ) : (
                          <div className="col-lg-12 p-0 ">
                            <label htmlFor="cv">
                              <span className="btn btn-lg btn-white font-weight-bold text-info w-100">
                                {' '}
                                Aggiorna
                              </span>
                              <input
                                type="file"
                                id="cv"
                                name="cv"
                                onChange={onUploadCVFileChange}
                                className="d-none"
                                accept=".doc, .pdf"
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-6 d-flex">
                <div className="card form-card bg-lightblue border-radius-none border-lg-radius-xl pt-5 pb-4 py-lg-0 w-100">
                  <div className="card-body text-center d-flex">
                    <div className="w-100 input-group-static input-group-outline mb-4 justify-content-center">
                      <img
                        src={curriculum}
                        alt="Curriculum Vitae"
                        width="88"
                        className="pb-3"
                      />
                      <h4 className="text-white lato">
                        INSERISCI IL TUO CURRICULUM VITAE
                      </h4>
                      (File in formato pdf, dimensione max. 2Mb)
                      <br />
                      <label htmlFor="cv" className="d-block w-100">
                        <span className="btn btn-lg btn-white mt-3 text-info font-weight-bold">
                          {' '}
                          Carica cv
                        </span>
                        <input
                          onChange={onUploadCVFileChange}
                          type="file"
                          id="cv"
                          name="cv"
                          className="d-none"
                          accept=".doc, .pdf"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          </>) : null}

        </div>

        <div className="bg-tertiary mb-lg-0 p-2 text-center text-lg-end">
          <button
            className={`btn btn-white mb-0 opacity-0 btn-save ${
              !saved && !errors.telefono ? 'opacity-10' : null
            }`}
            type="button"
            onClick={saveData}
          >
            Salva
          </button>
        </div>
      </div>
    </>
  )
}

export default ProfileBody
