import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
// import { AiFillCloseCircle } from 'react-icons/ai'
// import { IconContext } from 'react-icons'
import Sidebar from './Sidebar'
import './Drawer.css'

export default function SMUDrawer({ currentPage, setSelectedPage }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100vw' }}
      role="presentation"
      className="overflow-x-hidden"
      onClick={toggleDrawer(anchor, false)}
    >
      <button
        type="button"
        className="close-drawer"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        x
      </button>

      <Sidebar currentpage={currentPage} setSelectedPage={setSelectedPage} />
    </Box>
  )

  const anchorInfo = 'right' // ['left', 'right', 'top', 'bottom']

  return (
    <div>
      <React.Fragment key={anchorInfo}>
        <button
          type="button"
          className="btn-drawer"
          onClick={toggleDrawer(anchorInfo, true)}
        >
          <PersonOutlineIcon />
        </button>
        <Drawer
          anchor={anchorInfo}
          open={state[anchorInfo]}
          onClose={toggleDrawer(anchorInfo, false)}
          onOpen={toggleDrawer(anchorInfo, true)}
        >
          {list(anchorInfo)}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
