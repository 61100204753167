import logo from 'assets/images/sitemeup/sitemeup_logo-01.svg'
// import { useNavigate } from 'react-router-dom'
import './LivePreview.css'

export default function LivePreview() {
  return (
    <div className="livePreview">
      <header>
        <img
          src={logo}
          alt="sitemeup"
          style={{ width: '250px', height: 'auto' }}
          className="logo ms-4 me-auto mt-4 mb-4 d-block"
        />
        <button
          type="button"
          className="btn-sm text-bold mx-3"
          alt=""
          onClick={() => window.close()}
        >
          Close
        </button>
      </header>
      <footer>
        <iframe
          src="http://octhemedesign.com/opencart/3store/"
          title="livePreview"
        />
      </footer>
    </div>
  )
}
