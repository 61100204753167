// import { useEffect } from 'react'
import './BalletPoint.css'

export default function BalletPoint({ activeStep, steps, sendStep }) {
  const StepPoints = () => {
    const html = []
    for (let count = 0; count < steps; count += 1)
      html.push(
        <button type="button" onClick={()=>sendStep(count)} className={`${activeStep === count && 'active'} smu-ballet-point`}>
          {count + 1}
        </button>
      )
    return html
  }

  const StepText = () => (
    <div className="smu-ballet-text">
      Step &nbsp;<span className="fw-bold fs-5">{activeStep + 1}</span>/{steps}
    </div>
  )

  return (
    <div className="p-2">
      <div className="d-none d-md-flex flex-wrap justify-content-center gap-2">
        {StepPoints()}
      </div>
      <div className="d-flex d-md-none flex-wrap justify-content-center gap-2">
        {StepText()}
      </div>
    </div>
  )
}
