import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useHttp from 'hooks/use-http'
import { SMULoading } from 'components/SMU'
import SMUNavbar from 'commoncomponets/Navbars/SMUNavbar_main'
import SMUFooter from 'commoncomponets/Footer/SMUFooter'
import footerRoutes from 'footer.routes'

import I1 from 'assets/images/examples/blog1.jpg'
import I2 from 'assets/images/examples/blog8.jpg'
import I3 from 'assets/images/examples/blog5.jpg'

import Info from './sections/Info'
import Portfolio from './sections/Portfolio'
import './DettagliFreelancer.css'


const ExImgProjects = [
  { id: 0, img: I1 },
  { id: 1, img: I2 },
  { id: 2, img: I3 },
  { id: 3, img: I3 },
  { id: 4, img: I1 },
  { id: 5, img: I2 },
  { id: 6, img: I3 }
]

export default function DettagliFreelancer() {
  const { id } = useParams()
  const [user, setUser] = useState(null)

  const {
    sendRequest: sendUserRequest,
    isLoading: isUserRequestLoading,
    error: userRequestError
  } = useHttp()

  const manageDataResponse = (e) => {
    setUser(e.data)
    console.log(e.data)
  }

  useEffect(()=>{
    if (id) {
      sendUserRequest({
        url: `/sitemeup/freelance?id=${id}`,
        manageData: manageDataResponse
      })

      
    }
  }, [id])
  
  return (
    <>
      <SMUNavbar />
      <SMULoading show={isUserRequestLoading} />
      <div className="row m-0 lato freel-detail">
        <div className="col-12 col-lg-3 px-0 bg-gray">
          <Info user={user} />
        </div>
        <div className="col-12 col-lg-9 px-0 py-3 bg-white text-center text-lg-start">
          <div className="title px-5">Portfolio</div>
          <Portfolio list={ExImgProjects} user={user} />
        </div>
      </div>
      <SMUFooter content={footerRoutes} />
    </>
  )
}
