import React, { useState } from 'react'
import AccordionProject from 'pagesitemeup/CreaProgetto/components/AccordionProject/AccordionProject'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import Checkbox from '@mui/material/Checkbox'

import './CheckAction.css'

const listAccordion = [
  { id: 0, title: 'Ecommerce', desc: 'Una descrizione qualsiasi ...' },
  { id: 1, title: 'Multilingua', desc: 'Una descrizione qualsiasi ...' },
  {
    id: 2,
    title: 'Area Utenti Riservata',
    desc: 'Una descrizione qualsiasi ...'
  },
  { id: 3, title: 'Calendario eventi', desc: 'Una descrizione qualsiasi ...' },
  {
    id: 4,
    title: 'Appointment booking',
    desc: 'Una descrizione qualsiasi ...'
  },
  { id: 5, title: 'Ticket System', desc: 'Una descrizione qualsiasi ...' },
  {
    id: 6,
    title: 'Helpdesk, live chat, faq',
    desc: 'Una descrizione qualsiasi ...'
  },
  { id: 7, title: 'Newsletter', desc: 'Una descrizione qualsiasi ...' }
]

const CheckAction = () => {
  // stato impostato su "test" solo per visione del cliente. Definire successivamente su FALSE
  const [expanded, setExpanded] = useState('test')
  const handleAccordion = panel =>
    setExpanded(state => (state !== panel ? panel : false))

  const EXDescToRemove =
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremquelaudantium, totam rem aperiam eaque ipsa'
  // useEffect(() => console.log(expanded), [expanded])

  return (
    <>
      <div className="col-12 col-lg-10 py-5 py-lg-7 mx-auto">
        <AccordionProject
          title="Title Type Test"
          id="test"
          expanded={expanded}
          setExpanded={handleAccordion}
          status={1}
        >
          <div className="col-11 mx-auto">
            <p className="mb-5 font-weight-bold">
              Testo descrittivo, Sed ut perspiciatis unde omnis iste natus error
              sit voluptatem accusantium doloremque laudantium, totam rem
              aperiam eaque ipsa, quae ab illo inventore veritatis et quasi
              architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam
              voluptatem, quia voluptas sit, Sed ut perspiciatis unde omnis iste
              natus error sit voluptatem accusantium.
            </p>
            <div className="col-12">
              Una domanda qualsiasi ? <br />
              <textarea
                name="example1"
                className="w-100 p-2"
                value={EXDescToRemove}
              />
              <div className="d-flex gap-3 align-items-center mt-2">
                <input type="radio" checked />
                Sed ut perspiciatis
              </div>
            </div>
          </div>
        </AccordionProject>

        {listAccordion.map((item, index) => (
          <AccordionProject
            title={item.title}
            id={index}
            expanded={expanded}
            setExpanded={handleAccordion}
          >
            <div className="col-11 mx-auto">
              <p className="mb-5 font-weight-bold">{item.desc}</p>
              <div className="col-12">
                Una domanda qualsiasi ? <br />
                <textarea name="example1" className="w-100" />
              </div>
            </div>
          </AccordionProject>
        ))}
      </div>
    </>
  )
}

export default CheckAction
