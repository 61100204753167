import React from 'react'
import './SMULoading.css'
import marchio from './loading.gif'

const SMULoading = props => {
  const { show } = props

  return (
    <>
      {show ? (
        <div className="mask-loading">
          <img
            src={marchio}
            alt="Loading SiteMeUp"
            width="200"
            height="auto"
          />
        </div>
      ) : null}
    </>
  )
}

export default SMULoading
