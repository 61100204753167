/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/pages'

export default [
  {
    image: `${imagesPrefix}/coworking.jpg`,
    name: 'Coworking Page',
    route: '/pages/landing-pages/coworking'
  },
  {
    image: `${imagesPrefix}/rental.jpg`,
    name: 'Rental Page',
    route: '/pages/landing-pages/rental'
  },
  {
    image: `${imagesPrefix}/about-us.jpg`,
    name: 'About Us Page',
    route: '/pages/company/about-us'
  },
  {
    image: `${imagesPrefix}/pricing.jpg`,
    name: 'Pricing Page',
    route: '/pages/company/pricing'
  },
  {
    image: `${imagesPrefix}/help-center.jpg`,
    name: 'Help Center',
    route: '/pages/support/help-center'
  },
  {
    image: `${imagesPrefix}/contact.jpg`,
    name: 'Contact Us Page',
    route: '/pages/support/contact-us'
  },
  {
    image: `${imagesPrefix}/faq.jpg`,
    name: 'FAQ Page',
    route: '/pages/support/faq'
  },
  {
    image: `${imagesPrefix}/privacy.jpg`,
    name: 'Privacy Page',
    route: '/pages/support/privacy'
  },
  {
    image: `${imagesPrefix}/desktop.jpg`,
    name: 'Desktop App Page',
    route: '/pages/apps/desktop-app'
  },
  {
    image: `${imagesPrefix}/single-article.jpg`,
    name: 'Single Article Blog',
    route: '/pages/blogs/single-article'
  },
  {
    image: `${imagesPrefix}/author.jpg`,
    name: 'Author Blog Page',
    route: '/pages/blogs/author'
  },
  {
    image: `${imagesPrefix}/virtual-reality.jpg`,
    name: 'Virtual Reality',
    route: '/pages/extra/virtual-reality'
  }
]
