/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'
import logo from 'assets/images/sitemeup/sitemeup_logo-01.svg'
import './footerStyles.css'

function SMUFooter({ content }) {
  const { socials } = content

  return (
    <>
      <footer className="py-4 py-sm-4 bg-secondary text-white">
        <div className="col-12 px-2 overflow-hidden">
          <div className="row p-0 text-center text-md-start justify-content-center">
            <div className="col-md-4 d-flex flex-wrap justify-content-lg-center">
              <a className="col-12 text-center mb-n1 mb-md-n6" href="/">
                <img src={logo} className="logo mb-3" alt="Site Me Up" />
              </a>
              <MKBox
                className="col-12 justify-content-evenly mb-3 mx-auto max-width-250"
                display="flex"
                alignItems="center"
              >
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="white"
                    opacity={0.8}
                    mr={key === socials.length - 1 ? 0 : 2.5}
                    className="m-auto m-sm-3"
                  >
                    {icon}
                  </MKTypography>
                ))}
              </MKBox>
            </div>
            <div className="col-md-2 py-2 p-md-0 text-white">
              <span className="fs-5">
                <b>LINK UTILI</b>
              </span>
              <ul>
                <li>
                  <a href="/">Accedi</a>
                </li>
                <li>
                  <a href="/">Registrati</a>
                </li>
                <li>
                  <a href="/">Lavora con noi</a>
                </li>
                <li>
                  <a href="/">Formazione</a>
                </li>
                <li>
                  <a href="/">FAQ</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 pb-2 p-md-0 text-white">
              <span className="fs-5">
                <b>LEGAL</b>
              </span>
              <ul>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Cookie Policy</a>
                </li>
                <li>
                  <a href="/">Terms &amp; Conditions</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 text-white">
              <span className="fs-5">
                <b>CONTATTI</b>
              </span>
              <p className="mb-0">
                <b>Site Me Up Srl</b>
                <br />
                <b>Mail:</b>{' '}
                <a href="mailto: info@sitemeup.it"> info@sitemeup.it </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="text-center zilla">
        <strong className="fs-6">Site Me Up Srl</strong> &nbsp;
        <small className="fs-7">Via lorem ipsum 00100 Roma</small>
      </div>
    </>
  )
}

// Typechecking props for the DefaultFooter
SMUFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired
}

export default SMUFooter
