import { useState } from 'react'
import './AddProducts.css'

const Products = [
  {
    name: 'grafica',
    title: 'Grafica ad-hoc',
    desc: "Attento a non sottovalutare l'importanza della grafica, oggi i siti comunicano più con la grafica che con i testi",
    price: '10'
  },
  {
    name: 'seo',
    title: 'Seo di primo livello',
    desc: "La meta description è un'anteprima di testo che viene visualizzata per ogni risultato di ricerca e riepiloga il contenuto di una pagina",
    price: '20'
  },
  {
    name: 'wordpress',
    title: 'Aggiornamenti di wordpress dei plugin',
    desc: 'Aggiornare il template del tuo sito è molto importante perchè ne migliora la struttura, risolvendone i problemi e rendendola più sicura',
    price: '5'
  }
]

const AddProducts = () => {
  const [prods, setProds] = useState([])

  const addProd = obj => {
    for (let count = 0; count < prods.length; count += 1) {
      if (prods[count].name === obj.name) {
        setProds(prods.filter(item => item.name !== obj.name))
        return
      }
    }
    setProds(old => [...old, obj])
  }

  const checkProd = obj => {
    for (let count = 0; count < prods.length; count += 1) {
      if (prods[count].name === obj.name) {
        return true
      }
    }
    return false
  }

  return (
    <div className="add-products lato py-7">
      <h5 className="mb-4">Seleziona prodotti aggiuntivi</h5>
      <div className="col-11 col-sm-10 col-md-9 col-lg-8 mx-auto">
        {Products.map(obj => (
          <div className="d-flex col-12 col-md-4 p-2">
            <div className="btn-container">
              <button
                type="button"
                className={`${checkProd(obj) && 'active'}`}
                onClick={() => addProd(obj)}
                alt=""
              >
                <div className="price fs-3">
                  <small>€</small>&nbsp;{obj.price}
                </div>
                <desc className="text-center text-secondary">{obj.title}</desc>
                <p className="text-center text-md-start mb-1">{obj.desc}</p>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default AddProducts
