import { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'

export default function FilterBar({cities, skills, handleFilter}) {
  // const { handleFilter } = props
  const [filter, setFilter] = useState({})

  const cityChange = e =>
    setFilter(oldFilter => ({ ...oldFilter, citta: e.target.value }))
  const skillsChange = e =>
    setFilter(oldFilter => {
      if (oldFilter.skills === undefined)
        return { ...oldFilter, skills: [e.target.value] }
      if (oldFilter.skills.includes(e.target.value)) {
        const arr = oldFilter.skills.filter(s => s !== e.target.value)
        return { ...oldFilter, skills: arr }
      }
      return { ...oldFilter, skills: [...oldFilter.skills, e.target.value] }
    })

  useEffect(() => {
    handleFilter(filter)
  }, [filter])

  // const Fw = ({ children }) => <span className="label-text">{children}</span>

  return (
    <div className="filterbar lato">
      <div className="mb-2 fs-5 p-1">Filtra per:</div>

      <section>
        <span className="title">Skills</span>
        <ul>
          {skills.map((e, index) => (
            <li>
              <label htmlFor={`skill${index}`}>
                <Checkbox
                  name="skills"
                  value={e}
                  onChange={skillsChange}
                  id={`skill${index}`}
                  sx={{ padding: '6px 0' }}
                />
                <span className="label">{e}</span>
              </label>
            </li>
          ))}
        </ul>
      </section>

      <div className="bar" />

      <section>
        <span className="title">Città</span>
        <select
          id="city-filter"
          value={filter?.citta}
          onChange={cityChange}
          placeholder="Seleziona Città"
          className="smu-form-control smu-selector"
        >
          <option value="" selected>
            Tutte le Città
          </option>
          {cities.map(val => (
            <option value={val}>{val}</option>
          ))}
        </select>
      </section>
    </div>
  )
}
