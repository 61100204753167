import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectProfile, clearProfile } from 'slices/profileSlice'

// for dropdown menu
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Logout from '@mui/icons-material/Logout'

import profileImage from 'assets/images/ivana-square.jpg'
// import MKButton from 'components/MKButton'
import Login from './LoginForm'

const LoginButton = ({ className, value, link }) => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfile)
  // const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [user, setUser] = useState({
    email: authContext.email,
    name: authContext.name,
    id: authContext.id,
    token: authContext.token,
    isLoggedIn: authContext.isLoggedIn
  })
  const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const userdata = JSON.parse(localStorage.getItem('userdata'))
    // console.log('userData from loginButton :', userdata)
    if (userdata) {
      if (userdata.isLoggedIn) {
        setIsLoggedIn(true)
        setUser(userdata)
      }
    }
  }, [localStorage.getItem('userdata')])

  return (
    <>
      {isLoggedIn ? (
        <>
          <Box
            sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
          >
            <div
              className="text-white cursor-pointer"
              onClick={handleClick}
              aria-hidden="true"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <img
                src={
                  profileData && profileData.pathfoto
                    ? profileData.pathfoto
                    : profileImage
                }
                alt="Profilo"
                className="avatar bg-primary p-1 border-radius-100"
              />
              <small className="px-2 font-weight-light">{user.name}</small>
            </div>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem to="/profilo" component={Link}>
              <Avatar
                alt={user.name}
                src={
                  profileData &&
                  profileData.freelancedata &&
                  profileData.freelancedata.pathfoto
                    ? profileData.freelancedata.pathfoto
                    : profileImage
                }
              />{' '}
              Profilo
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                dispatch(clearProfile)
                authContext.sendLogout()
                setIsLoggedIn(false)
                localStorage.removeItem('userdata')
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
        {show ? (
          <Login
            show={show}
            origin={link || '/profilo'}
            sendLogin={target => {
              setIsLoggedIn(true)
              navigate(target)
            }}
            sendLogout={() => {
              dispatch(clearProfile)
              authContext.sendLogout()
              setIsLoggedIn(false)
              localStorage.removeItem('userdata')
            }}
            close={() => setShow(false)}
          />
          ) : null}
          <button
            onClick={() => setShow(true)}
            variant="text"
            color="white"
            type="button"
            className={
              className ||
              'btn border-0 btn-block bg-white text-secondary fs-7 my-0'
            }
          >
            {value || 'Accedi'}
          </button>
        </>
      )}
    </>
  )
}

export default LoginButton
