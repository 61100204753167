const SearchBar = ({ handleKeyWord }) => (
  <div className="col-12 col-sm-11 col-10 mx-auto">
    <input
      type="text"
      onChange={e => handleKeyWord(e.target.value)}
      className="search-bar"
      placeholder="ricerca"
    />
  </div>
)

export default SearchBar
