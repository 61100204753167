import MKAvatar from "components/MKAvatar";
import Stars from "./Stars";

export default function RecensioniFreelance({idFreelance, valutation, freelance, freetype, onChange, sendValutation}) {

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Recensisci freelance</h2>
          </div>
          <div className="col-3 text-center justify-content-center">
                  <MKAvatar
                    src={freelance?.pathfoto || ''}
                    alt={`${freelance?.nome} ${freelance?.cognome}`}
                    shadow="md"
                    variant="rounded"
                  />
                  <div className="pt-4">
                  <p className="ms-3 mb-0">
                    {`${freetype?.name?.toUpperCase()}`}<br />
                    <span className="text-primary">{freelance?.nome} {freelance?.cognome}</span>
                  </p>
                  </div>
          </div>
          <div className="col-9">
              <div className="row">
                <div className="col-6">
                  <h4>{valutation?.parametro1_nome || "Tempi di esecuzione"}</h4>
                  <Stars stars={valutation?.parametro1_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro1_stelle":e})} />
                </div>
                <div className="col-6">
                  <h4>{valutation?.parametro2_nome || "Professionalità"}</h4>
                  <Stars stars={valutation?.parametro2_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro2_stelle":e})} />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <h4>{valutation?.parametro3_nome || "Creatività"}</h4>
                  <Stars stars={valutation?.parametro3_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro3_stelle":e})} />
                </div>
                <div className="col-6">
                  <h4>{valutation?.parametro4_nome || "Altro criterio 1"}</h4>
                  <Stars stars={valutation?.parametro4_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro4_stelle":e})} />
                </div>
                <div className="col-6">
                  <h4>{valutation?.parametro5_nome || "Altro criterio 2"}</h4>
                  <Stars stars={valutation?.parametro5_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro5_stelle":e})} />
                </div>
                <div className="col-6">
                  <h4>{valutation?.parametro6_nome || "Altro criterio 3"}</h4>
                  <Stars stars={valutation?.parametro6_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro6_stelle":e})} />
                </div>
                <div className="col-6">
                  <h4>{valutation?.parametro7_nome || "Altro criterio 4"}</h4>
                  <Stars stars={valutation?.parametro7_stelle || 0} onChange={(e)=>onChange({...valutation, "parametro7_stelle":e})} />
                </div>
              </div>
          </div>
          {valutation?.parametro1_stelle > 0 && valutation?.parametro2_stelle > 0 && valutation?.parametro3_stelle > 0 && valutation?.parametro4_stelle > 0 && valutation?.parametro5_stelle > 0 && valutation?.parametro6_stelle > 0 && valutation?.parametro7_stelle > 0 ? (<>
            <div className="col-12 text-center mt-4">
                <button type="button" className="btn btn-primary" onClick={sendValutation}>Invia valutazione</button>
            </div>
          </>) : null}
        </div>
      </div>
    )
  }
  