import React, { useState } from 'react'
import SiteMeUpMarchio from 'assets/images/sitemeup/sitemeup_marchio.svg'
import SiteMeUpGaranzia from 'assets/images/sitemeup/garanzia.jpg'

const PaymentPlan = () => {
  // const { handleNext } = props
  const [payment, setPayment] = useState(null)

  const selectPayment = val => {
    setPayment(val)
  }

  return (
    <div className="bg-white py-5 text-center">
      <div className="col-11 col-lg-10 mx-auto">
        <img
          src={SiteMeUpMarchio}
          alt="sitemeup"
          style={{ width: '70px', height: 'auto' }}
          className="logo mx-auto d-block"
        />
        <p className="text-uppercase text-secondary mb-4">
          Seleziona il piano di pagamento
        </p>

        <div className="row">
          <button
            type="button"
            className={`opacity-5 p-0 mb-3 bg-white border-radius-lg ${
              !payment && 'border-success border-3 opacity-10'
            }`}
            onClick={() => selectPayment(0)}
            style={{ borderStyle: 'solid' }}
          >
            <div className="col-12 mx-auto">
              <div className="card p-3 mt-3 p-lg-4 text-center text-lg-start">
                <div className="row d-flex justify-content-center zilla">
                  <div className="col-md-6 col-lg-7 col-12 mb-lg-0 mb-4">
                    <p className="text-lg text-secondary fw-bold">
                      <h5>PIANO BASE</h5> <br />
                      Metti al sicuro il tuo investimento <br />
                      <span className="fw-light">Affidati a Site Me Up</span>
                    </p>
                    <p className="text-secondary">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam eaque ipsa, quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt, explicabo. Nemo
                      enim ipsam voluptatem, quia voluptas sit, Sed ut
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium.
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-5 col-12 mb-lg-0 mb-4">
                    <img
                      src={SiteMeUpGaranzia}
                      className="w-100"
                      alt="sitemeup garanzia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </button>

          <button
            type="button"
            className={`opacity-5 p-0 mb-3 bg-white border-radius-lg ${
              payment && 'border-success border-3 opacity-10'
            }`}
            onClick={() => selectPayment(1)}
            style={{ borderStyle: 'solid' }}
          >
            <div className="col-12 mx-auto">
              <div className="card p-3 mt-3 p-lg-4 text-center text-lg-start">
                <div className="row d-flex justify-content-center zilla">
                  <div className="col-12 mb-lg-0 mb-4">
                    <h5>PIANO BASE</h5>
                    <p className="text-lg text-secondary">
                      <b>Deposita subito il 30% </b> sul conto del web designer
                    </p>
                    <p className="text-secondary">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremquelaudantium, totam rem
                      aperiam eaque ipsa
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
export default PaymentPlan
