/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import homePage1 from 'assets/images/sitemeup/homepage1.png'
import homePage2 from 'assets/images/sitemeup/homepage2.png'
import homePage3 from 'assets/images/sitemeup/homepage3.png'
import geometricA from 'assets/images/sitemeup/geometricA.svg'
import geometricB from 'assets/images/sitemeup/geometricB.svg'
import landingart from 'assets/images/sitemeup/landing-art1.svg'
import bgLeft from 'assets/images/sitemeup/bg-left.svg'

// import { FormattedMessage } from 'react-intl'

function AboutUs() {
  const arrayHomeCard = [
    {
      image: homePage1,
      text: 'Scegli la grafica selezionata al portale più famoso del mondo e pubblica la tuarichiesta!'
    },
    {
      image: homePage2,
      text: 'Ricevi preventivi dai nostri migliori freelancer e scegli quello più adatto alle tue esigenze'
    },
    {
      image: homePage3,
      text: 'Creare un sito web professionale non é mai stato così semplice'
    }
  ]
  return (
    <>
      <section className="py-4">
        <div className="col-12 m-0 p-0 px-lg-4 position-relative overflow-hidden">
          <img
            src={geometricA}
            className="geometricA_rlogo z-index-0"
            alt="Site Me Up - theme"
          />
          <img
            src={geometricB}
            className="geometricB_llogo z-index-0"
            alt="Site Me Up - theme"
          />

          <div className="container position-relative z-index-3">
            <div className="row text-center justify-content-center">
              <div className="col-lg-8 py-4">
                <h2 className="text-white">Come funziona</h2>
              </div>
            </div>
          </div>

          <div className="col-11 m-auto py-4 py-lg-5 position-relative z-index-3">
            <div className="container">
              <div className="row">
                {arrayHomeCard.map(item => (
                  <div className="col-12 col-lg-4 mx-auto mb-7 mt-0 mb-lg-0  zilla ">
                    <div className="pb-4 pb-lg-0 mb-3 mb-lg-5  position-relative text-center cover img-hp-AboutUs">
                      <img
                        src={item.image}
                        alt="Professionalità"
                        className="img-fluid"
                      />
                    </div>
                    <p className="text-center text-lg-start font-size-2 text-secondary px-lg-4">
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="buttons text-center my-4">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            style={{ boxShadow: 'none' }}
          >
            Scopri di più
          </button>
        </div>
      </section>
      <section className="py-5 bg-white" style={{ backgroundImage: bgLeft }}>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src={landingart} alt="landing" className="w-150 w-lg-100" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUs
