import React, { useState, useEffect } from 'react'
import { Select, Checkbox, Input, MenuItem, TextField } from '@mui/material'
import Programmatore from './images/web_developer_2x.png';

import './TeamInstructions.css'

const TeamInstructions = props => {
  const { project, freelances, handleNext, handleBack, sendUpdate, sendCurrentHeaderData } = props
  const { profiles, projectType } = project
  const [currentFreelance, setCurrentFreelance] = useState(0)
  const [current, setCurrent] = useState(
    freelances.find(
      el =>
        parseInt(el.id, 10) ===
        parseInt(profiles[currentFreelance]?.id_tipofreelance, 10)
    ) || {}
  )
  const [questions, setQuestions] = useState({})
  const [budget, setBudget] = useState(0)
  const [budgetTotale, setBudgetTotale] = useState(0)
  const [mandatoryCheck, setMandatoryCheck] = useState(false)

  const handleTheNext = () => {
    sendCurrentHeaderData({})
    handleNext()
  }

  const handleTheBack = () => {
    sendCurrentHeaderData({})
    handleBack()
  }
  const handleNextFreelance = () => {
    if (currentFreelance + 1 === profiles.length) handleTheNext()
    else setCurrentFreelance(state => state + 1)
  }

  const handleBackFreelance = () => {
    if (currentFreelance === 0) handleTheBack()
    else setCurrentFreelance(state => state - 1)
  }

  const checkMandatories = () => {
    if (current?.questions.length === 0) {
      if (!profiles.find(pp => pp.id_tipofreelance === current.id)?.attivita) {
        setMandatoryCheck(false)
      } else {
        setMandatoryCheck(true)
      }
    } else {
      let checkIsOk = true
      console.log(questions)
      if (questions) {
        Object.keys(questions).map(el => {
          questions[el].map(eq => {
            const quest = eq?.tipofreelancequestion || eq
            const prof = profiles.find(pp => pp.id_tipofreelance === current.id)
            console.log(quest.mandatory)
            if (parseInt(quest.mandatory, 10) === 1) {
              checkIsOk =
                (prof?.answers.find(
                  pp => pp.id_question === (quest?.id_domanda || quest.id)
                )?.answer &&
                  checkIsOk) ||
                false
              console.log(
                checkIsOk,
                'CHECK FOR',
                prof?.answers.find(
                  pp => pp.id_question === (quest?.id_domanda || quest.id)
                )?.answer
              )
            }
            return eq
          })
          return el
        })
      }
      if (!profiles.find(pp => pp.id_tipofreelance === current.id)?.attivita) {
        checkIsOk = false
      }

      setMandatoryCheck(checkIsOk)
    }
  }

  const getQuestionsAsObject = () => {
    const q = Array.isArray(current?.questions) ? {} : current?.questions
    const qf = projectType.tipifreelance.find(
      el => el.id_tipofreelance === current.id
    )?.questions

    return qf || q
  }

  const updateAnswer = (val, questionID, cost, molt, optvalue) => {
    sendUpdate(
      'profiles',
      profiles.map(el => {
        if (el.id_tipofreelance === current.id) {
          return {
            ...el,
            answers: el?.answers.find(pp => pp.id_question === questionID)
              ? el?.answers.map(ans => {
                  if (ans.id_question === questionID) {
                    return {
                      ...ans,
                      answer: val,
                      budget: cost,
                      moltiplicatorio: molt,
                      optvalue
                    }
                  }
                  return ans
                })
              : [
                  ...el.answers,
                  {
                    id_question: questionID,
                    answer: val,
                    budget: cost,
                    moltiplicatorio: molt,
                    optvalue
                  }
                ]
          }
        }
        return el
      })
    )
  }

  const updateAttivita = v => {
    sendUpdate(
      'profiles',
      profiles.map(el => {
        if (el.id_tipofreelance === current.id) {
          return { ...el, attivita: v }
        }
        return el
      })
    )
  }

  const updateBudget = () => {
    const baseCost =
      project.tiposito === 1
        ? projectType.tipifreelance.find(
            el => el.id_tipofreelance === current.id
          )?.costonuovo
        : projectType.tipifreelance.find(
            el => el.id_tipofreelance === current.id
          )?.costorifacimento

    setBudget(
      (baseCost || 0) +
        profiles
          .find(pp => pp.id_tipofreelance === current.id)
          ?.answers.reduce((acc, x) => {
            if (x.answer) {
              if (x.moltiplicatorio === 1) {
                if (x.optvalue)
                  return acc + (x.budget || 0)

                return acc + (x.budget * (parseInt(x.answer, 10)) || 0)
              }
              return acc + x.budget
            }

            return acc
          }, 0) || 0
    )

    setBudgetTotale(
      0 +
        profiles
        .reduce((accs, el, i)=> {
            if (i <= currentFreelance) {
              const bsc =
              project.tiposito === 1
                ? projectType.tipifreelance.find(
                    ex => el.id_tipofreelance === ex.id_tipofreelance
                  )?.costonuovo
                : projectType.tipifreelance.find(
                    ex => ex.id_tipofreelance === el.id_tipofreelance
                  )?.costorifacimento

              return accs + (bsc || 0) + el.answers.reduce((acc, x) => {
                if (x.answer) {
                  if (x.moltiplicatorio === 1) {
                    if (x.optvalue)
                      return acc + (x.budget || 0)

                    return acc + (x.budget * (parseInt(x.answer, 10)) || 0)
                  }
                  return acc + x.budget
                }
    
                return acc
              }, 0) || 0
            }
            return accs;
          }, 0) || 0
    , 'BUDGET TOTALE')
  }

  useEffect(() => {
    if (profiles.length === 0) handleTheNext()

    setCurrent(
      freelances.find(
        el =>
          parseInt(el.id, 10) ===
          parseInt(profiles[currentFreelance]?.id_tipofreelance, 10)
      ) || {}
    )

    window.scrollTo({top: 0, behavior: 'smooth'});
    
  }, [currentFreelance])

  useEffect(()=>{

    sendCurrentHeaderData({
      title:current?.name || '',
      description:(<><span dangerouslySetInnerHTML={{__html:current?.description}} /></>) || '',
      icon:current?.pathfoto || Programmatore,
      iconTitle:current?.name ||''
    })

  }, [current])

  useEffect(() => {
    setQuestions(getQuestionsAsObject())
    updateBudget()
  }, [current, profiles])

  useEffect(() => {
    checkMandatories()
  }, [questions, profiles])

  useEffect(
    () => console.log(questions, profiles, 'DOMANDE e PROFILI', current),
    [questions, profiles]
  )

  useEffect(()=>{
    sendUpdate(
      'profiles',
      profiles.map(el => {
        if (el.id_tipofreelance === current.id) {
          return { ...el, budget }
        }
        return el
      })
    )


  }, [budget])

  return (
    <div className="container mx-auto">

    <div className="row">
        <div className="col-md-11 mx-auto p-4">
            <div className="d-flex justify-content-center align-items-center">
                <h2>Stima i costi del tuo progetto <span className="costBox dropshadow">€ {budgetTotale.toFixed(2)}</span></h2>
            </div>   
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-md-8">

          <div className="row">
            <div className="col-md-11 mx-auto">

           

        <h3 className="text-uppercase">
          <strong>{currentFreelance + 1}/{profiles.length} - {current?.name}</strong>
        </h3>
        <p>Rispondi alle domande</p>

        <div className="row">
          <div className="col-12">
            <p>Descrizione dell&lsquo;attività richiesta *</p>
            <TextField
            variant="filled"
              fullWidth
              multiline
              rows={3}
              required
              value={
                profiles.find(pp => pp.id_tipofreelance === current.id)
                  ?.attivita || ''
              }
              onChange={e => updateAttivita(e.target.value)}
            />
          </div>
        </div>

        {questions &&
          Object.keys(questions).map(el => (
            <>
              <div key={el}>
                <h4 className="mt-5 mb-4"><strong>{el}</strong></h4>
                {questions[el].map(eq => {
                  const quest = eq?.tipofreelancequestion || eq
                  if (!quest.answers && eq.answers) {
                    quest.answers = eq.answers;
                  }
                  const qt = parseInt(quest.id_question_type, 10)
                  const prof = profiles.find(
                    pp => pp.id_tipofreelance === current.id
                  )

                  return (
                    <>
                      <div key={`quest${quest.id}`} className="row">
                        <div className="col-12">
                          <p>
                            {quest.text}
                            {quest.mandatory ? ' *' : null}
                          </p>

                          {qt === 1 ? (
                            <TextField
                              variant="filled"
                              fullWidth
                              required={quest.mandatory}
                              type={
                                quest.moltiplicatorio === 1 ? 'number' : 'text'
                              }
                              value={
                                prof?.answers.find(
                                  pp =>
                                    pp.id_question ===
                                    (quest?.id_domanda || quest.id)
                                )?.answer || ''
                              }
                              onChange={e =>
                                updateAnswer(
                                  e.target.value,
                                  quest?.id_domanda || quest.id,
                                  quest.budget,
                                  quest.moltiplicatorio,
                                  null
                                )
                              }
                            />
                          ) : null}

                          {qt === 3 ? (
                            <>
                              {quest?.answers?.length > 0 ? (
                                <>
                                  <Select
                                    name={`quest${quest.id}`}
                                    inputProps="aria-label"
                                    variant="filled"
                                    value={
                                      prof?.answers.find(
                                        pp =>
                                          pp.id_question ===
                                          (quest?.id_domanda || quest.id)
                                      )?.answer || ''
                                    }
                                    sx={{ fontSize: '1rem' }}
                                    onChange={e => {

                                      const bdq = quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.moltiplicatorio && !Number.isNaN(quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.value) ? quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.budget * parseInt(quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.value, 10) : quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.budget;

                                      console.log(quest?.answers.find(
                                        qq => qq.key === e.target.value
                                      )?.value, 'QUEST')
                                      updateAnswer(
                                        e.target.value,
                                        quest?.id_domanda || quest.id,
                                        bdq || 0,
                                        quest.moltiplicatorio,
                                        quest?.answers.find(
                                          qq => qq.key === e.target.value
                                        )?.value || false
                                      )
                                    }
                                    }
                                    fullWidth
                                    required={quest.mandatory}
                                  >
                                    <MenuItem value="">
                                      Seleziona una risposta
                                    </MenuItem>
                                    {quest.answers.map(qAns => (
                                      <MenuItem
                                        value={qAns.key}
                                        dataCost={qAns.budget}
                                      >
                                        {qAns.key}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              ) : (
                                <>
                                  <Checkbox
                                    checked={
                                      prof?.answers.find(
                                        pp =>
                                          pp.id_question ===
                                          (quest?.id_domanda || quest.id)
                                      )?.answer || false
                                    }
                                    onChange={e =>
                                      updateAnswer(
                                        e.target.checked ? 1 : 0,
                                        quest?.id_domanda || quest.id,
                                        quest.budget,
                                        quest.moltiplicatorio,
                                        null
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          ))}

 </div>
          </div>

        </div>
        <div className="col-md-4 px-5 text-center">
          <div className="dropshadow">
            <img src={current?.pathfoto || Programmatore} className="img-fluid" alt={current?.name} />
            <p style={{background:'#EDF1F4', display:'block', padding:'10px 0'}}><span className="text-uppercase">Stima costo professionista</span><br /><span style={{color:"#0565a2", fontSize:'1.3em' }}>€ {budget.toFixed(2)}</span></p>
          </div>
        </div>
      </div>

      
      <div className="col-11 mx-auto">
        
       

        <div className="container">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-lg btn-primary ms-0 me-auto letter-spacing-1"
                  alt=""
                  onClick={() => handleBackFreelance()}
                >
                  <small>Indietro</small>
                </button>
                {mandatoryCheck ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary ms-auto me-0 letter-spacing-1"
                      alt=""
                      onClick={() => handleNextFreelance()}
                    >
                      <small>Avanti</small>
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamInstructions
