/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections'

export default [
  {
    title: 'Design Blocks',
    description:
      'A selection of 45 page sections that fit perfectly in any combination',
    items: [
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: 'Page Headers',
        count: 10,
        route: '/sections/page-sections/page-headers'
      },
      {
        image: `${imagesPrefix}/features.jpg`,
        name: 'Features',
        count: 14,
        route: '/sections/page-sections/features'
      },
      {
        image: `${imagesPrefix}/pricing.jpg`,
        name: 'Pricing',
        count: 8,
        route: '/sections/page-sections/pricing'
      },
      {
        image: `${imagesPrefix}/faq.jpg`,
        name: 'FAQ',
        count: 1,
        route: '/sections/page-sections/faq'
      },
      {
        image: `${imagesPrefix}/blogs.jpg`,
        name: 'Blog Posts',
        count: 11,
        route: '/sections/page-sections/blog-posts'
      },
      {
        image: `${imagesPrefix}/testimonials.jpg`,
        name: 'Testimonials',
        count: 11,
        route: '/sections/page-sections/testimonials'
      },
      {
        image: `${imagesPrefix}/teams.jpg`,
        name: 'Teams',
        count: 6,
        route: '/sections/page-sections/teams'
      },
      {
        image: `${imagesPrefix}/stats.jpg`,
        name: 'Stats',
        count: 3,
        route: '/sections/page-sections/stats'
      },
      {
        image: `${imagesPrefix}/call-to-action.jpg`,
        name: 'Call to Actions',
        count: 8,
        route: '/sections/page-sections/cta'
      },
      {
        image: `${imagesPrefix}/projects.jpg`,
        name: 'Applications',
        count: 6,
        route: '/sections/page-sections/applications'
      },
      {
        image: `${imagesPrefix}/logo-area.jpg`,
        name: 'Logo Areas',
        count: 4,
        route: '/sections/page-sections/logo-areas'
      },
      {
        image: `${imagesPrefix}/footers.jpg`,
        name: 'Footers',
        count: 10,
        route: '/sections/page-sections/footers'
      },
      {
        image: `${imagesPrefix}/general-cards.jpg`,
        name: 'General Cards',
        count: 9,
        route: '/sections/page-sections/general-cards'
      },
      {
        image: `${imagesPrefix}/content-sections.jpg`,
        name: 'Content Sections',
        count: 8,
        route: '/sections/page-sections/content-sections'
      }
    ]
  },
  {
    title: 'Navigation',
    description: '30+ components that will help go through the pages',
    items: [
      {
        image: `${imagesPrefix}/navbars.jpg`,
        name: 'Navbars',
        count: 4,
        route: '/sections/navigation/navbars'
      },
      {
        image: `${imagesPrefix}/nav-tabs.jpg`,
        name: 'Nav Tabs',
        count: 2,
        route: '/sections/navigation/nav-tabs'
      },
      {
        image: `${imagesPrefix}/pagination.jpg`,
        name: 'Pagination',
        count: 3,
        route: '/sections/navigation/pagination'
      }
    ]
  },
  {
    title: 'Input Areas',
    description:
      '50+ elements that you need for text manipulation and insertion',
    items: [
      {
        image: `${imagesPrefix}/newsletters.jpg`,
        name: 'Newsletters',
        count: 6,
        route: '/sections/input-areas/newsletter'
      },
      {
        image: `${imagesPrefix}/contact-sections.jpg`,
        name: 'Contact Sections',
        count: 8,
        route: '/sections/input-areas/contact-sections'
      },
      {
        image: `${imagesPrefix}/forms.jpg`,
        name: 'Forms',
        count: 3,
        route: '/sections/input-areas/forms'
      },
      {
        image: `${imagesPrefix}/inputs.jpg`,
        name: 'Inputs',
        count: 6,
        route: '/sections/input-areas/inputs'
      }
    ]
  },
  {
    title: 'Attention Catchers',
    description:
      '20+ Fully coded components that popup from different places of the screen',
    items: [
      {
        image: `${imagesPrefix}/alerts.jpg`,
        name: 'Alerts',
        count: 4,
        route: '/sections/attention-catchers/alerts'
      },
      {
        image: `${imagesPrefix}/toasts.jpg`,
        name: 'Notifications',
        count: 3,
        route: '/sections/attention-catchers/notifications'
      },
      {
        image: `${imagesPrefix}/popovers.jpg`,
        name: 'Tooltips & Popovers',
        count: 2,
        route: '/sections/attention-catchers/tooltips-popovers'
      },
      {
        image: `${imagesPrefix}/modals.jpg`,
        name: 'Modals',
        count: 5,
        route: '/sections/attention-catchers/modals'
      }
    ]
  },
  {
    title: 'Elements',
    description:
      '80+ carefully crafted small elements that come with multiple colors and shapes',
    items: [
      {
        image: `${imagesPrefix}/buttons.jpg`,
        name: 'Buttons',
        count: 6,
        route: '/sections/elements/buttons'
      },
      {
        image: `${imagesPrefix}/avatars.jpg`,
        name: 'Avatars',
        count: 2,
        route: '/sections/elements/avatars'
      },
      {
        image: `${imagesPrefix}/dropdowns.jpg`,
        name: 'Dropdowns',
        count: 2,
        route: '/sections/elements/dropdowns'
      },
      {
        image: `${imagesPrefix}/switch.jpg`,
        name: 'Toggles',
        count: 2,
        route: '/sections/elements/toggles'
      },
      {
        image: `${imagesPrefix}/social-buttons.jpg`,
        name: 'Social Buttons',
        count: 2,
        route: '/sections/elements/social-buttons'
      },
      {
        image: `${imagesPrefix}/breadcrumbs.jpg`,
        name: 'Breadcrumbs',
        count: 1,
        route: '/sections/elements/breadcrumbs'
      },
      {
        image: `${imagesPrefix}/badges.jpg`,
        name: 'Badges',
        count: 3,
        route: '/sections/elements/badges'
      },
      {
        image: `${imagesPrefix}/progress.jpg`,
        name: 'Progress Bars',
        count: 4,
        route: '/sections/elements/progress-bars'
      },
      {
        image: `${imagesPrefix}/tables.jpg`,
        name: 'Tables',
        count: 3,
        route: '/sections/elements/tables'
      },
      {
        image: `${imagesPrefix}/typography.jpg`,
        name: 'Typography',
        count: 2,
        route: '/sections/elements/typography'
      }
    ]
  }
]
