import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import MobileStepper from '@mui/material/MobileStepper'
import templateImg from 'assets/images/sitemeup/creaprogetto/template_view.png'
// Images
import themeA from 'assets/images/sitemeup/creaprogetto/cp-themeA.png'
import themeB from 'assets/images/sitemeup/creaprogetto/cp-themeB.png'
import logoimg from 'assets/images/sitemeup/sitemeup_logo-01.svg'

import BalletPoint from '../components/BalletPoint'
import './Header.css'

export default function HeaderTemplate({
  // msg = '',
  template,
  activeStep,
  stepTextData,
  dynamicHeaderData,
  steps=7,
  sendStep,
  freelanceTypes,
  profiles
}) {
  const { objTemplate, setObjTemplate } = template || {}
  const { title:theTitle, description:theDesc, icon:theIcon, iconTitle:theIconTitle, includeTeam } = stepTextData[activeStep] || {title:'', description:'', icon:'', iconTitle:'', includeTeam:false};

  const [title, setTitle] = useState(theTitle)
  const [description, setDescription] = useState(theDesc)
  const [icon, setIcon] = useState(theIcon)
  const [iconTitle, setIconTitle] = useState(theIconTitle)

  useEffect(()=>{
    setTitle(stepTextData[activeStep]?.title || theTitle)
    setDescription(stepTextData[activeStep]?.description || theDesc)
    setIcon(stepTextData[activeStep]?.icon || theIcon)
    setIconTitle(stepTextData[activeStep]?.iconTitle || theIconTitle)
    console.log(profiles, 'TEAM INSERITO');
    console.log(freelanceTypes, 'TIPI FREELANCES');
}, [activeStep, stepTextData])


  useEffect(()=>{
      setTitle(dynamicHeaderData?.title || theTitle)
      setDescription(dynamicHeaderData?.description || theDesc)
      setIcon(dynamicHeaderData?.icon || theIcon)
      setIconTitle(dynamicHeaderData?.iconTitle || theIconTitle)
    
  }, [dynamicHeaderData])



  return (
    <header>
      <div
        className={` align-items-start position-relative overflow-hidden ${
          activeStep < 3 && 'pb-6'
        }`}
      >
        <div className="bg-secondary position-absolute w-100 h-100 z-index-0">
          {' '}
        </div>

        <img src={themeA} className="themeA" alt="" />
        <img src={themeB} className="themeB" alt="" />

        <div className="container mt-5 lato header-title">
         

          { !icon ? (<><div className="col-lg-10 mx-auto text-white text-center text-lg-start">
            <h3 className="text-white text-center text-lg-start">
              {title}
              
            </h3>
            {includeTeam && profiles.length > 0 ? (<>
                 <p>{profiles.map((el)=>(<>
                    <img className="me-2" src={freelanceTypes.find((ej)=>ej.id === el.id_tipofreelance)?.pathfoto || ''} alt={freelanceTypes.find((ej)=>ej.id === el.id_tipofreelance)?.name} width="100" />
                  </>))}</p>
                </>) : null}
                
            <p className="text-white pb-5">{description}</p>
          </div></>) : (<>
          <div className="col-md-9 mx-auto mt-5">
            <div className="row align-items-center">
              <div className="col-md-4 text-white text-center">
                <img src={icon} alt={iconTitle} className="img-fluid" />
                <p className="text-white text-uppercase pb-5">{iconTitle}</p>
              </div>
              <div className="col-md-8  text-white text-center text-lg-start">
                <h3 className="text-white text-center text-lg-start">
                  {title}
                </h3>
                
                <p className="text-white pb-5">{description}</p>
              </div>
            </div>
          </div>
          </>)}
          

           <div className="pb-5">
           <BalletPoint
            activeStep={parseInt(activeStep, 10)}
            steps={steps}
            sendStep={sendStep}
          />
          </div>
{/*
           <MobileStepper
                    variant="dots"
                    steps={9}
                    position="static"
                    className="stepper-project"
                    activeStep={parseInt(activeStep, 10)}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                /> */}

          {objTemplate && (
            <div className="row">
              <div
                className={`col-12 col-md-10 d-flex flex-wrap align-items-center justify-content-center 
                                border-radius-top-start-2xl border-radius-top-end-2xl z-index-1 mt-2
                                ${objTemplate ? 'title-template' : 'd-none'}`}
              >
                <div
                  className={`d-block col-12 col-md-2 mb-4 mb-md-0 text-center tema-scelto 
                ${!objTemplate?.confirm && 'd-none'}`}
                >
                  <img src={templateImg} alt="" />
                </div>

                <h4
                  style={{ color: 'black' }}
                  className="col-12 col-md-7 mx-auto text-center fs-5 letter-spacing-1 mb-0"
                >
                  {objTemplate?.nome} &nbsp;|&nbsp; {objTemplate?.descrizione}
                </h4>

                <div
                  className={`col-12 col-md-3 text-center ${
                    !objTemplate?.confirm && 'd-none'
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-light m-0 mx-auto py-2 px-3 text-primary fs-7"
                    onClick={() => setObjTemplate(null)}
                  >
                    Cambia template
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
