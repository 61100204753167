import Alert, { AlertContainer } from 'components/SMU/AlertDiv'
import './Notifiche.css'

export default function Notifiche({notifications}) {
  console.log(notifications)
  return (
    <div className="alert-page">
     { /* <section className="pb-5">
        <desc>Messaggi ricevuti</desc>
        <p>Nessun messaggio ricevuto.</p>
      </section>
       */ } 
      <section>
        <desc>Notifiche</desc>
        <AlertContainer type="fill" closeOff>
         {notifications?.map((el)=>(<>
          <Alert notification>
            <span dangerouslySetInnerHTML={{__html:el.testo}} />
          </Alert>
          <hr className="w-95 mx-auto my-0 mb-2" />
          </>))}
         
        </AlertContainer>
      </section>
    </div>
  )
}
