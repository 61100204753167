import { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Dialog, TextField } from '@mui/material'
import { format } from 'date-fns'

export default function ChatProgetto({ chatProgetto, sendChatProgetto, project, client }) {
    const authContext = useContext(AuthContext)
    const freelanceID = authContext.authState?.freelancedata?.id
    const freelanceIDTipo = authContext.authState?.freelancedata?.id_tipologia;
    const clientID = authContext.authState?.clientedata?.id;
    const [isLoggedIn, setIsLoggedIn] = useState(authContext.isLoggedIn)
    const [openDialog, setOpenDialog] = useState(false)
    const [isAnswer, setIsAnswer] = useState(null)

    console.log(authContext.authState)
    const [testo, setTesto] = useState('')

    const answer = (id) => {
        setIsAnswer(id)
        setOpenDialog(true)
    }

  return (<>
  <Dialog fullWidth maxWidth="xl" open={openDialog} onClose={()=>{ setOpenDialog(false);  setIsAnswer(null); }}>
    <div className="py-3 px-4">
        <p>Inserisci qui la tua domanda:</p>
        <div>
            <TextField
                variant="filled"
                name="testo"
                placeholder="Inserisci qui la domanda che vuoi fare al cliente ed attendi una sua risposta."
                inputProps="aria-label"
                value={testo}
                sx={{ fontSize: '1rem' }}
                onChange={(e)=>setTesto(e.target.value)}
                fullWidth
                required
                multiline
                rows={4}

            />
        </div>
        {testo ? (<>
            <button type="button" className="btn btn-primary mt-3" onClick={()=>{ sendChatProgetto(testo, isAnswer); setOpenDialog(false);  setIsAnswer(null) }}>Invia domanda</button>
        </>) : null}
    </div>
  </Dialog>
    <div className="background-grey py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
                <h2>In contatto con il cliente</h2>
                {!freelanceID ? null : (<>
                <button 
                    type="button" 
                    disabled={!isLoggedIn || !freelanceID}
                    className="btn btn-gold" 
                    style={{background:'#FFB703', color:'#FFF'}}
                    onClick={()=>setOpenDialog(true)}
                >Richiedi specifiche</button>
                </>)}
          </div>
            
        </div>

        <div className="row">
            {chatProgetto.length === 0 ? (<>
            <div className="col-12 text-center">
                <p>Non ci sono ancora richieste per questo progetto.</p>
            </div>
            </>) : (<>
            <div className="col-12 mt-3">
                <h4>Altri freelancer come te hanno già chiesto</h4>
            </div>
                {chatProgetto.map((el)=>(<>
                
                    <div key={`chat${el.id}`} className="col-12 mt-2">
                        <div className="bg-white p-3">
                            <p className="text-primary pb-0 mb-0" style={{fontSize:'0.75em'}}>{format(new Date(el.created_at), 'dd/MM/yyyy HH:mm')}</p>
                        <p className="mb-0 pb-0">{el.testo}</p>
                        {el.risposta ? (<>
                            <div className="mt-2 ps-5 py-3 background-grey">
                                <p className="text-primary pb-0 mb-0" style={{fontSize:'0.75em'}}>{format(new Date(el.updated_at), 'dd/MM/yyyy HH:mm')}</p>
                                <p className="pb-0 mb-0">{el.risposta}</p>
                            </div>
                        </>) : (<>
                            {clientID === project.id_cliente ? (<>
                                <button type="button" className="btn btn-primary mt-2" onClick={()=>answer(el.id)}>Rispondi</button>
                            </>) : null}
                        </>)}
                        </div>
                    </div>
                </>))}
            
            </>)}
        </div>
      </div>
    </div>
    </>)
}
