import React from 'react'
// import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'

const KOStatusAlert = () => {
  const koStatusAlert = useSelector(state => state.events.koStatusAlert)

  // useEffect(() => {
  // console.log("events : ", events);
  // console.log("useSelector.state = ",useSelector(state => state))
  // }, [])

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'red',
          color: 'black',
          display: koStatusAlert ? 'block' : 'none'
        }}
      >
        Qualcosa è andato storto, ti preghiamo di riprovare più tardi
      </div>
    </>
  )
}

export default KOStatusAlert
