import { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'store/auth-context'
import LocationOnIcon from '@mui/icons-material/LocationOn'

export default function DettagliHeader({ project, freelances }) {
  const { titolo, localita, offers, profiles, budget } = project
  const [freelanceBudget, setFreelanceBudget] = useState(0)
  const authContext = useContext(AuthContext)
  const tipoFreelance = authContext.authState?.freelancedata?.id_tipologia
  const freelanceType = freelances.find(el => el.id === tipoFreelance) || {}

  useEffect(() => {
    setFreelanceBudget(
      profiles.find(el => el.id_tipofreelance === tipoFreelance)?.budget || 0
    )
    console.log(freelances)
  }, [])

  return (
    <div className="project-name">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-9">
            <span style={{fontSize:'0.75em', color:'#CEE0ED'}}><LocationOnIcon fontSize="small" color="#CEE0ED" /> {localita}</span><br />
            <h1>{titolo}</h1>
          </div>
          <div className="col-12 col-md-3">
            <section className="budget text-center">
              STIMA COSTO
              <span>€ {budget}</span>
              <span className="gold-background">{offers.length} Offerte</span>
            </section>
          </div>

        </div>
      </div>
    </div>
  )
}
