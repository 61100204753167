import Drawer from '@mui/material/Drawer'
import { useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import FilterBar from './FilterBar'

export default function DrawerFilterBar({ handleFilter, cities, skills }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        className="btn-filterbar-open"
        onClick={() => setOpen(true)}
      >
        {open ? (
          <CloseIcon fontSize="large" />
        ) : (
          <FilterAltIcon fontSize="large" />
        )}
      </button>

      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '50%',
            maxWidth: '400px',
            minWidth: '280px',
            overflow: 'hidden',
            padding: 0
          }
        }}
      >
        <div className="position-relative bg-white h-100 overflow-y-auto scrollbar-typeA">
          <div className="py-3">
            <FilterBar handleFilter={handleFilter} cities={cities} skills={skills} />

            <button
              type="button"
              className="btn-filterbar-close"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </button>
          </div>
        </div>
      </Drawer>
    </>
  )
}
