/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef } from 'react'

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper'

// SwiperJS react components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

// SwiperJS styles
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// @mui material components
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'

function RecentWork({ element }) {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation])

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15%',
    height: '80%',
    textAlign: 'center',
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    fontSize: '4em',

    '&:hover, &:focus': {
      opacity: 1
    }
  }

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <MKBox py={5} position="relative" className="py-6 rounded-0">
      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-lg-8">
            <h2>LAVORI RECENTI SU SITE ME UP</h2>
          </div>
        </div>

        <Swiper
          onInit={({ params, navigation }) => {
            const { navigation: nav } = params

            nav.prevEl = navigationPrevRef.current
            nav.nextEl = navigationNextRef.current
            navigation.init()
            navigation.update()
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={3}
          breakpoints={{
            1080: {
              slidesPerView: 3
            },
            768: {
              spaceBetween: 20,
              slidesPerView: 2
            },
            320: {
              slidesPerView: 1
            }
          }}
          loop
        >
          <div className="px-0">
            {element.map(item => (
              <SwiperSlide>
                <Container>
                  <>
                    <div className="mb-lg-0 mb-4">
                      <div className="card p-0" style={{ boxShadow: 'none' }}>
                        <div className="card-header p-0 bg-transparent z-index-2">
                          <div className="d-block">
                            <img
                              src={item.image}
                              alt="img-blur-shadow"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="card-body text-center bg-white border-radius-lg p-3 pt-0">
                          <h6 className="mb-1 fw-bolder text-secondary">
                            {item.clientname}
                          </h6>
                          <p className="mb-0 fw-normal text-xs">
                            {/* link scheda freelencer  */}
                            realizzato da{' '}
                            <span className="text-primary">
                              @{item.freelencername}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </Container>
              </SwiperSlide>
            ))}
          </div>
          <MKTypography
            variant="h1"
            color="info"
            sx={{
              ...navigationStyles,
              left: 0
            }}
            ref={navigationPrevRef}
            className="slider-larrow"
          >
            <Icon>chevron_left</Icon>
          </MKTypography>
          <MKTypography
            variant="h1"
            color="info"
            sx={{
              ...navigationStyles,
              right: 0
            }}
            ref={navigationNextRef}
            className="slider-rarrow"
          >
            <Icon>chevron_right</Icon>
          </MKTypography>
        </Swiper>
      </div>
    </MKBox>
  )
}

export default RecentWork
