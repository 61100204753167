import useHttp from "hooks/use-http";
import { useEffect, useState } from "react";

export default function ClientPayments() {

    const [transazioni, setTransazioni] = useState(null);
    const {
        sendRequest: sendTransactionsRequest,
        isLoading,
        error: transactionError
      } = useHttp()


      const manageTransactionsResponse = (dt) => {
        setTransazioni(dt.data)
      }
    useEffect(()=>{
        sendTransactionsRequest({
            url: `/sitemeup/usertransactions`,
            manageData: manageTransactionsResponse
          })
    }, [])

    return (<>
    
        <div className="row mt-4 px-3">
            <div className="col-12">
            <div className="w-100 text-center text-lg-start pb-4 pt-1 pb-lg-0">
                <b>ARCHIVIO TRANSAZIONI</b>
            </div>
            <div className="container border-lg-radius-xl w-100 m-auto p-0 bg-cyan mt-4">
                <div className="card-body p-1 pl-3">
                <table className="table">
                    <colgroup>
                        <col span="1" style={{ width: '15%' }} />
                        <col span="1" style={{ width: '20%' }} />
                        <col span="1" style={{ width: '40%' }} />
                        <col span="1" style={{ width: '25%' }} />
                    </colgroup>
                    <thead className="text-primary">
                        <tr>
                            <th
                            colSpan={4}
                            className="text-center pb-2 bg-lightcyan border-radius-top-end-lg"
                            >
                            PAGAMENTI
                            </th>
                        </tr>
                    </thead>
                    
                    {!transazioni ? (
                        <>
                        <tr>
                            <td colSpan={4}>
                                <div className="text-center font-weight-bold p-2 p-lg-3 text-dark">
                                {isLoading? 'Caricamento transazioni' : 'Non ci sono ancora transazioni'}
                                </div>
                            </td>
                        </tr>
                        </>
                    ) : (<> 
                        <tr>
                                <th>Progetto</th>
                                <th colSpan={2}>Descrizione</th>
                                <th>Importo</th>
                                
                            </tr>
                    
                        {Object.keys(transazioni).map((el, x)=>
                        transazioni[el].map((ex)=>(<>
                            <tr>
                                <td>{el}</td>
                                <td colSpan={2}>{ex.description}</td>
                                <td>€{parseInt(ex.amount, 10).toFixed(2)}</td>
                                
                            </tr></>
                            ))
                        )}
                        </>)
                    
                    
                    }
                    
                    
                </table>
                </div>
            </div>
        </div>
    </div>
    </>)
  }
  
  