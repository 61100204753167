import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// @mui material components
// import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
// import MKBox from 'components/MKBox'
// import MKTypography from 'components/MKTypography'
import MKButton from 'components/MKButton'

// import imgProfessionalita from 'assets/images/sitemeup/professionalita.svg'
// import imgSemplice from 'assets/images/sitemeup/semplice.svg'
// import imgBudget from 'assets/images/sitemeup/budget.svg'
import iscrivitiora from 'assets/images/sitemeup/iscrivitiora.svg'
import geometricC from 'assets/images/sitemeup/geometricC.svg'

// import { Registration } from 'components/SMU/index'
import LoginForm from 'components/SMU/Login/LoginForm'
import { useDispatch } from 'react-redux'
import { clearProfile } from 'slices/profileSlice'

// import { FormattedMessage } from 'react-intl'

export default function Iscriviti({ isLoggedIn, setIsLoggedIn }) {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      {/* <FormRegistrazione show={show} close={() => setShow(false)} /> */}
      <section className="bg-gray py-0 position-relative overflow-hidden">
        <img
          src={geometricC}
          className="geometricC_llogo z-index-1"
          alt="Site Me Up - theme"
        />
        <div className="container position-relative z-index-2 pt-6 pb-7 px-4 px-lg-auto py-lg-5">
          <div className="row">
            <div className="col-12 col-md-7 m-auto text-secondary zilla justify-content-center text-center-sm">
              <h2>
                Iscriviti ora <br />e ottieni fino a 300&euro; di abbonamenti
                premium
              </h2>
              <p className="subtitle">
                L&lsquo;abbonamento premium ti permette di fare offerte
                illimitate, di aggiudicarti 2 lavori in contemporanea e di
                approfittare di molti altri vantaggi.
              </p>
              
            </div>
            <div className="mt-5 my-lg-auto col-md-5">
              <img
                src={iscrivitiora}
                alt="Iscriviti ora"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
