import Checkbox from '@mui/material/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'

import './FilterBar.css'

// const filterListExample = [
//   'e-commerce',
//   'multilingua',
//   'area utenti riservata',
//   'calendario eventi',
//   'newsletter',
// ]

export default function FilterBar({
  projFilter,
  filterSkillList,
  maxBudgetChange,
  minBudgetChange,
  stateChange,
  choiceChange,
  skillsChange
}) {
  const Fw = ({ children }) => <span className="label-text">{children}</span>

  return (
    <div className="filterbar lato">
      <div className="mb-2 fs-5 p-1">Filtra per:</div>

      <section>
        <span className="title">Budget</span>
        <div className="col-12 budget">
          <div className="col-5">
            <TextField
              label="min"
              type="number"
              value={projFilter?.min || 0}
              onChange={minBudgetChange}
            />
          </div>
          <div className="col-2 fs-7 text-center">A</div>
          <div className="col-5">
            <TextField
              label="max"
              type="number"
              value={projFilter?.max || 0}
              onChange={maxBudgetChange}
            />
          </div>
        </div>
      </section>

      <div className="bar" />

      <section>
        <span className="title">Skills</span>
        <ul>
          {filterSkillList.map((e, index) => (
            <li>
              <label key={e.slice(0, 5)} htmlFor={`skill${index}`}>
                <Checkbox
                  name="skills"
                  value={e}
                  onChange={skillsChange}
                  key={e.slice(0, 5)}
                  id={`skill${index}`}
                  sx={{ padding: '6px 0' }}
                />
                <span className="label">{e}</span>
              </label>
            </li>
          ))}
        </ul>
      </section>

      <div className="bar" />

      <section>
        <span className="title">Scelta template</span>
        <RadioGroup name="choice" onChange={choiceChange}>
          <FormControlLabel
            value={0}
            control={<Radio />}
            label={<Fw>Dal cliente</Fw>}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={<Fw>Dal web designer</Fw>}
          />
        </RadioGroup>
      </section>

      <div className="bar" />

      <section>
        <span className="title">Stato</span>
        <RadioGroup name="state" onChange={stateChange}>
          <FormControlLabel
            value={0}
            control={<Radio />}
            label={<Fw>Richieste aperte</Fw>}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={<Fw>Lavori completati</Fw>}
          />
        </RadioGroup>
      </section>
    </div>
  )
}
